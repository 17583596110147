<template>
  <div class="container">
    <TitleBar :PageId="Id"></TitleBar>
    <div class="text-center" v-if="loading == true">
      <img :src="require('@/assets/images/loading.gif')" />
    </div>

    <div class="text-danger" v-if="errorMsg != ''">
      {{ errorMsg }}
    </div>
    <div v-if="loading == false">
      <tab :tabs="tabs" @tabChanged="onTabChange()">
        <template v-slot:tab1>
          <div class="row pddbtm30">
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label for="staticEmail" class="col-sm-12 col-form-label detail-page-label">First Name</label>
              <div class="col-sm-12">
                <input type="text" readonly class="form-control-plaintext detail-page-input" v-model="FirstName" />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label for="staticEmail" class="col-sm-12 col-form-label detail-page-label">Last Name</label>
              <div class="col-sm-12">
                <input type="text" readonly class="form-control-plaintext detail-page-input" v-model="LastName" />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label for="staticEmail" class="col-sm-12 col-form-label detail-page-label">IC Number</label>
              <div class="col-sm-12">
                <input type="text" readonly class="form-control-plaintext detail-page-input" v-model="IcNumber" />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label for="staticEmail" class="col-sm-12 col-form-label detail-page-label">Employee Number</label>
              <div class="col-sm-12">
                <input type="text" readonly class="form-control-plaintext detail-page-input" v-model="EmployeeNumber" />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label for="staticEmail" class="col-sm-12 col-form-label detail-page-label">Email</label>
              <div class="col-sm-12">
                <input type="text" readonly class="form-control-plaintext detail-page-input" v-model="Email" />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label for="staticEmail" class="col-sm-12 col-form-label detail-page-label">Personal Email</label>
              <div class="col-sm-12">
                <input type="text" readonly class="form-control-plaintext detail-page-input" v-model="PersonalEmail" />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label for="dob" class="col-sm-12 col-form-label detail-page-label">Date of birth</label>
              <div class="col-sm-12">
                <input type="text" readonly class="form-control-plaintext detail-page-input" v-model="dob" />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label for="address" class="col-sm-12 col-form-label detail-page-label">Address</label>
              <div class="col-sm-12">
                <input type="text" readonly class="form-control-plaintext detail-page-input" v-model="address" />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label for="staticEmail" class="col-sm-12 col-form-label detail-page-label">Contact Number</label>
              <div class="col-sm-12">
                <input type="text" readonly class="form-control-plaintext detail-page-input" v-model="ContactNumber" />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label for="staticEmail" class="col-sm-12 col-form-label detail-page-label">Department</label>
              <div class="col-sm-12">
                <input type="text" readonly class="form-control-plaintext detail-page-input" v-model="DepartmentName" />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label for="staticEmail" class="col-sm-12 col-form-label detail-page-label">Position</label>
              <div class="col-sm-12">
                <input type="text" readonly class="form-control-plaintext detail-page-input" v-model="Position" />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label for="staticEmail" class="col-sm-12 col-form-label detail-page-label">Bank</label>
              <div class="col-sm-12">
                <input type="text" readonly class="form-control-plaintext detail-page-input" v-model="Bank" />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label for="staticEmail" class="col-sm-12 col-form-label detail-page-label">Bank Account Number</label>
              <div class="col-sm-12">
                <input type="text" readonly class="form-control-plaintext detail-page-input"
                  v-model="BankAccountNumber" />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label for="staticEmail" class="col-sm-12 col-form-label detail-page-label">Basic salary (including fixed
                allowance)</label>
              <div class="col-sm-12">
                <SalarySafeguard :userId="Id"></SalarySafeguard>

                <!-- <input
              type="text"
              readonly
              class="form-control-plaintext detail-page-input"
              v-model="GrossSalary"
            /> -->
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label for="SubCompanyName" class="col-sm-12 col-form-label detail-page-label">Company</label>
              <div class="col-sm-12">
                <input type="text" readonly class="form-control-plaintext detail-page-input" v-model="SubCompanyName" />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label for="staticEmail" class="col-sm-12 col-form-label detail-page-label">Joined Date</label>
              <div class="col-sm-12">
                <input type="text" readonly class="form-control-plaintext detail-page-input" v-model="JoinedDate" />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label for="staticEmail" class="col-sm-12 col-form-label detail-page-label">Withdrawal Start Date</label>
              <div class="col-sm-12">
                <input type="text" readonly class="form-control-plaintext detail-page-input"
                  v-model="WithdrawalStartDate" />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label for="GracePeriod" class="col-sm-12 col-form-label detail-page-label">Notice Period</label>
              <div class="col-sm-12">
                <input type="text" readonly class="form-control-plaintext detail-page-input" v-model="GracePeriod" />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label for="staticEmail" class="col-sm-12 col-form-label detail-page-label">App Activation status</label>
              <div class="col-sm-12">
                <input type="text" readonly class="form-control-plaintext detail-page-input" v-model="Status"
                  :class="Status == 'Inactive' ? 'red' : ''" />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label for="mainstatus" class="col-sm-12 col-form-label detail-page-label">Account Status</label>
              <div class="col-sm-12">
                <input type="text" readonly class="form-control-plaintext detail-page-input" v-model="mainStatus"
                  :class="mainStatus == 'Inactive' ? 'red' : ''" />
              </div>
            </div>
          </div>
        </template>
        <template v-slot:tab2>
          <div class="row mt-4 title-section" v-if="loading == false">
            <div class="col-3">
              <Datepicker v-model="date1" :enable-time-picker="false" :format="format" :max-date="date2"
                placeholder="From" :auto-apply="true" :close-on-auto-apply="true" class="calender-picker-field"><template
                  #input-icon>
                  <img :src="require('@/assets/images/Group-714.svg')" alt="" class="calander-logo" /> </template>
              </Datepicker>
            </div>
            <div class="col-3">
              <Datepicker v-model="date2" :enable-time-picker="false" :format="format" :min-date="date1" placeholder="To"
                :auto-apply="true" :close-on-auto-apply="true" class="calender-picker-field"><template #input-icon>
                  <img :src="require('@/assets/images/Group-714.svg')" alt="" class="calander-logo" /> </template>
              </Datepicker>
            </div>

            <div class="col-3">
              <button class="btn btn-primary btn-sm me-2 btn-list btn-list-3" @click="getList">
                <i class="fa fa-search"></i> Filter
              </button>
            </div>
          </div>
          <Pagination :totalItem="withdrawalList.length" :items="withdrawalList" :perPage="50">
            <template #data="{ paginatedItems, currentIndex }">
              <table class="table table-list" v-if="loading == false">
                <thead>
                  <tr>
                    <th scope="col">No</th>

                    <th scope="col" class="pointer" @click="onSort('bankName')">
                      <span class="sort-arrow" :class="currentSort == 'bankName'
                          ? currentSortDir == 'desc'
                            ? 'sort-arrow-desc'
                            : 'sort-arrow-asc'
                          : ''
                        ">Bank</span>
                    </th>

                    <th scope="col" class="pointer" @click="onSort('totalAmountRequested')">
                      <span class="sort-arrow" :class="currentSort == 'totalAmountRequested'
                          ? currentSortDir == 'desc'
                            ? 'sort-arrow-desc'
                            : 'sort-arrow-asc'
                          : ''
                        ">Amount Requested</span>
                    </th>
                    <th scope="col" class="pointer" @click="onSort('nettAmountToTransfer')">
                      <span class="sort-arrow" :class="currentSort == 'nettAmountToTransfer'
                          ? currentSortDir == 'desc'
                            ? 'sort-arrow-desc'
                            : 'sort-arrow-asc'
                          : ''
                        ">Nett Amount Transfer</span>
                    </th>
                    <th scope="col" class="pointer" @click="onSort('isAutoWithdrawType')">
                      <span class="sort-arrow" :class="currentSort == 'isAutoWithdrawType'
                          ? currentSortDir == 'desc'
                            ? 'sort-arrow-desc'
                            : 'sort-arrow-asc'
                          : ''
                        ">Type</span>
                    </th>
                    <th scope="col" class="pointer" @click="onSort('requestedAt')">
                      <span class="sort-arrow" :class="currentSort == 'requestedAt'
                          ? currentSortDir == 'desc'
                            ? 'sort-arrow-desc'
                            : 'sort-arrow-asc'
                          : ''
                        ">Requested At</span>
                    </th>
                    <th scope="col" class="pointer" @click="onSort('statusString')">
                      <span class="sort-arrow" :class="currentSort == 'statusString'
                          ? currentSortDir == 'desc'
                            ? 'sort-arrow-desc'
                            : 'sort-arrow-asc'
                          : ''
                        ">Status</span>
                    </th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(withdrawal, index) in paginatedItems" :key="index + currentIndex">
                    <td scope="row">{{ index + currentIndex }}</td>
                    <td>{{ withdrawal.bankName }}</td>
                    <td>
                      {{
                        parseFloat(withdrawal.totalAmountRequested)
                          .toFixed(2)
                          .toLocaleString()
                      }}
                    </td>
                    <td>
                      {{
                        parseFloat(withdrawal.nettAmountToTransfer)
                          .toFixed(2)
                          .toLocaleString()
                      }}
                    </td>
                    <td>
                      {{
                        withdrawal.isAutoWithdrawType == 1 ? "Auto" : "Manual"
                      }}
                    </td>
                    <td>
                      {{
                        moment(withdrawal.requestedAt).format(
                          "DD-MM-YYYY HH:mm:ss"
                        )
                      }}
                    </td>
                    <td :class="withdrawal.statusString == 'Rejected' ? 'red' : ''
                        ">
                      {{ withdrawal.statusString }}
                    </td>
                    <td>
                      <router-link :to="{
                        name: 'admin-withdrawal-details',
                        params: {
                          id: withdrawal.id,
                        },
                      }"><img class="edit-btn-img" :src="require('@/assets/images/edit-btn.svg')" /></router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </Pagination>
        </template>
      </tab>
    </div>
    <div class="btn btn-primary mrgrbtn mt-4" type="submit" style="width: 150px"
      v-if="role == 'admin' && FailedLoginAttempts >= 3" @click="reactivate">
      Reactivate
    </div>

    <router-link class="btn btn-secondary mt-4" to="/employee/list" v-if="role != 'admin'">Back</router-link>
  </div>
</template>
<script lang="ts">
import store from "@/store";
import axios from "axios";
import { computed, defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
import TitleBar from "@/components/TitleBar.vue";
import SalarySafeguard from "@/components/SalarySafeguard.vue";
import Tab from "@/components/Tab.vue";
import Pagination from "@/components/Pagination.vue";
import { formatDateToYYYYMMDD } from "@/helpers/date-formatter";
export default defineComponent({
  name: "EmployeeDetails",
  setup() {
    const toast = useToast();
    const router = useRouter();
    const route = useRoute;
    const role = computed(() => {
      return store.state.role;
    });

    const temp = new Date();
    temp.setDate(1);
    const date1 = ref(temp);
    const date2 = ref(new Date());

    const format = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    };
    return { role, toast, router, date1, date2, format };
  },
  components: {
    TitleBar,
    SalarySafeguard,
    Tab,
    Pagination,
    Datepicker,
  },
  data() {
    return {
      Id: ref("0"),
      Email: "",
      FirstName: "",
      LastName: "",
      ContactNumber: "",
      IcNumber: "",
      EmployeeNumber: "",
      DepartmentName: "",
      PersonalEmail: '',
      Position: "",
      Bank: "",
      BankAccountNumber: "",
      GrossSalary: "",
      dob: "-",
      address: "-",
      Status: "",
      mainStatus: "",
      JoinedDate: "",
      WithdrawalStartDate: "",
      FailedLoginAttempts: 0,
      FailedLoginAttemptsDate: "",
      companyId: "",
      SubCompanyName: "",
      GracePeriod: "",
      loading: true,
      errorMsg: ref(""),
      moment: moment,
      tabs: [
        { label: "Details", slotName: "tab1" },
        { label: "Withdrawals", slotName: "tab2" },
      ],
      withdrawalList: ref([]),
      currentSort: "name",
      currentSortDir: "asc",
      totalamount: 0,
      totalNetAmount: 0,
    };
  },
  methods: {
    onSort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;

      this.withdrawalList = this.withdrawalList.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
    async getList() {
      const id = this.$route.params.id;
      const dateString1 = formatDateToYYYYMMDD(this.date1);
      const dateString2 = formatDateToYYYYMMDD(this.date2);

      const token = store.state.token;
      const response = await axios.get(
        process.env.VUE_APP_API_URL + "Withdrawal/employee/history",
        {
          params: {
            date1: dateString1,
            date2: dateString2,
            employeeId: id,
          },
          headers: {
            Authorization: "bearer " + token,
          },
        }
      );
      this.withdrawalList = response.data;
      this.withdrawalList.forEach((element) => {
        if (element.statusString === "Rejected") return;
        this.totalamount += element.totalAmountRequested;
        this.totalNetAmount += element.nettAmountToTransfer;
      });
    },
    onTabChange() {
      //
    },
    async reactivate() {
      const token = store.state.token;
      const id = this.$route.params.id;
      await axios
        .put(
          process.env.VUE_APP_API_URL + "employee/" + id + "/reactivateaccount",
          "",
          {
            headers: {
              Authorization: "bearer " + token,
            },
          }
        )
        .then((res) => {
          this.toast.success("Successfully reactivate", {
            timeout: 3000,
          });
          this.errorMsg = "";
          this.router.push({
            name: "admin-company-details",
            params: { id: this.companyId },
          });
        })
        .catch((err) => {
          this.errorMsg = err.response.data;
        });
    },
    async employeeDetails() {
      const id = this.$route.params.id;

      const token = store.state.token;
      const response = await axios
        .get(process.env.VUE_APP_API_URL + "employee/" + id + "/details", {
          headers: {
            Authorization: "bearer " + token,
          },
        })
        .then((res) => {
          this.Id = res.data.id;
          this.Email = res.data.email;
          this.FirstName = res.data.firstName;
          this.LastName = res.data.lastName;
          this.IcNumber = res.data.icNumber;
          this.EmployeeNumber = res.data.employeeNumber;
          this.ContactNumber = res.data.contactNumber;
          this.DepartmentName = res.data.departmentName;
          this.Bank = res.data.bankName;
          this.Position = res.data.position;
          this.GrossSalary = res.data.grossSalary
            ? parseFloat(res.data.grossSalary).toFixed(2).toLocaleString()
            : 0;
          this.BankAccountNumber = res.data.bankAccountNumber;
          this.Status = res.data.status;
          this.mainStatus = res.data.mainStatusString;
          this.FailedLoginAttempts = res.data.failedLoginAttempts;
          this.companyId = res.data.companyId;
          this.SubCompanyName = res.data.subCompanyName;
          this.personalEmail = res.data.personalEmail;
          if (res.data.failedLoginAttemptsDate) {
            this.FailedLoginAttemptsDate = moment(
              res.data.failedLoginAttemptsDate
            ).format("DD-MM-YYYY");
          }
          if (res.data.dataOfBirth) {
            this.dob = moment(res.data.dataOfBirth).format("DD-MM-YYYY");
          }
          if (res.data.joinedDate) {
            this.JoinedDate = moment(res.data.joinedDate).format("DD-MM-YYYY");
          }
          if (res.data.withdrawalStartDate) {
            this.WithdrawalStartDate = moment(
              res.data.withdrawalStartDate
            ).format("DD-MM-YYYY");
          }
          if (res.data.gracePeriod) {
            this.GracePeriod = moment(res.data.gracePeriod).format(
              "DD-MM-YYYY"
            );
          }

          if (res.data.address1) {
            this.address =
              res.data.address1 +
              ", " +
              res.data.address2 +
              ", " +
              res.data.city +
              ", " +
              res.data.postCode;
          }
        })
        .catch((err) => {
          // 
        });
    },
  },
  mounted() {
    (async () => {
      try {
        this.loading = true;
        await this.employeeDetails();
        await this.getList();
      } catch (error) {
        // 
      } finally {
        this.loading = false;
      }
    })();
  },
});
</script>

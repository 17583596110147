<template>
  <div class="container">
    <TitleBar></TitleBar>
    <div class="text-danger" v-if="errorMsg != ''">
      {{ errorMsg }}
    </div>

    <form enctype="multipart/form-data">
      <div class="row g-3">
        <div class="col-sm-12">
          <div class="input-file-div">
            <input
              type="file"
              id="file"
              name="file"
              ref="file"
              class="form-control file-input"
              accept=".csv"
              @change="handleFile()"
            />

            <label class="custom-file-input">
              <div @click="openFileInput" class="custom-file-div">
                <div class="custom-file-inner">
                  <span class="icon"
                    ><img
                      :src="require('@/assets/images/Group-1429.svg')"
                      alt="File Icon"
                  /></span>
                  <span class="label">Choose file</span>
                </div>
              </div>
            </label>
            <label
              class="custom-file-input-no-file-chosen"
              @click="openFileInput"
              v-if="!file"
            >
              <div class="custom-file-input-no-file-chosen-opacity">
                No file selected
              </div>
            </label>
          </div>
          <span class="download-smallnote">
            Only .csv file format is accepted.
          </span>
          <br />
          <p
            @click="DownloadExcel('mulaaa_import_template.xlsx')"
            class="sample-download"
          >
            Click here to download sample template
          </p>
          <span class="text-danger" v-if="errormessage">{{
            errormessage
          }}</span>

          <span
            v-if="
              successnumber != 0 || failnumber != 0 || successupdatenumber != 0
            "
          >
            <br />
            Number Successful imported : {{ successnumber }}
            <br />
            Number Successful updated : {{ successupdatenumber }}
            <br />
            Number of failed
            <router-link
              v-if="failnumber > 0"
              target="_blank"
              :to="{
                name: 'employee-bulkimport-failed-list',
                params: {
                  id: bulkiid,
                },
              }"
              >(view more details)</router-link
            >
            : {{ failnumber }}
          </span>
        </div>
      </div>
      <div class="text-center" v-if="loading == true">
        <img :src="require('@/assets/images/loading.gif')" />
      </div>

      <router-link class="btn btn-secondary mrgrbtn" to="/employee/list"
        >Back</router-link
      >
      <button class="btn btn-primary" type="button" v-on:click="submit()">
        Submit
      </button>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import store from "@/store";
import axios from "axios";
import TitleBar from "@/components/TitleBar.vue";
export default defineComponent({
  name: "EmployeeBulkImport",
  components: {
    TitleBar,
  },
  data() {
    return {
      file: "",
      errormessage: "",
      loading: false,
      successnumber: 0,
      failnumber: 0,
      successupdatenumber: 0,
      bulkiid: "",
      errorMsg: "",
    };
  },

  methods: {
    async submit() {
      this.loading = true;

      this.errormessage = "";
      const file = this.$refs.file.files[0];
      if (!file) {
        this.errormessage = "No file chosen";
        this.loading = false;
        return;
      }

      if (file.size > 1024 * 1024) {
        this.errormessage = "File too big (> 1MB)";
        this.loading = false;
        return;
      }
      if (!(file.type == "text/csv")) {
        this.errormessage = "Invalid File";
        this.loading = false;
        return;
      }
      try {
        let formData = new FormData();

        formData.append("file", this.file);

        const token = store.state.token;

        var response = (
          await axios.post(
            process.env.VUE_APP_API_URL + "employee/bulkimport",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "bearer " + token,
              },
            }
          )
        ).data;
        this.loading = false;
        this.successnumber = response.result.successnumber;
        this.failnumber = response.result.failnumber;
        this.successupdatenumber = response.result.successupdatenumber;
        this.bulkiid = response.result.bulkiid;
        this.errormessage = response.result.msg;
      } catch (error) {
        this.loading = false;
      }
    },
    async DownloadExcel(filename) {
      const token = store.state.token;

      const response = await axios
        .get(
          process.env.VUE_APP_API_URL +
            "employee/file/downloadsample/" +
            filename + "?dd=" + new Date().getTime(),
          {
            responseType: "blob",
            headers: {
              Authorization: "bearer " + token,
            },
          }
        )
        .then((res) => {
          var fileURL = window.URL.createObjectURL(new Blob([res.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((err) => {
          //
        });
    },

    openFileInput() {
      let fileInputElement = this.$refs.file;
      fileInputElement.click();
    },

    handleFile() {
      this.file = this.$refs.file.files[0];
      this.errormessage = "";
      this.successnumber = 0;
      this.failnumber = 0;
      this.successupdatenumber = 0;
    },
    onSort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },
  },
});
</script>

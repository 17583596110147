<template>
  <div class="container">
    <TitleBar :PageId="id + ''"></TitleBar>
    <div class="text-center" v-if="loading == true">
      <img :src="require('@/assets/images/loading.gif')" />
    </div>

    <div class="text-danger" v-if="errorMsg != ''">
      {{ errorMsg }}
    </div>
    <div v-if="loading == false" class="pddbtm30">
      <div class="row mrglr0">
        <div class="col-5">
          <div class="col-sm-12">
            <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">Name</label>
          </div>
          <div class="col-sm-12">
            <div class="form-control-plaintext detail-page-input">
              {{ subcompany.companyName }}
            </div>
          </div>
        </div>
        <div class="col-5">
          <div class="col-sm-12">
            <label for="staticEmail" class="col-form-label detail-page-label">Contact Number</label>
          </div>
          <div class="col-sm-12">
            <div class="form-control-plaintext detail-page-input">
              {{ subcompany.contactNumber }}
            </div>
          </div>
        </div>
      </div>
      <div class="pddtop20 row mrglr0">
        <div class="col-5">
          <div class="col-sm-12">
            <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">Email</label>
          </div>
          <div class="col-sm-12">
            <div class="form-control-plaintext detail-page-input">
              {{ subcompany.email }}
            </div>
          </div>
        </div>
        <!-- <div class="row">
            <label
              for="staticEmail"
              class="col-sm-3 col-form-label detail-page-label"
              >EZPA Enrolment</label
            >
            <div class="col-sm-9">
              <div class="form-control-plaintext detail-page-input">
                {{ subcompany.ezpaVsureEnableString }}
              </div>
            </div>
          </div> -->
        <div class="col-5">
          <div class="col-sm-12">
            <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">Status</label>
          </div>
          <div class="col-sm-12">
            <div class="form-control-plaintext detail-page-input"
              :class="subcompany.statusString === 'Inactive' ? 'red' : ''">
              {{ subcompany.statusString }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <hr class="my-4" /> -->

    <router-link class="btn btn-secondary" to="/subcompany/list">Back</router-link>
  </div>
</template>
<script lang="ts">
import store from "@/store";
import axios from "axios";
import { defineComponent, ref, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import TitleBar from "@/components/TitleBar.vue";
export default defineComponent({
  name: "SubCompanyDetails",
  components: {
    TitleBar,
  },
  setup() {
    const route = useRoute;
    const store = useStore();

    const role = computed(() => {
      return store.state.role;
    });

    return { role };
  },
  data() {
    return {
      id: ref(0),
      subcompany: ref(null),
      loading: true,
      errorMsg: ref(""),
    };
  },
  methods: {
    async subcompanyDetails() {
      const id = this.$route.params.id;

      const token = store.state.token;
      const response = await axios
        .get(process.env.VUE_APP_API_URL + "SubCompany/" + id + "/details", {
          headers: {
            Authorization: "bearer " + token,
          },
        })
        .then((res) => {
          this.subcompany = res.data;
        })
        .catch((err) => {
          // 
        });
      this.loading = false;
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.subcompanyDetails();
  },
});
</script>

<template>
  <TitleBar></TitleBar>
  <div class="text-center" v-if="recordData.loading == true">
    <img :src="require('@/assets/images/loading.gif')" />
  </div>
  <div v-if="recordData.loading == false">
    <div class="mb-3 row">
      <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label"
        >Name</label
      >
      <div class="col-sm-9">
        <input
          type="text"
          readonly
          class="form-control-plaintext detail-page-input"
          v-model="recordData.FirstName"
        />
      </div>
    </div>
    <div class="mb-3 row">
      <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label"
        >Bank</label
      >
      <div class="col-sm-9">
        <input
          type="text"
          readonly
          class="form-control-plaintext detail-page-input"
          v-model="recordData.BankName"
        />
      </div>
    </div>
    <div class="mb-3 row">
      <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label"
        >Bank Account Number</label
      >
      <div class="col-sm-9">
        <input
          type="text"
          readonly
          class="form-control-plaintext detail-page-input"
          v-model="recordData.BankAccountNumber"
        />
      </div>
    </div>

    <div class="mb-3 row">
      <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label"
        >Total Amount Requested</label
      >
      <div class="col-sm-9">
        <input
          type="text"
          readonly
          class="form-control-plaintext detail-page-input"
          v-model="recordData.TotalAmountRequested"
        />
      </div>
    </div>
    <div class="mb-3 row">
      <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label"
        >Transaction Fees</label
      >
      <div class="col-sm-9">
        <input
          type="text"
          readonly
          class="form-control-plaintext detail-page-input"
          v-model="recordData.TransactionFees"
        />
      </div>
    </div>

    <div class="mb-3 row">
      <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label"
        >Nett Amount Transfer</label
      >
      <div class="col-sm-9">
        <input
          type="text"
          readonly
          class="form-control-plaintext detail-page-input"
          v-model="recordData.NettAmountToTransfer"
        />
      </div>
    </div>
    <div class="mb-3 row">
      <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label"
        >Requested At</label
      >
      <div class="col-sm-9">
        <input
          type="text"
          readonly
          class="form-control-plaintext detail-page-input"
          v-model="recordData.RequestedAt"
        />
      </div>
    </div>
    <div class="mb-3 row">
      <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label"
        >Status</label
      >
      <div class="col-sm-9">
        <input
          type="text"
          readonly
          class="form-control-plaintext detail-page-input"
          v-model="recordData.StatusString"
        />
      </div>
    </div>
    <hr class="my-4" />

    <button class="btn btn-secondary" @click="$router.back()" 
      >Back</button
    >
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, inject } from "vue";
import store from "@/store";
import axios from "axios";
import { useRoute } from "vue-router";
import { reactive } from "@vue/reactivity";
import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers, decimal } from "@vuelidate/validators";
import { computed } from "@vue/runtime-core";
import { useToast } from "vue-toastification";
import TitleBar from "@/components/TitleBar.vue";
import moment from "moment";
declare var bootstrap;

export default defineComponent({
  name: "WithdrawalDetails",
  components: {
    TitleBar,
  },
  setup() {
    const swal = inject("$swal") as any;
    const route = useRoute();
    const showModal = ref(false);
    const toast = useToast();
    const formData = reactive({
      Status: 0,
      TransactionId: "",
      withdrawalRecordId: 0,
    });

    const recordData = reactive({
      Id: ref(0),
      UpdateStatusValue: ref(0),
      FirstName: "",
      LastName: "",
      BankAccountNumber: "",
      BankName: "",
      TransactionFees: "",
      TotalAmountRequested: "",
      NettAmountToTransfer: "",
      StatusString: "",
      Status: 0,
      RequestedAt: "",
      TransactionId: "",
      TransactionCreatedAt: "",
      loading: true,
    });

    const WithdrawalDetails = async () => {
      const id = route.params.id;

      const token = store.state.token;
      const response = await axios
        .get(process.env.VUE_APP_API_URL + "withdrawal/" + id + "/details", {
          headers: {
            Authorization: "bearer " + token,
          },
        })
        .then((res) => {
          recordData.Id = res.data.id;
          recordData.FirstName = res.data.firstName;
          recordData.LastName = res.data.lastName;
          recordData.BankAccountNumber = res.data.bankAccountNumber;
          recordData.BankName = res.data.bankName;
          recordData.TransactionFees = res.data.transactionFees
            ? parseFloat(res.data.transactionFees).toFixed(2).toLocaleString()
            : "0";
          recordData.TotalAmountRequested = res.data.totalAmountRequested
            ? parseFloat(res.data.totalAmountRequested)
                .toFixed(2)
                .toLocaleString()
            : "0";
          recordData.NettAmountToTransfer = res.data.nettAmountToTransfer
            ? parseFloat(res.data.nettAmountToTransfer)
                .toFixed(2)
                .toLocaleString()
            : "0";
          recordData.RequestedAt = moment(res.data.requestedAt).format(
            "DD MMMM YYYY, h:mm A"
          );
          recordData.StatusString = res.data.statusString;
          recordData.Status = res.data.status;
        })
        .catch((err) => {
        // 
        });
      recordData.loading = false;
    };

    return {
      formData,
      recordData,

      WithdrawalDetails,
    };
  },
  data() {
    return {
      errorMsg: ref(""),
    };
  },

  mounted() {
    this.WithdrawalDetails();
  },
});
</script>
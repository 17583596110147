<template>
  <div class="container">
    <TitleBar></TitleBar>
    <div class="row mt-4 title-section gap-2">
      <div class="col-12 col-md-4">
        <div class="page-title-s">
          <div class="search-box" @click="onSearch($event)">
            <i class="fa fa-search"></i>
          </div>
          <input type="text" v-model="search" placeholder="Search" />
        </div>
      </div>
      <div class="col-12 col-md-2">
        <button class="btn btn-primary btn-sm btn-list btn-list-3 w-100" @click="downloadEmployeeList()">
          <i class="fa fa-download"></i>
          {{ downloading ? "Download..." : "Download" }}
        </button>
      </div>

      <div class="col d-flex justify-content-end flex-wrap gap-1">
        <router-link to="/employee/bulkimport" class="btn btn-primary btn-sm me-2 btn-list" style="flex: 1"><i
            class="fa fa-upload"></i> Bulk Import</router-link>

        <router-link to="/employee/add" class="btn btn-primary btn-sm me-2 btn-list" style="flex: 1"><i
            class="fa fa-add"></i> Add New Employee</router-link>
        <router-link to="/employee/bulkimport/failed/list" class="btn btn-primary btn-sm btn-list" style="flex: 1"><i
            class="fa fa-history"></i> Bulk Import History</router-link>
      </div>
    </div>
    <div class="text-center" v-if="loading == true">
      <img :src="require('@/assets/images/loading.gif')" />
    </div>
    <Pagination :totalItem="employees.length" :items="employees" :perPage="50">
      <template #data="{ paginatedItems, currentIndex }">
        <table class="table table-list" v-if="loading == false">
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col" class="pointer" @click="onSort('fullName')">
                <span class="sort-arrow" :class="currentSort == 'fullName'
                  ? currentSortDir == 'desc'
                    ? 'sort-arrow-desc'
                    : 'sort-arrow-asc'
                  : ''
                  ">Name</span>
              </th>
              <th scope="col" class="pointer" @click="onSort('departmentName')">
                <span class="sort-arrow" :class="currentSort == 'departmentName'
                  ? currentSortDir == 'desc'
                    ? 'sort-arrow-desc'
                    : 'sort-arrow-asc'
                  : ''
                  ">Department</span>
              </th>
              <th scope="col" class="pointer" @click="onSort('position')">
                <span class="sort-arrow" :class="currentSort == 'position'
                  ? currentSortDir == 'desc'
                    ? 'sort-arrow-desc'
                    : 'sort-arrow-asc'
                  : ''
                  ">Position</span>
              </th>
              <th scope="col" class="pointer" @click="onSort('contactNumber')">
                <span class="sort-arrow" :class="currentSort == 'contactNumber'
                  ? currentSortDir == 'desc'
                    ? 'sort-arrow-desc'
                    : 'sort-arrow-asc'
                  : ''
                  ">Contact Number</span>
              </th>
              <th scope="col" class="pointer">
                <span class="sort-arrow" :class="currentSort == 'subCompanyName'
                  ? currentSortDir == 'desc'
                    ? 'sort-arrow-desc'
                    : 'sort-arrow-asc'
                  : ''
                  " @click="onSort('subCompanyName')">Company</span>
              </th>
              <th scope="col" class="pointer" @click="onSort('status')">
                <span class="sort-arrow" :class="currentSort == 'status'
                  ? currentSortDir == 'desc'
                    ? 'sort-arrow-desc'
                    : 'sort-arrow-asc'
                  : ''
                  ">App Activation status</span>
              </th>
              <th scope="col" class="pointer" @click="onSort('mainStatus')">
                <span class="sort-arrow" :class="currentSort == 'mainStatus'
                  ? currentSortDir == 'desc'
                    ? 'sort-arrow-desc'
                    : 'sort-arrow-asc'
                  : ''
                  ">Account Status</span>
              </th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(employee, index) in paginatedItems" :key="employee.id">
              <td scope="row">{{ index + currentIndex }}</td>
              <td>{{ employee.fullName }}</td>
              <td>{{ employee.departmentName }}</td>
              <td>{{ employee.position }}</td>
              <td>{{ employee.contactNumber }}</td>
              <td>
                {{ employee.subCompanyName }}
              </td>
              <td :class="employee.status == 'Inactive' ? 'red' : ''">
                {{ employee.status }}
              </td>
              <td :class="employee.mainStatus == 'Inactive' ? 'red' : ''">
                {{ employee.mainStatus }}
              </td>
              <td>
                <router-link :to="{
                  name: 'employee-details',
                  params: {
                    id: employee.id,
                  },
                }"><img class="edit-btn-img" :src="require('@/assets/images/edit-btn.svg')" /></router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </Pagination>
  </div>
</template>
<script lang="ts">
import store from "@/store";
import axios from "axios";
import Pagination from "@/components/Pagination.vue";
import TitleBar from "@/components/TitleBar.vue";
import { defineComponent, inject, ref } from "vue";
import { usePasswordVerify } from "@/composables/usePasswordVerify";

export default defineComponent({
  name: "EmployeeList",
  components: {
    Pagination,
    TitleBar,
  },
  setup() {
    const { verifyPassword } = usePasswordVerify();
    const swal = inject("$swal") as any;
    const downloading = ref(false);

    async function downloadSelection() {

      const result = await swal({
        html: `
       
            <form>
              <div class="row g-3 margin-lr-0 left-align">
              <div class="col-sm-12">
                <label for="Status" class="form-label">Download Type</label>
                <select id="downloadtype"
                    name="Status" class="form-control" value="default"
                  >
                  <option value="default">Default</option>
                  <option value="bulkimport-format">Format 2 (for Bulk import)</option>
                </select>
              </div>
              <div class="col-sm-12">
                <label for="Time" class="form-label">Users</label>
                <select id="usertype"
                  name="Time" class="form-control" value="all"
                  >
                  <option value="all">All</option>
                  <option value="active-users">Only Active Users</option>
                </select>
              </div>
            </div>
          </form>
                `,

        showCancelButton: false,
        confirmButtonText: "Submit",
        cancelButtonText: "Close",
        showCloseButton: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-secondary me-2",
        },

      });

      if (result.isConfirmed) {
        const downloadtype = swal.getHtmlContainer().querySelector("#downloadtype")?.value;
        const userType = swal.getHtmlContainer().querySelector("#usertype")?.value;
        return {
          downloadtype, userType
        }
      }
      return null
    }

    async function downloadEmployeeList() {
      const selection = await downloadSelection()
      if (!selection) return

      if (!(await verifyPassword())) return;
      let uri = "employee/download-list"
      if (selection.downloadtype === "bulkimport-format") {
        uri = "employee/download-list/bulkimport-format"
      }

      downloading.value = true;
      const token = store.state.token;
      try {
        const res = await axios
          .post(process.env.VUE_APP_API_URL + uri, {
            userType: selection.userType
          }, {
            responseType: "blob",
            headers: {
              Authorization: "bearer " + token,
            },
          })

        var fileURL = window.URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "employee_list.csv");
        document.body.appendChild(fileLink);

        fileLink.click();
        downloading.value = false;
      } catch (error) {
        downloading.value = false;
      }

    }

    return { downloadEmployeeList, downloading };
  },

  data() {
    return {
      employees: ref([]),
      oriemployees: ref([]),
      count: 1,
      loading: true,
      search: "",
      currentSort: "name",
      currentSortDir: "asc",
    };
  },
  methods: {
    async employeeList() {
      const token = store.state.token;
      const response = await axios.get(
        process.env.VUE_APP_API_URL + "employee/list",
        {
          headers: {
            Authorization: "bearer " + token,
          },
        }
      );
      this.loading = false;
      this.employees = response.data;
      this.oriemployees = response.data;
    },
    onSort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;

      this.employees = this.employees.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },

    onSearch(event) {
      this.employees = this.oriemployees.filter((p) => {
        return (
          (
            p.firstName.toLowerCase() +
            " " +
            (p.lastName ?? "").toLowerCase()
          ).indexOf(this.search.toLowerCase()) != -1 ||
          (p.email ?? "").toLowerCase().indexOf(this.search.toLowerCase()) !=
          -1 ||
          (p.position ?? "").toLowerCase().indexOf(this.search.toLowerCase()) !=
          -1 ||
          (p.contactNumber ?? "").indexOf(this.search.toLowerCase()) != -1 ||
          (p.subCompanyName ?? "").indexOf(this.search.toLowerCase()) != -1
        );
      });
    },
  },
  mounted() {
    this.employeeList();
  },
});
</script>
<style scoped>
.page-title-s {
  width: 100%;
}

.page-title-s input {
  width: 100%;
}
</style>

<template>
  <div class="bg-light p-5 rounded">
    <h1>Companies</h1>
    <button @click="getCompanies">Get Company</button>
    <div v-if="companies">
      <div v-for="company in companies" :key="company.id">
        {{ company.companyName }}
      </div>
    </div>
    <div v-else>No Data found</div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";

export default defineComponent({
  name: "Companies",

  data() {
    return {
      companies: [],
    };
  },
  methods: {
    async getCompanies() {
      const response = await axios.get(process.env.VUE_APP_API_URL + "Company");

      this.companies = response.data;
    },
  },
  created() {
    this.getCompanies();
  },
});
</script>
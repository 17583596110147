<template>
    <div class="container mt-5">
          <TitleBar></TitleBar>
  
      <div class="text-center" v-if="loading == true">
        <img :src="require('@/assets/images/loading.gif')" />
      </div>
      <div class="row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label"
          >File name</label
        >
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ originalexcel }}
          </div>
        </div>
      </div>
      <div class="row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label"
          >Status</label
        >
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ bStatus && bStatus == 1 ? "Success" : "Failed" }}
          </div>
        </div>
      </div>
      <div class="row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label"
          >Created At</label
        >
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ createat }}
          </div>
        </div>
      </div>
      <div class="row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label"
          >Created By</label
        >
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ CreatedBy }}
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label
          v-if="bStatus != 1"
          for="staticEmail"
          class="col-sm-3 col-form-label detail-page-label"
          >Remarks</label
        >
        <div class="col-sm-9" v-if="bStatus != 1">
          <div class="form-control-plaintext detail-page-input">
            {{ ListRemark }}
          </div>
        </div>
      </div>
      <Pagination :totalItem="lists.length" :items="lists" :perPage="50">
        <template #data="{ paginatedItems, currentIndex }">
          <table class="table table-list" v-if="loading == false">
            <thead>
              <tr>
                <th scope="col">No</th>
                <th
                  scope="col"
                 class="pointer"
                  @click="onSort('fullName')"
                >
                  <span  class=" sort-arrow"
                  :class="
                    currentSort == 'fullName'
                      ? currentSortDir == 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                  ">Employee Name</span>
                </th>
                <th
                  scope="col"
                  class="pointer "
                  @click="onSort('requestAmount')"
                >
                  <span   class=" sort-arrow"
                  :class="
                    currentSort == 'requestAmount'
                      ? currentSortDir == 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                  ">Net Amount To Transfer</span>
                </th>
                <!-- <th
                  scope="col"
                  class="pointer sort-arrow"
                  :class="
                    currentSort == 'transactionID'
                      ? currentSortDir == 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                  "
                  @click="onSort('transactionID')"
                >
                  Transaction ID
                </th> -->
                <th
                  scope="col"
                  class="pointer"
                  @click="onSort('status')"
                >
                  Status
                </th>
                <th scope="col">
                  <span  class="sort-arrow"
                  :class="
                    currentSort == 'status'
                      ? currentSortDir == 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                  ">Remark</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(list, index) in paginatedItems"
                :key="index + currentIndex"
              >
                <td scope="row">{{ index + currentIndex }}</td>
                <td>{{ list.fullName }}</td>
                <td>{{  list.nettAmountToTransfer ? parseFloat(list.nettAmountToTransfer).toFixed(2).toLocaleString() : "0"}}</td>
                <!-- <td>{{ list.transactionID }}</td> -->
                <td>
                  {{
                    list.status && (list.status == "1" || list.status == 1)
                      ? "Success"
                      : "Failed"
                  }}
                </td>
                <td>{{ list.remarks }}</td>
              </tr>
            </tbody>
          </table>
        </template>
      </Pagination>
      <router-link
        class="btn btn-secondary"
        to="/admin/withdrawal/bulkimport/list"
        >Back</router-link
      >
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref } from "vue";
  // import { useVuelidate } from "@vuelidate/core";
  // import { required, email, helpers, decimal } from "@vuelidate/validators";
  // import { reactive } from "@vue/reactivity";
  // import { computed } from "@vue/runtime-core";
  import { useRouter } from "vue-router";
  import { useToast } from "vue-toastification";
  import store from "@/store";
  import moment from "moment";
  
  import axios from "axios";
  import Pagination from "@/components/Pagination.vue";
  import TitleBar from "@/components/TitleBar.vue";
  export default defineComponent({
    name: "AdminWithdrawalTransactionBatchListDetails",
    components: {
      Pagination,
        TitleBar,
    },
    mounted() {
      this.id = this.$route.params.id;
      this.bulkDetailslist();
    },
    data() {
      return {
        id: "",
        count: 1,
        lists: "",
        loading: true,
        createat: "",
        originalexcel: "",
        CreatedBy: "",
        ListRemark: "",
        bStatus: "",
        currentSort: "name",
        currentSortDir: "asc",
      };
    },
    methods: {
      onSort(s) {
        if (s === this.currentSort) {
          this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
        }
        this.currentSort = s;
  
        this.lists = this.lists.sort((a, b) => {
          let modifier = 1;
          if (this.currentSortDir === "desc") modifier = -1;
          if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
          if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
          return 0;
        });
      },
      async bulkDetailslist() {
        const token = store.state.token;
        const response = await axios.get(
          process.env.VUE_APP_API_URL +
            "Insurance/bulkimport/" +
            this.id +
            "/details",
          {
            headers: {
              Authorization: "bearer " + token,
            },
          }
        );
        this.loading = false;
        this.lists = response.data;
        if (this.lists) {
          this.createat = moment(this.lists[0].createdAt).format(
            "DD-MM-YYYY HH:mm:ss"
          );
          this.originalexcel = this.lists[0].fileName;
          this.CreatedBy = this.lists[0].createdByEmail;
          this.ListRemark = this.lists[0].bRemarks;
          this.bStatus = this.lists[0].bStatus;
        }
      },
    },
  });
  </script>
<template>
  <div class="container">
    <TitleBar></TitleBar>
    <!-- <p class="mt-3 page-title mrgb0 pdd0">Dashboard</p> -->
    <p class="dashboard-sub-title">
      Hey, there! <br />Here’s what’s happening at {{ company }} today.
    </p>
    <p class="lead"></p>
    <div class="row g-3 d-top-section">
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 d-top-section-box-o">
        <div class="d-top-section-box d-tsb-1">
          <div class="d-top-section-box-i">
            <div class="row">
              <div class="col-8 pdd0">
                <p class="d-top-section-box-r-p-1">Active Users</p>
                <p class="d-top-section-box-r-p-2">{{ totalActiveUser }}</p>
              </div>
              <div class="col-4 pdd0 right-align">
                <img
                  class="d-top-section-box-img"
                  :src="require('@/assets/images/Group-660.svg')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 d-top-section-box-o">
        <div class="d-top-section-box d-tsb-2">
          <div class="d-top-section-box-i">
            <div class="row">
              <div class="col-8 pdd0">
                <p class="d-top-section-box-r-p-1">Recurring</p>
                <p class="d-top-section-box-r-p-2">{{ totalRuser }}</p>
              </div>
              <div class="col-4 pdd0 right-align">
                <img
                  class="d-top-section-box-img"
                  :src="require('@/assets/images/Group-661.svg')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 d-top-section-box-o">
        <div class="d-top-section-box d-tsb-3">
          <div class="d-top-section-box-i">
            <div class="row">
              <div class="col-8 pdd0">
                <p class="d-top-section-box-r-p-1">
                  Withdrawals<br />(Approved/Pending)
                </p>
                <p class="d-top-section-box-r-p-2">
                  {{ totalwithdrawal }}/{{ totalPendingWithdrawal }}
                </p>
              </div>
              <div class="col-4 pdd0 right-align">
                <img
                  class="d-top-section-box-img"
                  :src="require('@/assets/images/Group-662.svg')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 d-top-section-box-o">
        <div class="d-top-section-box d-tsb-4">
          <div class="d-top-section-box-i">
            <div class="row">
              <div class="col-8 pdd0">
                <p class="d-top-section-box-r-p-1">Payout Amount</p>
                <p class="d-top-section-box-r-p-2">
                  RM{{ totalpayout.toLocaleString() }}
                </p>
              </div>
              <div class="col-4 pdd0 right-align">
                <img
                  class="d-top-section-box-img-1"
                  :src="require('@/assets/images/Group-665.svg')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4 d-chart-section mrglr0">
      <div class="col-md-4 col-sm-3 pddr0">
        <p class="d-section-title pdd0 mrg0">
          <img
            :src="require('@/assets/images/Group-712.svg')"
            alt=""
            class="title-logo"
          />Withdrawals
        </p>
      </div>
      <div class="col-sm-6 pddl0">
        <div class="row">
          <div class="col-sm-6 pddr0">
            <p class="d-chart-section-filter pdd0 mrg0">FILTER</p>
          </div>
          <div class="col-sm-6">
            <Datepicker
              v-model="date"
              month-picker
              @update:model-value="getChartData"
              format="MM-yyyy"
              :auto-apply="true"
              :close-on-auto-apply="true"
              class="calender-picker-field"
            >
              <template #input-icon>
                <img
                  :src="require('@/assets/images/Group-714.svg')"
                  alt=""
                  class="calander-logo"
                />
              </template>
            </Datepicker>
          </div>
        </div>
      </div>
      <div class="col-sm-12 d-chart-section-c">
        <apexchart
          type="line"
          width="100%"
          height="400px"
          :options="withdrawalschartOptions"
          :series="withdrawalschartdata"
        ></apexchart>
      </div>
    </div>
    <div class="row mt-4 g-3 d-bottom-section">
      <div
        v-if="role == 'employer'"
        class="col-lg-5 col-md-5 col-sm-12 col-xs-12 d-bottom-section-box-o mrg0"
      >
        <div class="notification-section mb-4">
          <p class="d-section-title pdd0 mrg0">
            <img
              :src="require('@/assets/images/Group-720.svg')"
              alt=""
              class="title-logo-2"
            />Latest Notifications
          </p>
          <div class="notification-list">
            <!-- <div class="notification-list-search-s">
              <div class="control is-expanded">
                <input
                  v-model="Nsearch"
                  type="text"
                  class="form-control nsearch"
                  id="Nsearch"
                  placeholder="Search.."
                  @input="onNSearch($event)"
                />
                <span
                  class="fas nsearch-icon fa-solid fa-magnifying-glass"
                ></span>
              </div>
            </div> -->
            <div
              class="notification-list-search-s notification-list-s overflow-table"
            >
              <div
                class="notification-list-box pointer"
                v-for="nr in NrequestList"
                :key="nr.id"
              >
                <router-link
                  :to="{
                    name: 'updateprofilerequest-details',
                    params: {
                      id: nr.id,
                    },
                  }"
                  target="_blank"
                >
                  <div class="notification-list-i">
                    <p class="notification-list-title pdd0 mrg0">
                      <span class="notification-list-sub">{{
                        moment(nr.updatedAt).format("DD-MM-YYYY")
                      }}</span>
                    </p>
                    <p class="notification-list-title pdd0 mrg0">
                      {{ nr.fullName }} Request To Update Profile
                    </p>
                  </div>
                </router-link>
              </div>
              <!-- <div class="notification-list-box">
              <div class="notification-list-i">
                <p class="notification-list-title pdd0 mrg0">
                  Change of Bank Account
                </p>
                <p class="notification-list-sub pdd0 mrg0">29 Jun 2022</p>
              </div>
            </div>
            <div class="notification-list-box">
              <div class="notification-list-i">
                <p class="notification-list-title pdd0 mrg0">
                  Bulk Update Successful
                </p>
                <p class="notification-list-sub pdd0 mrg0">04 Jul 2022</p>
              </div>
            </div>
            <div class="notification-list-box">
              <div class="notification-list-i">
                <p class="notification-list-title pdd0 mrg0">
                  Withdrawal Notification
                </p>
                <p class="notification-list-sub pdd0 mrg0">12 Jul 2022</p>
              </div>
            </div> -->
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-lg-7 col-md-7 col-sm-12 col-xs-12 d-bottom-section-box-o mrg0 d-recent-withdrawal-section"
      >
        <div class="r-withdrawal-section">
          <p class="d-section-title pdd0 mrg0">
            <img
              :src="require('@/assets/images/Group-877.svg')"
              alt=""
              class="title-logo-2"
            />Recent Withdrawals
          </p>
          <div class="overflow-table">
            <table
              class="table d-recent-withdrawal-table"
              v-if="loading == false"
              cellspacing="0"
              cellpadding="0"
            >
              <thead>
                <tr>
                  <th scope="col">Inv. No</th>
                  <th scope="col">Employee Name</th>
                  <th scope="col">Position</th>
                  <th scope="col">Date</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(withdrawal, index) in withdrawalList" :key="index">
                  <td class="d-recent-withdrawal-td-1"></td>
                  <td class="d-recent-withdrawal-td-2">
                    {{ withdrawal.firstName + " " + withdrawal.lastName }}
                  </td>
                  <td class="d-recent-withdrawal-td-3">
                    {{ withdrawal.position }}
                  </td>
                  <td class="d-recent-withdrawal-td-4">
                    {{ moment(withdrawal.requestedAt).format("DD-MM-YYYY") }}
                  </td>
                  <td class="d-recent-withdrawal-td-5">
                    RM{{
                      parseFloat(withdrawal.totalAmountRequested)
                        .toFixed(2)
                        .toLocaleString()
                    }}
                  </td>
                  <td class="d-recent-withdrawal-td-6">
                    <div
                      class="d-recent-withdrawal-status"
                      :class="
                        withdrawal.statusString &&
                        withdrawal.statusString == 'Approved'
                          ? 'd-recent-withdrawal-status-paid'
                          : 'd-recent-withdrawal-status-draft'
                      "
                    >
                      <span
                        v-if="
                          withdrawal.statusString &&
                          withdrawal.statusString == 'Approved'
                        "
                        >Paid</span
                      >
                      <span v-else>Draft</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
/* top */
/* .d-tsb-1 {
  background-color: #66b033;
}
.d-tsb-2 {
  background-color: #0392ce;
}
.d-tsb-3 {
  background-color: #a7194b;
}
.d-tsb-4 {
  background-color: #fb9902;
} */
.d-tsb-1 {
  background: #ffffff 0% 0% no-repeat padding-box;
}
.d-tsb-2 {
  background: #ffffff 0% 0% no-repeat padding-box;
}
.d-tsb-3 {
  background: #ffffff 0% 0% no-repeat padding-box;
}
.d-tsb-4 {
  background: #ffffff 0% 0% no-repeat padding-box;
}
.d-top-section-box {
  color: #7f63f4;
  height: 100%;
  /* background: #ffffff 0% 0% no-repeat padding-box; */
  opacity: 1;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 31px;
}
.d-top-section-box-o:nth-child(odd) > .d-top-section-box {
  /* box-shadow: 0.47px 3px 10px #7777771a; */
}
.d-top-section-box-o:nth-child(even) > .d-top-section-box {
  /* box-shadow: 3px 20px 20px #0000001a; */
}
.d-top-section-box-i {
  margin: 0;
  padding: 25px 45px;
}
.d-top-section-box-img {
  width: 35px;
  padding-top: 10px;
  padding-bottom: 57px;
  margin-top: -10px;
  filter: invert(55%) sepia(51%) saturate(7467%) hue-rotate(232deg)
    brightness(100%) contrast(92%);
}
.d-top-section-box-img-1 {
  width: 22px;
  padding-top: 10px;
  padding-bottom: 57px;
  margin-top: -10px;
  filter: invert(55%) sepia(51%) saturate(7467%) hue-rotate(232deg)
    brightness(100%) contrast(92%);
}
.d-top-section-box-l-p {
  font-size: 20px;
  /* font-family: "Circular Std Book"; */
  /* color: #464a53; */
  padding-top: 10px;
  color: #abafb3;
}

.d-top-section-box-r-p-1 {
  font-size: 20px;
  letter-spacing: 0px;
  text-align: left;
  margin: 0;
}
/* .d-top-section-box-o:nth-child(odd) .d-top-section-box-r-p-1 {
  color: #472667;
}
.d-top-section-box-o:nth-child(even) .d-top-section-box-r-p-1 {
  color: #ec3894;
} */
.d-top-section-box-r-p-2 {
  font-size: 40px;
  letter-spacing: 0.3px;
  /* color: #6a707e; */
  text-align: left;
  color: #4c2c6b;
}
.d-top-section-box-r-p-3 {
  font-size: 15px;
  letter-spacing: 0.32px;
  text-align: left;
  color: #abafb3;
}

/* .d-top-section-box-o:nth-child(odd) .d-top-section-box-r-p-3 {
  color: #472667;
}
.d-top-section-box-o:nth-child(even) .d-top-section-box-r-p-3 {
  color: #ec3894;
} */
/* top */

/* chart */
.d-chart-section {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0.47px 3px 10px #7777771a;
  padding: 20px;
}
.d-section-title {
  font-size: 25px;
  letter-spacing: 0px;
  color: #4c2c6b;
}
.title-logo {
  width: 40px;
  margin-right: 15px;
}
.title-logo-2 {
  width: 35px;
  margin-right: 15px;
}
.d-chart-section-filter {
  font-size: 15px;
  color: #472667;
  letter-spacing: 0px;
  text-transform: uppercase;
  text-align: right;
  line-height: 35px;
  font-weight: 600;
}
.d-chart-section-c {
  /* padding-top: 10px; */
}

.chart-tooltip-box {
  background-color: #ec3894;
  color: #ffffff;
  padding: 10px 20px;
}
.chart-tooltip-title {
  font-size: 16px;
  letter-spacing: 0.36px;
}
.chart-tooltip-p {
  font-size: 14px;
  letter-spacing: 0.32px;
}
/* chart */

/* notification */
.notification-list-search-s {
  padding-top: 20px;
  /*padding-bottom: 20px;*/
}
.nsearch {
  background-color: transparent;
  border: 1px solid #dddfe1;
  border-radius: 3px;
}
.nsearch::placeholder {
  letter-spacing: 0.3px;
  color: #abafb3;
  opacity: 1;
  font-size: 15px;
}
.nsearch-icon {
  float: right;
  right: 3%;
  margin-top: -25px;
  position: relative;
  z-index: 2;
  color: #abafb3;
}

.notification-list-box {
  min-width: 400px;
  padding-bottom: 10px;
}
.notification-list-box a {
  text-decoration: none;
  border: none;
}
.notification-list-i {
  padding: 15px 20px;
  border-radius: 17px;
  display: flex;
}
.notification-list-box:nth-child(odd) .notification-list-i {
  background: #fffbfd 0% 0% no-repeat padding-box;
}
.notification-list-box:nth-child(even) .notification-list-i {
  background: #ffffff 0% 0% no-repeat padding-box;
}
.notification-list-title {
  text-align: left;
  opacity: 1;
  font-size: 16px;
  letter-spacing: 0px;
  color: #472667;
}
.notification-list-sub {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  padding-right: 20px;
}

/* notification */

/* recent withdraw */
.d-recent-withdrawal-section {
  padding-left: 10px;
}
.d-recent-withdrawal-table {
  padding: 0.5rem 0.5rem;
  margin-top: 20px;
  border: none !important;
  border-collapse: collapse;
}

.d-recent-withdrawal-table th {
  background: transparent;
  font-size: 17px;
  letter-spacing: 0px;
  color: #464a53;
  font-weight: normal;
  vertical-align: middle;
  text-align: center;
}

.d-recent-withdrawal-table th,
.d-recent-withdrawal-table td {
  border: none !important;
  border-collapse: collapse;
}
.d-recent-withdrawal-table thead {
  background: #f6f0fc 0% 0% no-repeat padding-box;
  border-radius: 17px;
  /* box-shadow: 0.47px 3px 10px #7777771A; */
}
.d-recent-withdrawal-table thead tr {
  /* border-bottom: 5px solid transparent; */
  /* box-shadow: 0.47px 3px 10px #7777771A; */
}
.d-recent-withdrawal-table tbody tr {
  /* border-bottom: 10px solid transparent; */
  /* box-shadow: 0.47px 3px 10px #7777771A; */
}
.d-recent-withdrawal-table td {
  background: #ffffff 0% 0% no-repeat padding-box;
  height: 72px;
  vertical-align: middle;
  text-align: center;
  color: #6a707e;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0px;
}
.d-recent-withdrawal-table thead th:first-child {
  border-top-left-radius: 17px;
  border-bottom-left-radius: 17px;
}
.d-recent-withdrawal-table thead th:last-child {
  border-top-right-radius: 17px;
  border-bottom-right-radius: 17px;
}
.d-recent-withdrawal-table tbody tr:nth-child(even) td {
  background: #fffbfd 0% 0% no-repeat padding-box;
}
.d-recent-withdrawal-table tbody tr:nth-child(even) td:first-child {
  border-top-left-radius: 17px;
  border-bottom-left-radius: 17px;
}
.d-recent-withdrawal-table tbody tr:nth-child(even) td:last-child {
  border-top-right-radius: 17px;
  border-bottom-right-radius: 17px;
}
.d-recent-withdrawal-td-3 {
  color: #7f63f4 !important;
}
.d-recent-withdrawal-td-4 {
  color: #abafb3 !important;
}
.d-recent-withdrawal-status {
  height: 30px;
  width: 75px;
  margin: auto;
  border-radius: 8px;
}
.d-recent-withdrawal-status-paid {
  color: #ffffff;
  background-color: #75ea66;
}
.d-recent-withdrawal-status-draft {
  color: #ffffff;
  background-color: #cdcfcd;
}
.d-recent-withdrawal-status span {
  vertical-align: middle;
  text-align: center;
  margin: auto;
}
.apexcharts-menu-icon {
  width: 140px !important;
  height: 65px !important;
  transform: scale(1) !important;
}
.apexcharts-menu-icon img {
  width: 100%;
  height: 100%;
}
.apexcharts-toolbar {
  top: -44px !important;
  right: -3px !important;
}
/* recent withdraw */
</style>
<script lang="ts">
import { defineComponent, ref, reactive, computed } from "vue";
import axios from "axios";
import router from "@/router";
import store from "@/store";
import moment from "moment";
import Datepicker from "@vuepic/vue-datepicker";
import TitleBar from "@/components/TitleBar.vue";

export default defineComponent({
  name: "Dashboard",
  setup() {
    const month = new Date().getMonth();
    const year = new Date().getFullYear();
    const date = ref({
      month: month,
      year: year,
    });
    const DownloadFilter = reactive({
      Month: month,
      Year: year,
    });

    const role = computed(() => {
      return store.state.role;
    });
    return { date, month, year, DownloadFilter, moment: moment, role };
  },
  components: {
    Datepicker,
    TitleBar,
  },
  data() {
    return {
      loading: false,
      userName: "",

      totaluser: 0,
      totalRuser: 0,
      totalwithdrawal: 0,
      totalPendingWithdrawal: 0,
      totalpayout: 0,

      userpercentage: 0,
      ruserpercentage: 0,
      withdrawalpercentage: 0,
      payoutpercentage: 0,

      totacurrentusers: 0,
      totacurrentrecurring: 0,
      totacurrentwithdrawals: 0,
      totacurrentpayout: 0,
      totalActiveUser: 0,
      totalAppActivatedUser: 0,

      withdrawalscharttotalPayout: [],
      withdrawalschartdata: [
        {
          name: "Withdrawals",
          data: [],
        },
      ],
      withdrawalschartOptions: {
        chart: {
          toolbar: {
            tools: {
              download:
                '<button class="btn btn-primary btn-sm btn-list btn-list-3"><i class="fa fa-download"></i> Download</button>',
            },
          },
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#EC3894"],
        stroke: {
          curve: "smooth",
        },
        // title: {
        //   text: "Product Trends by Month",
        //   align: "left",
        // },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [],
        },
        markers: {
          size: 2,
          colors: "#00AFF0",
          strokeColors: "#00AFF0",
          strokeWidth: 2,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [],
          shape: "circle",
          radius: 2,
          offsetX: 0,
          offsetY: 0,
          onClick: undefined,
          onDblClick: undefined,
          showNullDataPoints: true,
          hover: {
            size: undefined,
            sizeOffset: 4,
          },
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            return (
              '<div class="chart-tooltip-box"><p class="chart-tooltip-title">' +
              w.globals.initialSeries[seriesIndex].data[dataPointIndex].date +
              '</p><p class="chart-tooltip-p pdd0 mrg0">Withdrawals: ' +
              series[seriesIndex][dataPointIndex] +
              "</p>" +
              '<p class="chart-tooltip-p pdd0 mrg0">Payout: RM' +
              parseFloat(
                w.globals.initialSeries[seriesIndex].data[dataPointIndex]
                  .totalwithdrawpayout
              ).toLocaleString() +
              "</p></div>"
            );
          },
        },
      },

      Nsearch: "",
      withdrawalList: ref([]),
      NrequestList: ref([]),
      oriNrequestList: ref([]),
    };
  },
  methods: {
    async getNrequestList() {
      const token = store.state.token;
      const response = await axios.get(
        process.env.VUE_APP_API_URL + "user/getUserProfileRequest",
        {
          headers: {
            Authorization: "bearer " + token,
          },
        }
      );
      this.loading = false;
      this.NrequestList = response.data.slice(0, 4);
      this.oriNrequestList = response.data.slice(0, 4);
      this.NrequestList.sort((a, b) => {
        let da = new Date(a.updatedAt),
          db = new Date(b.updatedAt);
        return db.getTime() - da.getTime();
      });
    },
    onNSearch(event) {
      this.NrequestList = this.oriNrequestList.filter((p) => {
        return (
          p.fullName.toLowerCase().indexOf(this.Nsearch.toLowerCase()) != -1
        );
      });
    },
    async getProfile() {
      const token = store.state.token;

      await axios
        .get(process.env.VUE_APP_API_URL + "User/get-me", {
          headers: {
            Authorization: "bearer " + token,
          },
        })
        .then((res) => {
          this.userName = res.data.firstName;
        })
        .catch((err) => {
          router.push("/login");
        });
    },
    async getTopTotalData() {
      const token = store.state.token;

      const response = await axios.get(
        process.env.VUE_APP_API_URL + "website/dashboard/totaldata",
        {
          headers: {
            Authorization: "bearer " + token,
          },
        }
      );
      this.loading = false;
      this.totaluser = response.data.result.totalusers;
      this.totalRuser = response.data.result.totalRuser;
      this.totalwithdrawal = response.data.result.totalthismonthwithdrawal;
      this.totalPendingWithdrawal =
        response.data.result.pendingWithdrawalCounts;
      this.totalpayout = parseFloat(response.data.result.totalthismonthpayout);

      this.userpercentage = parseFloat(
        response.data.result.userpercentage
      ).toFixed(0);
      this.ruserpercentage = parseFloat(
        response.data.result.ruserpercentage
      ).toFixed(0);
      this.withdrawalpercentage = parseFloat(
        response.data.result.withdrawalpercentage
      ).toFixed(0);
      this.payoutpercentage = parseFloat(
        response.data.result.payoutpercentage
      ).toFixed(0);

      this.totacurrentusers = response.data.result.totalthismonthusers;
      this.totacurrentrecurring = response.data.result.totalthismonthRuser;
      this.totacurrentwithdrawals =
        response.data.result.totalthismonthwithdrawal;
      this.totacurrentpayout = response.data.result.totalthismonthpayout;

      this.totalAppActivatedUser =
        response.data.result.usersCountWithActiveAppStatus;
      this.totalActiveUser = response.data.result.usersCountWithActiveStatus;
    },
    async getChartData() {
      const token = store.state.token;
      const search_year = this.date.year;
      const search_month = this.date.month + 1;

      const response = await axios.get(
        process.env.VUE_APP_API_URL +
          "withdrawal/chart/data/" +
          search_year +
          "/" +
          search_month,
        {
          headers: {
            Authorization: "bearer " + token,
          },
        }
      );
      this.loading = false;
      this.withdrawalschartdata = [
        {
          data: response.data.result.withdrawalChartlist,
        },
      ];

      this.withdrawalschartOptions = {
        xaxis: {
          categories: response.data.result.daylist,
        },
      };
    },
    async getRecentWithdrawalList() {
      const token = store.state.token;
      const response = await axios.get(
        process.env.VUE_APP_API_URL + "Withdrawal/dashboard/recentwithdraw",
        {
          headers: {
            Authorization: "bearer " + token,
          },
        }
      );
      this.loading = false;
      this.withdrawalList = response.data;
      this.withdrawalList.forEach((element) => {
        this.totalamount = this.totalamount + element.totalAmountRequested;
      });
    },
  },
  mounted() {
    this.getProfile();
    this.getTopTotalData();
    this.getChartData();
    this.getRecentWithdrawalList();
    this.getNrequestList();
  },

  computed: {
    company() {
      return this.$store.state.userdetail?.companyName ?? "";
    },
  },
});
</script>

import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid pb-5content-bg" }
const _hoisted_2 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_NavBar),
        _createElementVNode("main", {
          class: _normalizeClass(
          $setup.role == 'visitor'
            ? 'col-md-12 col-lg-12 ms-sm-auto  px-md-4'
            : 'col-md-9 col-lg-10 ms-sm-auto  px-md-4 content-bg'
        )
        }, [
          _createVNode(_component_router_view)
        ], 2)
      ])
    ])
  ], 64))
}
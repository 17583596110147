<template>
  <div style="overflow-x: scroll">
    <slot
      name="data"
      :pageNumber="pageNumber"
      :paginatedItems="paginatedItems"
      :perPages="perPage"
      :currentIndex="currentIndex"
    />

    <div class="pagination-row" v-if="numberPages > 1">
      <i
        v-if="pageNumber > 1"
        class="fa-solid fa-angles-left pagination-button"
        @click="changePageNumber(1)"
      ></i>
      <i
        class="fa fa-chevron-left pagination-button"
        v-if="pageNumber > 1"
        @click="changePageNumber(pageNumber - 1)"
      ></i>
      <div v-else style="width: 34px"></div>
      <div class="visible-numbers">
        <span v-for="(page, index) in visiblePageNumbers" :key="index">
          <button
            :class="['pagination-button', pageNumber === page ? 'pactive' : '']"
            @click="changePageNumber(page)"
          >
            {{ page }}
          </button>
        </span>
        <span
          v-if="visiblePageNumbers[visiblePageNumbers.length - 1] < numberPages"
        >
          <span class="pagination-ellipsis">...</span>
          <button
            class="pagination-button"
            @click="changePageNumber(numberPages)"
          >
            {{ numberPages }}
          </button>
        </span>
      </div>
      <i
        class="fa fa-chevron-right pagination-button"
        v-if="pageNumber < numberPages"
        @click="changePageNumber(pageNumber + 1)"
      ></i>
      <i
        v-if="pageNumber < numberPages"
        class="fa-solid fa-angles-right pagination-button"
        @click="changePageNumber(numberPages)"
      ></i>
      <div v-else style="width: 34px"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageNumber: Number(this.$route.query[this.queryStringForPageNumber]) || 1,
      currentIndex: this.perPage * (this.pageNumber - 1) + 1 || 1,
    };
  },
  computed: {
    numberPages() {
      return Math.ceil(this.totalItem / this.perPage);
    },
    visiblePageNumbers() {
      const range = 5; // Number of visible page numbers

      const start = Math.max(1, this.pageNumber - Math.floor(range / 2));
      const end = Math.min(this.numberPages, start + range - 1);
      const visiblePages = [];
      for (let i = start; i <= end; i++) {
        visiblePages.push(i);
      }
      if (end < this.numberPages - 1) {
        visiblePages.push("...");
        visiblePages.push(this.numberPages);
      }
      return visiblePages;
    },
    paginatedItems() {
      let slice = this.perPage * this.pageNumber;
      return this.items.slice(slice - this.perPage, slice);
    },
  },
  methods: {
    changePageNumber(n) {
      if (Number.isNaN(parseInt(n))) return;

      this.pageNumber = n;
      if (this.pageNumber > 1) {
        this.currentIndex = this.perPage * (this.pageNumber - 1) + 1;
      } else {
        this.currentIndex = 1;
      }
      this.$router.push({
        path: this.$route.path,
        query: {
          [this.queryStringForPageNumber]: n,
        },
      });
    },
  },
  props: {
    queryStringForPageNumber: {
      type: String,
      default: "pageNumber",
    },
    totalItem: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style>
.pagination-button {
  padding: 5px 12px;
  margin: 2px;
  border-radius: 3px;
  font-size: 1em;
  cursor: pointer;
  text-align: center;
  background-color: white;
}
.pactive {
  color: white !important;
  background: #4c2c6b !important;
  border: 3px solid #4c2c6b;
  cursor: auto;
}
.pagination-row {
  padding: 5px 0;
  display: flex;
  align-items: center;
}
.pagination-ellipsis {
  margin: 0 5px;
}

.visible-numbers {
  display: flex;
  align-items: center;
}
</style>

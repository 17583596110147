<template>
  <div class="container">
    <TitleBar></TitleBar>
    <div class="text-center" v-if="loading == true">
      <img :src="require('@/assets/images/loading.gif')" />
    </div>
    <p>File name : {{ originalexcel }}</p>
    <p>Created At : {{ createat }}</p>
    <p>Created By: {{ createdBy }}</p>

    <Pagination :totalItem="lists.length" :items="lists" :perPage="50">
      <template #data="{ paginatedItems, currentIndex }">
        <table class="table table-list" v-if="loading == false">
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col" class="pointer" @click="onSort('remarks')">
                <span
                  class="sort-arrow"
                  :class="
                    currentSort == 'remarks'
                      ? currentSortDir == 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                  "
                  >Remarks</span
                >
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(list, index) in paginatedItems"
              :key="index + currentIndex"
            >
              <td scope="row">{{ index + currentIndex }}</td>
              <td>{{ list.remarks }}</td>
            </tr>
          </tbody>
        </table>
      </template>
    </Pagination>
    <router-link class="btn btn-secondary" to="/employee/bulkimport/failed/list"
      >Back</router-link
    >
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
// import { useVuelidate } from "@vuelidate/core";
// import { required, email, helpers, decimal } from "@vuelidate/validators";
// import { reactive } from "@vue/reactivity";
// import { computed } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import store from "@/store";
import moment from "moment";

import axios from "axios";
import Pagination from "@/components/Pagination.vue";
import TitleBar from "@/components/TitleBar.vue";
export default defineComponent({
  name: "EmployeeBulkImportFailedDetails",
  components: {
    Pagination,
    TitleBar,
  },
  mounted() {
    this.id = this.$route.params.id;
    this.bulkfailedlist();
  },
  data() {
    return {
      id: "",
      count: 1,
      lists: "",
      loading: true,
      createat: "",
      originalexcel: "",
      createdBy: "",
      currentSort: "name",
      currentSortDir: "asc",
    };
  },
  methods: {
    onSort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;

      this.lists = this.lists.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
    async bulkfailedlist() {
      const token = store.state.token;
      const response = await axios.get(
        process.env.VUE_APP_API_URL +
          "employee/bulkimport/" +
          this.id +
          "/details",
        {
          headers: {
            Authorization: "bearer " + token,
          },
        }
      );
      this.loading = false;
      this.lists = response.data;
      if (this.lists) {
        this.createat = moment(this.lists[0].createdAt).format(
          "DD MMMM YYYY, h:mm A"
        );
        this.originalexcel = this.lists[0].fileName;
        this.createdBy = this.lists[0].createdBy;
      }
    },
  },
});
</script>
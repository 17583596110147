<template>
  <div class="container">
    <TitleBar></TitleBar>
    <div class="row title-section justify-content-end">
      <div class="col-12 col-md-3">
        <router-link
          to="/subcompany/add"
          class="btn btn-primary btn-sm me-2 btn-list w-100"
          ><i class="fa fa-add"></i> Add New Company</router-link
        >
      </div>
    </div>
    <div class="text-center" v-if="loading == true">
      <img :src="require('@/assets/images/loading.gif')" />
    </div>
    <Pagination
      :totalItem="subcompanies.length"
      :items="subcompanies"
      :perPage="50"
    >
      <template #data="{ paginatedItems, currentIndex }">
        <table class="table table-list" v-if="loading == false">
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col" class="pointer" @click="onSort('companyName')">
                <span
                  class="sort-arrow"
                  :class="
                    currentSort == 'companyName'
                      ? currentSortDir == 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                  "
                >
                  Name
                </span>
              </th>
              <th scope="col" class="pointer" @click="onSort('contactNumber')">
                <span
                  class="sort-arrow"
                  :class="
                    currentSort == 'contactNumber'
                      ? currentSortDir == 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                  "
                >
                  Contact Number
                </span>
              </th>
              <th scope="col" class="pointer" @click="onSort('email')">
                <span
                  class="sort-arrow"
                  :class="
                    currentSort == 'email'
                      ? currentSortDir == 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                  "
                >
                  Email
                </span>
              </th>
              <!-- <th
                scope="col"
                class="pointer sort-arrow"
                :class="
                  currentSort == 'ezpaVsureEnableString'
                    ? currentSortDir == 'desc'
                      ? 'sort-arrow-desc'
                      : 'sort-arrow-asc'
                    : ''
                "
                @click="onSort('ezpaVsureEnableString')"
              >
                EZPA Enrolment
              </th> -->
              <th scope="col" class="pointer" @click="onSort('statusString')">
                <span
                  class="sort-arrow"
                  :class="
                    currentSort == 'statusString'
                      ? currentSortDir == 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                  "
                >
                  Status
                </span>
              </th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(subcompany, index) in paginatedItems"
              :key="subcompany.id"
            >
              <td scope="row">{{ index + currentIndex }}</td>
              <td>{{ subcompany.companyName }}</td>
              <td>{{ subcompany.contactNumber }}</td>
              <td>{{ subcompany.email }}</td>
              <!-- <td>{{ subcompany.ezpaVsureEnableString }}</td> -->
              <td :class="subcompany.statusString === 'Inactive' ? 'red' : ''">
                {{ subcompany.statusString }}
              </td>
              <td>
                <router-link
                  :to="{
                    name: 'subcompany-details',
                    params: {
                      id: subcompany.id,
                    },
                  }"
                  ><img
                    class="edit-btn-img"
                    :src="require('@/assets/images/edit-btn.svg')"
                /></router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </Pagination>
  </div>
</template>
<script lang="ts">
import store from "@/store";
import axios from "axios";
import Pagination from "@/components/Pagination.vue";

import { defineComponent, ref } from "vue";
import TitleBar from "@/components/TitleBar.vue";
export default defineComponent({
  name: "SubCompanyList",
  components: {
    Pagination,
    TitleBar,
  },
  data() {
    return {
      subcompanies: ref([]),
      count: 1,
      loading: true,
      currentSort: "name",
      currentSortDir: "asc",
    };
  },
  methods: {
    onSort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;

      this.subcompanies = this.subcompanies.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
    async subcompanyList() {
      const token = store.state.token;
      const response = await axios.get(
        process.env.VUE_APP_API_URL + "SubCompany/list",
        {
          headers: {
            Authorization: "bearer " + token,
          },
        }
      );
      this.loading = false;
      this.subcompanies = response.data;
    },
  },
  mounted() {
    this.subcompanyList();
  },
});
</script>

<template>
  <div class="container">
    <TitleBar></TitleBar>
    <div class="row mt-4 title-section">
      <div class="col-12 col-md-4">
        <div class="page-title-s">
          <div class="search-box" @click="onSearch($event)">
            <i class="fa fa-search"></i>
          </div>
          <input type="text" v-model="search" placeholder="Search" />
        </div>
      </div>
    </div>
    <div class="text-center" v-if="loading == true">
      <img :src="require('@/assets/images/loading.gif')" />
    </div>
    <Pagination
      :totalItem="NrequestLists.length"
      :items="NrequestLists"
      :perPage="50"
    >
      <template #data="{ paginatedItems, currentIndex }">
        <table class="table table-list" v-if="loading == false">
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col" class="pointer" @click="onSort('fullName')">
                <span
                  class="sort-arrow"
                  :class="
                    currentSort == 'fullName'
                      ? currentSortDir == 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                  "
                  >Name</span
                >
              </th>
              <th scope="col" class="pointer" @click="onSort('companyName')">
                <span
                  class="sort-arrow"
                  :class="
                    currentSort == 'companyName'
                      ? currentSortDir == 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                  "
                  >Company</span
                >
              </th>
              <th scope="col" class="pointr" @click="onSort('email')">
                <span
                  class="sort-arrow"
                  :class="
                    currentSort == 'email'
                      ? currentSortDir == 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                  "
                  >Email</span
                >
              </th>
              <th scope="col" class="pointer" @click="onSort('departmentName')">
                <span
                  class="sort-arrow"
                  :class="
                    currentSort == 'departmentName'
                      ? currentSortDir == 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                  "
                  >Department</span
                >
              </th>
              <th scope="col" class="pointer" @click="onSort('position')">
                <span
                  class="sort-arrow"
                  :class="
                    currentSort == 'position'
                      ? currentSortDir == 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                  "
                  >Position</span
                >
              </th>
              <th scope="col" class="pointer" @click="onSort('contactNumber')">
                <span
                  class="sort-arrow"
                  :class="
                    currentSort == 'contactNumber'
                      ? currentSortDir == 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                  "
                  >Contact Number</span
                >
              </th>
              <th scope="col" class="pointer" @click="onSort('updatedAt')">
                <span
                  class="sort-arrow"
                  :class="
                    currentSort == 'updatedAt'
                      ? currentSortDir == 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                  "
                  @click="onSort('updatedAt')"
                  >Request At</span
                >
              </th>
              <th scope="col" class="pointer">
                <span
                  class="sort-arrow"
                  :class="
                    currentSort == 'statusString'
                      ? currentSortDir == 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                  "
                  @click="onSort('statusString')"
                  >Status</span
                >
              </th>

              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(NrequestList, index) in paginatedItems"
              :key="NrequestList.id"
            >
              <td scope="row">{{ index + currentIndex }}</td>
              <td>{{ NrequestList.fullName }}</td>
              <td>{{ NrequestList.companyName }}</td>
              <td>{{ NrequestList.email }}</td>
              <td>{{ NrequestList.departmentName }}</td>
              <td>{{ NrequestList.position }}</td>
              <td>{{ NrequestList.contactNumber }}</td>
              <td>{{ moment(NrequestList.updatedAt).format("DD-MM-YYYY") }}</td>
              <td>{{ NrequestList.statusString }}</td>

              <td>
                <a href="#" @click.prevent="goToDetail(NrequestList.id)"
                  ><img
                    class="edit-btn-img"
                    :src="require('@/assets/images/edit-btn.svg')"
                /></a>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </Pagination>
  </div>
</template>
<script lang="ts">
import store from "@/store";
import axios from "axios";
import Pagination from "@/components/Pagination.vue";
import { computed, ref, defineComponent } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import moment from "moment";
import TitleBar from "@/components/TitleBar.vue";
import { usePasswordVerify } from "@/composables/usePasswordVerify";
export default defineComponent({
  name: "UpdateProfileRequestList",
  setup() {
    const store = useStore();
    const router = useRouter();
    const { verifyPassword } = usePasswordVerify();

    const role = computed(() => {
      return store.state.role;
    });

    async function goToDetail(id) {
      if (!(await verifyPassword())) return;
      router.push({
        name: "updateprofilerequest-details",
        params: {
          id: id,
        },
      });
    }

    return { role, goToDetail };
  },
  components: {
    Pagination,
    TitleBar,
  },
  data() {
    return {
      NrequestLists: ref([]),
      oriNrequestLists: ref([]),
      count: 1,
      loading: true,
      moment: moment,
      currentSort: "name",
      currentSortDir: "asc",
      search: "",
    };
  },
  methods: {
    async updateProfileRequestList() {
      const token = store.state.token;
      const response = await axios.get(
        process.env.VUE_APP_API_URL + "user/getUserProfileRequest",
        {
          headers: {
            Authorization: "bearer " + token,
          },
        }
      );
      this.loading = false;
      this.NrequestLists = response.data;
      this.oriNrequestLists = response.data;
    },
    onSort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;

      this.NrequestLists = this.NrequestLists.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
    onSearch(event) {
      this.NrequestLists = this.oriNrequestLists.filter((p) => {
        return (
          p.fullName.toLowerCase().indexOf(this.search.toLowerCase()) != -1 ||
          (p.companyName &&
            p.companyName.toLowerCase().indexOf(this.search.toLowerCase()) !=
              -1) ||
          p.email.indexOf(this.search.toLowerCase()) != -1 ||
          p.departmentName.indexOf(this.search.toLowerCase()) != -1 ||
          p.position.indexOf(this.search.toLowerCase()) != -1 ||
          p.contactNumber.indexOf(this.search.toLowerCase()) != -1
        );
      });
    },
  },

  mounted() {
    this.updateProfileRequestList();
  },
});
</script>
<style scoped>
.page-title-s {
  width: 100%;
}
.page-title-s input {
  width: 100%;
}
</style>

<template>
  <div class="container">
    <TitleBar></TitleBar>
    <div class="row title-section">
      <div class="col"></div>
      <div class="col text-end">
        <router-link
          to="/admin/company/add"
          class="btn btn-primary btn-sm me-2 btn-list"
          ><i class="fa fa-add"></i> Add New Company</router-link
        >
      </div>
    </div>
    <div class="text-center" v-if="loading == true">
      <img :src="require('@/assets/images/loading.gif')" />
    </div>
    <Pagination :totalItem="companies.length" :items="companies" :perPage="50">
      <template #data="{ paginatedItems, currentIndex }">
        <table class="table table-list" v-if="loading == false">
          <thead>
            <tr>
              <th scope="col">No</th>
              <th
                scope="col"
                class="pointer"
                @click="onCompanySort('companyName')"
              >
                <span
                  class="sort-arrow"
                  :class="
                    companySortField === 'companyName'
                      ? companySortOrder === 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                  "
                  >Name</span
                >
              </th>
              <th
                scope="col"
                class="pointer"
                @click="onCompanySort('contactNumber')"
              >
                <span
                  class="sort-arrow"
                  :class="
                    companySortField === 'contactNumber'
                      ? companySortOrder === 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                  "
                  >Contact Number</span
                >
              </th>
              <th
                scope="col"
                class="pointer sort-arrow"
                :class="
                  companySortField === 'email'
                    ? companySortOrder === 'desc'
                      ? 'sort-arrow-desc'
                      : 'sort-arrow-asc'
                    : ''
                "
                @click="onCompanySort('email')"
              >
                <span>Email</span>
              </th>
              <th
                scope="col"
                class="pointer"
                @click="onCompanySort('insuranceName')"
              >
                <span
                  class="sort-arrow"
                  :class="
                    companySortField === 'insuranceName'
                      ? companySortOrder === 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                  "
                  >Insurance Enrolment</span
                >
              </th>
              <th
                scope="col"
                class="pointer"
                @click="onCompanySort('statusString')"
              >
                <span
                  class="sort-arrow"
                  :class="
                    companySortField === 'statusString'
                      ? companySortOrder === 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                  "
                  >Status</span
                >
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(company, index) in paginatedItems" :key="company.id">
              <td scope="row">{{ index + currentIndex }}</td>
              <td>{{ company.companyName }}</td>
              <td>{{ company.contactNumber }}</td>
              <td>{{ company.email }}</td>
              <td>{{ company.insuranceName }}</td>
              <td :class="company.statusString === 'Inactive' ? 'red' : ''">
                {{ company.statusString }}
              </td>
              <td>
                <router-link
                  :to="{
                    name: 'admin-company-details',
                    params: {
                      id: company.id,
                    },
                  }"
                  ><img
                    class="edit-btn-img"
                    :src="require('@/assets/images/edit-btn.svg')"
                /></router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </Pagination>
  </div>
</template>
<script lang="ts" setup>
import axios from "axios";
import Pagination from "@/components/Pagination.vue";

import { ref } from "vue";
import TitleBar from "@/components/TitleBar.vue";
import { useListData } from "@/composables/useListData";
import { useStore } from "vuex";
const loading = ref(true);
const store = useStore();

const {
  list: companies,
  onSort: onCompanySort,
  sortField: companySortField,
  sortOrder: companySortOrder,
} = useListData({
  loadFunction: loadCompanies,
  defaultSortField: "companyName",
});

async function loadCompanies() {
  let result = [];
  try {
    const token = store.state.token;
    const response = await axios.get(
      process.env.VUE_APP_API_URL + "Company/list",
      {
        headers: {
          Authorization: "bearer " + token,
        },
      }
    );

    result = response.data;
  } catch (error) {
    //
  }
  loading.value = false;

  return result;
}
</script>

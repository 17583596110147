import axios from "axios";
import { ref } from "vue";
import { useStore } from "vuex";

export function useCompany() {
  const store = useStore();
  const companyListDataLoading = ref(true);
  const companies = ref([]);

  async function fetchCompanyList() {
    try {
      const token = store.state.token;
      const response = await axios.get(
        process.env.VUE_APP_API_URL + "Company/list",
        {
          headers: {
            Authorization: "bearer " + token,
          },
        }
      );

      companies.value = response.data;
    } catch (error) {
        // 
    }

    companyListDataLoading.value = false;
  }
  fetchCompanyList();
  return {
    companyListDataLoading,
    companies,
    fetchCompanyList,
  };
}

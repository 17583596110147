<template>
  <div class="d-flex align-items-center gap-2">
    <p v-if="!show && !loading">*****</p>
    <p v-if="show && !loading">{{ salary }}</p>
    <p v-if="loading">Loading...</p>
    <i v-if="!show && !loading" @click="showSalary" class="fa-solid fa-magnifying-glass"></i>
  </div>
</template>

<script setup lang="ts">
import { usePasswordVerify } from "@/composables/usePasswordVerify";
import axios from "axios";
import { ref, inject, defineProps } from "vue";
import { useStore } from "vuex";
const swal = inject("$swal") as any;
const props = defineProps(["userId"]);
const store = useStore();
const { verifyPassword } = usePasswordVerify();
const show = ref(false);
const salary = ref("");
const token = store.state.token;
const loading = ref(false);
async function showSalary() {
  show.value = false;
  loading.value = true;

  if (await verifyPassword()) {
    const el = swal.getHtmlContainer().querySelector("#passwordConfirm");

    if (el && el.value && props.userId) {
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + "employee/show-salary",
          {
            userId: props.userId,
          },
          {
            headers: {
              Authorization: "bearer " + token,
            },
          }
        );
        show.value = true;
        salary.value = response.data;
      } catch (_) {
        show.value = true;
        salary.value = "Error retrieving";
      }
    }
  }
  loading.value = false;
}
</script>
<style scoped>
i {
  cursor: pointer;
}
p {
  margin: 0;
  font-size: 20px;
  letter-spacing: 0.32px;
  color: #472667;
  text-transform: capitalize;
  opacity: 1;
  padding-top: 0;
}
</style>

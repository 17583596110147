import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "container mt-5" }
const _hoisted_2 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_3 = {
  key: 1,
  class: "text-danger"
}
const _hoisted_4 = {
  key: 2,
  class: "text-danger"
}
const _hoisted_5 = { class: "row g-3 pddtop20" }
const _hoisted_6 = { class: "col-sm-12" }
const _hoisted_7 = { class: "col-sm-5" }
const _hoisted_8 = { class: "col-sm-12" }
const _hoisted_9 = { class: "col-sm-5" }
const _hoisted_10 = { class: "col-sm-12" }
const _hoisted_11 = { class: "col-sm-5" }
const _hoisted_12 = { class: "button-section" }
const _hoisted_13 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleBar = _resolveComponent("TitleBar")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TitleBar),
    (_ctx.errorMsg != '')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.errorMsg), 1))
      : _createCommentVNode("", true),
    ($setup.invalidReset)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, "Invalid password reset"))
      : _createCommentVNode("", true),
    ($setup.pleaseWait)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, "Please wait..."))
      : _createCommentVNode("", true),
    _createElementVNode("form", {
      onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => ($setup.submit && $setup.submit(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _withDirectives(_createElementVNode("input", {
              type: "password",
              class: "form-control",
              id: "currentPassword",
              placeholder: "Current Password",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.formData.currentPassword) = $event))
            }, null, 512), [
              [_vModelText, $setup.formData.currentPassword]
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.v$.currentPassword.$errors, (error) => {
              return (_openBlock(), _createElementBlock("span", {
                class: "text-danger",
                key: error.$uid
              }, _toDisplayString(error.$message), 1))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _withDirectives(_createElementVNode("input", {
              type: "password",
              class: "form-control",
              id: "newPassword",
              placeholder: "New Password",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.formData.newPassword) = $event))
            }, null, 512), [
              [_vModelText, $setup.formData.newPassword]
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.v$.newPassword.$errors, (error) => {
              return (_openBlock(), _createElementBlock("span", {
                class: "text-danger",
                key: error.$uid
              }, _toDisplayString(error.$message), 1))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _withDirectives(_createElementVNode("input", {
              type: "password",
              class: "form-control",
              id: "confirmPassword",
              placeholder: "Confirmed Password",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.formData.confirmPassword) = $event))
            }, null, 512), [
              [_vModelText, $setup.formData.confirmPassword]
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.v$.confirmPassword.$errors, (error) => {
              return (_openBlock(), _createElementBlock("span", {
                class: "text-danger",
                key: error.$uid
              }, _toDisplayString(error.$message), 1))
            }), 128))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_router_link, {
          class: "btn btn-secondary mrgrbtn",
          to: "/dashboard"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Cancel")
          ]),
          _: 1
        }),
        _createElementVNode("button", {
          class: "btn btn-primary",
          type: "submit",
          disabled: $data.btnDisabled || $setup.sending
        }, _toDisplayString($setup.sending ? "Submitting..." : "Submit"), 9, _hoisted_13)
      ])
    ], 32)
  ]))
}
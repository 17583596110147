<template>
  <div class="container">
    <TitleBar></TitleBar>
    <div class="text-center" v-if="loading === true">
      <img :src="require('@/assets/images/loading.gif')" />
    </div>

    <template v-if="loading === false">
      <div class="page-title-s mb-2">
        <div class="search-box" @click="onSearch($event)">
          <i class="fa fa-search"></i>
        </div>
        <input type="text" v-model="search" placeholder="Search" />
      </div>

      <Pagination :totalItem="banks.length" :items="banks" :perPage="50">
        <template #data="{ paginatedItems, currentIndex }">
          <table class="table table-list" v-if="loading == false">
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col" class="pointer" @click="onSort('Name')">
                  <span
                    class="sort-arrow"
                    :class="
                      currentSort == 'Name'
                        ? currentSortDir == 'desc'
                          ? 'sort-arrow-desc'
                          : 'sort-arrow-asc'
                        : ''
                    "
                    >Name</span
                  >
                </th>
                <th scope="col" class="pointer" @click="onSort('Code')">
                  <span
                    class="sort-arrow"
                    :class="
                      currentSort == 'Code'
                        ? currentSortDir == 'desc'
                          ? 'sort-arrow-desc'
                          : 'sort-arrow-asc'
                        : ''
                    "
                    >Code</span
                  >
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(bank, index) in paginatedItems"
                :key="index + currentIndex"
              >
                <td scope="row">{{ index + currentIndex }}</td>
                <td>
                  {{ bank.bankName }}
                </td>
                <td>{{ bank.bankCode }}</td>
              </tr>
            </tbody>
          </table>
        </template>
      </Pagination>
    </template>
  </div>
</template>
<script lang="ts" setup>
import TitleBar from "@/components/TitleBar.vue";
import Pagination from "@/components/Pagination.vue";
import axios from "axios";
import { ref } from "vue";
const banks = ref([]);
const _banks = ref([]);
const loading = ref(true);
const currentSort = ref("Name");
const currentSortDir = ref("asc");
const search = ref("");

async function getBanks() {
  try {
    loading.value = true;

    const response = await axios.get(process.env.VUE_APP_API_URL + "Bank");
    banks.value = response.data;
    _banks.value = response.data;
    loading.value = false;
  } catch (_) {
    loading.value = false;
  }
}

function onSort(s) {
  if (s === currentSort.value) {
    currentSortDir.value = currentSortDir.value === "asc" ? "desc" : "asc";
  }
  currentSort.value = s;

  this.withdrawalList;
  this.withdrawalList.sort((a, b) => {
    let modifier = 1;
    if (currentSortDir.value === "desc") modifier = -1;
    if (a[currentSort.value] < b[currentSort.value]) return -1 * modifier;
    if (a[currentSort.value] > b[currentSort.value]) return 1 * modifier;
    return 0;
  });
}

function onSearch(event) {
  if (search.value === "") {
    banks.value = _banks.value;
  } else {
    banks.value = _banks.value.filter((p) => {
      return (
        p.bankName.toLowerCase().indexOf(search.value.toLowerCase()) != -1 ||
        (p.bankCode ?? "").toLowerCase().indexOf(search.value.toLowerCase()) !=
          -1
      );
    });
  }
}

getBanks();
</script>

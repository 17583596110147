import { computed, ref, Ref } from "vue";

type ListDataOptions<T = any> = {
  loadFunction?: () => Promise<T[]>;
  searchFunction?: (list: T[], query: string) => T[];
  defaultSortField?: string;
};

export function useListData<T = any>(options?: ListDataOptions<T>) {
  const _list = ref([]) as Ref<T[]>;
  const search = ref("");
  const _sortOrder = ref<"asc" | "desc">("asc");
  const _sortField = ref(options?.defaultSortField ?? "");

  const list = computed(() => {
    let newList = sortList(_list.value);
    if (options?.searchFunction) {
      newList = options.searchFunction(_list.value, search.value) ?? [];
    }
    return newList;
  });
  const sortOrder = computed(() => _sortOrder.value);
  const sortField = computed(() => _sortField.value);

  function onSort(field) {
    if (field === _sortField.value) {
      _sortOrder.value = _sortOrder.value === "asc" ? "desc" : "asc";
    }
    _sortField.value = field;
  }

  function sortList(unsortedList) {
    return unsortedList.sort((a, b) => {
      let modifier = 1;
      if (_sortOrder.value === "desc") modifier = -1;
      if (a[_sortField.value] < b[_sortField.value]) return -1 * modifier;
      if (a[_sortField.value] > b[_sortField.value]) return 1 * modifier;
      return 0;
    });
  }

  async function refresh() {
    _list.value = (await options?.loadFunction?.()) ?? [];
  }
  refresh();
  return { list, refresh, search, sortOrder, sortField, onSort };
}

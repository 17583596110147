<template>
  <div class="container mt-5">
    <TitleBar></TitleBar>
    <form @submit.prevent="submit">
      <div class="row g-3 pddbtm50">
        <div class="col-sm-5">
          <label for="firstName" class="form-label">First name*</label>
          <input v-model="formData.FirstName" type="text" class="form-control" id="firstName" placeholder="" />
          <span class="text-danger" v-for="error in v$.FirstName.$errors" :key="error.$uid">{{ error.$message }}</span>
        </div>

        <div class="col-sm-5">
          <label for="lastName" class="form-label">Last name</label>
          <input v-model="formData.LastName" type="text" class="form-control" id="lastName" placeholder="" />
          <span class="text-danger" v-for="error in v$.LastName.$errors" :key="error.$uid">{{ error.$message }}</span>
        </div>
        <div class="col-sm-5">
          <label for="lastName" class="form-label">IC Number*</label>
          <input v-model="formData.IcNumber" type="text" class="form-control" id="IcNumber" placeholder="" />
          <span class="text-danger" v-for="error in v$.IcNumber.$errors" :key="error.$uid">{{ error.$message }}</span>
        </div>
        <div class="col-sm-5">
          <label for="lastName" class="form-label">Employee Number*</label>
          <input v-model="formData.EmployeeNumber" type="text" class="form-control" id="EmployeeNumber" placeholder="" />
          <span class="text-danger" v-for="error in v$.EmployeeNumber.$errors" :key="error.$uid">{{ error.$message
          }}</span>
        </div>
        <div class="col-5">
          <label for="email" class="form-label">Email* <span class="text-muted"></span></label>
          <input v-model="formData.Email" type="text" class="form-control" id="email" placeholder="" />
          <span class="text-danger" v-for="error in v$.Email.$errors" :key="error.$uid">{{ error.$message }}</span>
        </div>

        <div class="col-5">
          <label for="DataOfBirth" class="form-label">Date of birth* </label>
          <Datepicker v-model="formData.DataOfBirth" :enable-time-picker="false" :format="format" :auto-apply="true"
            :close-on-auto-apply="true" class="calender-picker-field">
            <template #input-icon>
              <img :src="require('@/assets/images/Group-714.svg')" alt="" class="calander-logo" />
            </template>
          </Datepicker>
          <span class="text-danger" v-for="error in v$.DataOfBirth.$errors" :key="error.$uid">{{ error.$message }}</span>
        </div>
        <div class="col-5">
          <label for="Address1" class="form-label">Address 1 </label>
          <input v-model="formData.Address1" type="text" class="form-control" id="Address1" placeholder="" />
          <span class="text-danger" v-for="error in v$.Address1.$errors" :key="error.$uid">{{ error.$message }}</span>
        </div>
        <div class="col-5">
          <label for="Address2" class="form-label">Address 2 </label>
          <input v-model="formData.Address2" type="text" class="form-control" id="Address2" placeholder="" />
          <span class="text-danger" v-for="error in v$.Address2.$errors" :key="error.$uid">{{ error.$message }}</span>
        </div>
        <div class="col-5">
          <label for="City" class="form-label">City </label>
          <input v-model="formData.City" type="text" class="form-control" id="City" placeholder="" />
          <span class="text-danger" v-for="error in v$.City.$errors" :key="error.$uid">{{ error.$message }}</span>
        </div>
        <div class="col-5">
          <label for="PostCode" class="form-label">Postcode </label>
          <input v-model="formData.PostCode" type="text" class="form-control" id="PostCode" placeholder="" />
          <span class="text-danger" v-for="error in v$.PostCode.$errors" :key="error.$uid">{{ error.$message }}</span>
        </div>

        <div class="col-5">
          <label for="contactNumber" class="form-label">Contact Number* </label>
          <input v-model="formData.ContactNumber" type="text" class="form-control" id="ContactNumber" placeholder="" />
          <span class="text-danger" v-for="error in v$.ContactNumber.$errors" :key="error.$uid">{{ error.$message
          }}</span>
        </div>
        <div class="col-5">
          <label for="DepartmentName" class="form-label">Department* </label>
          <input v-model="formData.DepartmentName" type="text" class="form-control" id="DepartmentName" placeholder="" />
          <span class="text-danger" v-for="error in v$.DepartmentName.$errors" :key="error.$uid">{{ error.$message
          }}</span>
        </div>
        <div class="col-5">
          <label for="Position" class="form-label">Position* </label>
          <input v-model="formData.Position" type="text" class="form-control" id="Position" placeholder="" />
          <span class="text-danger" v-for="error in v$.Position.$errors" :key="error.$uid">{{ error.$message }}</span>
        </div>
        <div class="col-sm-5">
          <label for="Bank" class="form-label">Bank*</label>
          <select v-model="formData.BankId" type="text" class="form-control" id="BankId" placeholder="">
            <option value="">Please select</option>
            <option v-for="bank in banks" :key="bank.id" v-bind:value="bank.id">
              {{ bank.bankName }}
            </option>
          </select>
          <span class="text-danger" v-for="error in v$.BankId.$errors" :key="error.$uid">{{ error.$message }}</span>
        </div>
        <div class="col-sm-5">
          <label for="lastName" class="form-label">Bank Account Number*</label>
          <input v-model="formData.BankAccountNumber" type="text" class="form-control" id="BankAccountNumber"
            placeholder="" />
          <span class="text-danger" v-for="error in v$.BankAccountNumber.$errors" :key="error.$uid">{{ error.$message
          }}</span>
        </div>
        <div class="col-sm-5">
          <label for="lastName" class="form-label">Basic salary (including fixed allowance)*</label>
          <input v-model="formData.GrossSalary" type="number" class="form-control" id="GrossSalary" placeholder="" />
          <span class="text-danger" v-for="error in v$.GrossSalary.$errors" :key="error.$uid">{{ error.$message }}</span>
        </div>
        <div class="col-sm-5">
          <label for="SubCompany" class="form-label">Company</label>
          <select v-model="formData.SubCompany" type="text" class="form-control" id="SubCompany" placeholder="">
            <option value="">Please select</option>
            <option v-for="subcompany in subcompanies" :key="subcompany.id" v-bind:value="subcompany.id">
              {{ subcompany.companyName }}
            </option>
          </select>
          <span class="text-danger" v-for="error in v$.SubCompany.$errors" :key="error.$uid">{{ error.$message }}</span>
        </div>
        <div class="col-sm-5">
          <label for="JoinedDate" class="form-label">Joined Date </label>
          <Datepicker v-model="formData.JoinedDate" :enable-time-picker="false" :format="format" :auto-apply="true"
            :close-on-auto-apply="true" class="calender-picker-field">
            <template #input-icon>
              <img :src="require('@/assets/images/Group-714.svg')" alt="" class="calander-logo" />
            </template>
          </Datepicker>
          <span class="text-danger" v-for="error in v$.JoinedDate.$errors" :key="error.$uid">{{ error.$message }}</span>
        </div>
        <div class="col-sm-5">
          <label for="JoinedDate" class="form-label">Withdrawal Start Date
          </label>
          <Datepicker v-model="formData.WithdrawalStartDate" :enable-time-picker="false" :format="format"
            :auto-apply="true" :close-on-auto-apply="true" class="calender-picker-field">
            <template #input-icon>
              <img :src="require('@/assets/images/Group-714.svg')" alt="" class="calander-logo" />
            </template>
          </Datepicker>
          <span class="text-danger" v-for="error in v$.JoinedDate.$errors" :key="error.$uid">{{ error.$message }}</span>
        </div>
        <div class="col-sm-5">
          <label for="MainStatus" class="form-label">Account Status*</label>
          <select v-model="formData.MainStatus" type="text" class="form-control" id="MainStatus" placeholder="">
            <option value="1">Active</option>
            <option value="0">Deactivate</option>
          </select>
          <span class="text-danger" v-for="error in v$.MainStatus.$errors" :key="error.$uid">{{ error.$message }}</span>
        </div>
        <div class="col-sm-5" v-if="formData.MainStatus == 0">
          <label for="GracePeriod" class="form-label">Notice Period </label>
          <Datepicker v-model="formData.GracePeriod" :enable-time-picker="false" :format="format" :auto-apply="true"
            :close-on-auto-apply="true" class="calender-picker-field">
            <template #input-icon>
              <img :src="require('@/assets/images/Group-714.svg')" alt="" class="calander-logo" />
            </template>
          </Datepicker>
          <span class="text-danger" v-for="error in v$.GracePeriod.$errors" :key="error.$uid">{{ error.$message }}</span>
        </div>
      </div>
      <!-- <hr class="my-4" /> -->
      <div class="text-danger" v-if="errorMsg != ''" style="margin-bottom: 20px">
        {{ errorMsg }}
      </div>
      <router-link class="btn btn-secondary mrgrbtn" :to="{
        name: 'employee-details',
        params: {
          id: formData.Id,
        },
      }">Cancel</router-link>
      <button class="btn btn-primary" type="submit">Submit</button>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  email,
  sameAs,
  helpers,
  decimal,
  numeric,
  requiredIf,
} from "@vuelidate/validators";
import { reactive } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import store from "@/store";
import Datepicker from "@vuepic/vue-datepicker";
import axios from "axios";
import TitleBar from "@/components/TitleBar.vue";
export default defineComponent({
  name: "EmployeeUpdate",
  setup() {
    const toast = useToast();
    const router = useRouter();
    const token = store.state.token;
    const errorMsg = ref("");
    const DataOfBirth = ref(new Date());
    const format = (DataOfBirth) => {
      const day = DataOfBirth.getDate();
      const month = DataOfBirth.getMonth() + 1;
      const year = DataOfBirth.getFullYear();

      return `${day}-${month}-${year}`;
    };

    const sending = ref(false);
    const formData = reactive({
      Id: 0,
      FirstName: "",
      LastName: "",
      IcNumber: "",
      Email: "",
      ContactNumber: "",
      DepartmentName: "",
      Position: "",
      BankId: 0,
      BankAccountNumber: "",
      GrossSalary: 0,
      EmployeeNumber: "",
      CurrentContactNumber: "",
      CurrentEmployeeNumber: "",
      DataOfBirth: "",
      Address1: "",
      Address2: "",
      City: "",
      PostCode: "",
      MainStatus: 1,
      JoinedDate: null,
      WithdrawalStartDate: null,
      SubCompany: null,
      GracePeriod: null,
      ezpaVsureSubscribe: 0,
    });

    const submit = async () => {
      if (sending.value) return;

      sending.value = true;

      const result = await v$.value.$validate();
      if (formData.MainStatus == 1) {
        formData.GracePeriod = null;
      }
      if (result) {
        await axios
          .put(process.env.VUE_APP_API_URL + "employee/update", formData, {
            headers: {
              Authorization: "bearer " + token,
            },
          })
          .then((res) => {
            errorMsg.value = "";

            toast.success("Employee successfully updated", {
              timeout: 3000,
            });
            sending.value = false;
            router.push({
              name: "employee-details",
              params: { id: formData.Id },
            });
          })
          .catch((err) => {
            sending.value = false;
            errorMsg.value = err.response.data;
          });
      }
    };

    const rules = computed(() => {
      return {
        FirstName: {
          required: helpers.withMessage("Please enter first name", required),
        },
        LastName: {
        },
        IcNumber: {
          required: helpers.withMessage("Please enter IC Number", required),
        },
        Email: {
          email,
          required: helpers.withMessage(
            "Please enter email if contact number is not available",
            requiredIf(!formData.ContactNumber || formData.ContactNumber === "")
          ),
        },
        BankId: {
          required: helpers.withMessage("Please select bank", required),
        },
        EmployeeNumber: {
          required: helpers.withMessage(
            "Please enter employee number",
            required
          ),
        },
        BankAccountNumber: {
          required: helpers.withMessage(
            "Please enter bank account number",
            required
          ),
        },
        ContactNumber: {
          required: helpers.withMessage(
            "Please enter contact number if email is not available",
            requiredIf(!formData.Email && formData.Email === "")
          ),
          phoneformat: helpers.withMessage(
            "Invalid Contact Number",
            helpers.regex(/^[6][0][1][0-9]+$/)
          ),
          numeric,
        },
        DepartmentName: {
          required: helpers.withMessage(
            "Please enter department name",
            required
          ),
        },
        Position: {
          required: helpers.withMessage("Please enter position", required),
        },
        GrossSalary: {
          required: helpers.withMessage("Please enter salaray", required),
          decimal,
        },
        DataOfBirth: {
          required: helpers.withMessage("Please enter date of birth", required),
        },
        Address1: {
          requiredIf: helpers.withMessage(
            "Please enter address 1",
            requiredIf(() => formData.ezpaVsureSubscribe == 1)
          ),
        },
        Address2: {},
        City: {
          requiredIf: helpers.withMessage(
            "Please enter City",
            requiredIf(() => formData.ezpaVsureSubscribe == 1)
          ),
        },
        PostCode: {
          requiredIf: helpers.withMessage(
            "Please enter PostCode",
            requiredIf(() => formData.ezpaVsureSubscribe == 1)
          ),
          numeric,
        },
        MainStatus: {
          required: helpers.withMessage(
            "Please select Account Status",
            required
          ),
        },
        JoinedDate: {},
        WithdrawalStartDate: {},
        SubCompany: {},
        GracePeriod: {},
      };
    });
    const v$ = useVuelidate(rules, formData);

    return {
      v$,
      formData,
      token,
      router,
      toast,
      errorMsg,
      submit,
      DataOfBirth,
      format,
    };
  },
  data() {
    return {
      banks: ref([]),
      loading: true,
      subcompanies: ref([]),
    };
  },
  components: {
    Datepicker,
    TitleBar,
  },
  methods: {
    async subcompanyList() {
      const token = store.state.token;
      const response = await axios.get(
        process.env.VUE_APP_API_URL + "SubCompany/list",
        {
          headers: {
            Authorization: "bearer " + token,
          },
        }
      );
      this.loading = false;
      this.subcompanies = response.data;
    },
    async bankList() {
      const response = await axios.get(process.env.VUE_APP_API_URL + "Bank");
      this.banks = response.data;
    },
    async employeeDetails() {
      const id = this.$route.params.id;

      await axios
        .get(process.env.VUE_APP_API_URL + "employee/" + id + "/details", {
          headers: {
            Authorization: "bearer " + this.token,
          },
        })
        .then((res) => {
          this.formData.Id = res.data.id;
          this.formData.Email = res.data.email;
          this.formData.FirstName = res.data.firstName;
          this.formData.LastName = res.data.lastName;
          this.formData.IcNumber = res.data.icNumber;
          this.formData.ContactNumber = res.data.contactNumber;
          this.formData.DepartmentName = res.data.departmentName;
          this.formData.BankId = res.data.bankId;
          this.formData.Position = res.data.position;

          this.formData.BankAccountNumber = res.data.bankAccountNumber;
          this.formData.GrossSalary = res.data.grossSalary;
          this.formData.EmployeeNumber = res.data.employeeNumber;
          this.formData.CurrentContactNumber = res.data.contactNumber;
          this.formData.CurrentEmployeeNumber = res.data.employeeNumber;

          this.formData.DataOfBirth = res.data.dataOfBirth;
          this.formData.Address1 = res.data.address1;
          this.formData.Address2 = res.data.address2;
          this.formData.City = res.data.city;
          this.formData.PostCode = res.data.postCode;
          this.formData.MainStatus = res.data.mainStatus;
          this.formData.ezpaVsureSubscribe = res.data.ezpaVsureSubscribe;
          this.formData.JoinedDate = res.data.joinedDate;
          this.formData.WithdrawalStartDate = res.data.withdrawalStartDate;
          this.formData.SubCompany = res.data.subCompany;
          this.formData.GracePeriod = res.data.gracePeriod;
        })
        .catch((err) => {
          // 
        });
      this.loading = false;
    },
  },
  validations() {
    return {};
  },
  mounted() {
    this.employeeDetails();
    this.bankList();
    this.subcompanyList();
  },
});
</script>

<template>
  <div class="container">
    <TitleBar></TitleBar>
    <div class="text-center" v-if="loading">
      <img :src="require('@/assets/images/loading.gif')" />
    </div>

    <div v-if="!loading">
      <div class="mb-3 row">
        <label
          for="staticEmail"
          class="col-sm-4 col-form-label detail-page-label"
          >Merchant Name</label
        >
        <div class="col-sm-8">
          <div class="form-control-plaintext detail-page-input">
            {{ balanceInfo.merchantName }}
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label
          for="staticEmail"
          class="col-sm-4 col-form-label detail-page-label"
          >Balance</label
        >
        <div class="col-sm-8">
          <div class="form-control-plaintext detail-page-input">
            RM {{ balanceFormatted }}
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label
          for="staticEmail"
          class="col-sm-4 col-form-label detail-page-label"
          >Curlec Url</label
        >
        <div class="col-sm-8">
          <div class="form-control-plaintext detail-page-input">
            <a
              :href="balanceInfo.curlecUrl"
              rel="noopener noreferrer"
              target="_blank"
            >
              {{ balanceInfo.curlecUrl }}
            </a>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label
          for="staticEmail"
          class="col-sm-4 col-form-label detail-page-label"
          >Merchant Id</label
        >
        <div class="col-sm-8">
          <div class="form-control-plaintext detail-page-input">
            {{ balanceInfo.merchantId }}
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label
          for="staticEmail"
          class="col-sm-4 col-form-label detail-page-label"
          >Employee Id</label
        >
        <div class="col-sm-8">
          <div class="form-control-plaintext detail-page-input">
            {{ balanceInfo.employeeId }}
          </div>
        </div>
      </div>

      <p class="text-danger" v-if="error !== ''">{{ error }}</p>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, reactive, ref } from "vue";
import axios from "axios";

import { useStore } from "vuex";
import TitleBar from "@/components/TitleBar.vue";
const store = useStore();

const loading = ref(true);
const balanceInfo = reactive({
  merchantName: "",
  balance: 0,
  curlecUrl: "",
  merchantId: "",
  employeeId: "",
});
const error = ref("");

const balanceFormatted = computed(() => {
  return balanceInfo.balance.toLocaleString("en-US");
});

async function loadData() {
  const token = store.state.token;
  loading.value = true;
  error.value = "";
  try {
    const response = await axios.get(
      process.env.VUE_APP_API_URL + "Withdrawal/curlec/balance",
      {
        headers: {
          Authorization: "bearer " + token,
        },
      }
    );
    balanceInfo.merchantName = response.data.merchantName;
    balanceInfo.balance = response.data.balance;
    balanceInfo.curlecUrl = response.data.curlecUrl;
    balanceInfo.merchantId = response.data.merchantId;
    balanceInfo.employeeId = response.data.employeeId;
  } catch (error) {
    error.value = error.message;
  }
  loading.value = false;
}

loadData();
</script>
<style scoped>
.detail-page-input {
  text-transform: none;
}
</style>

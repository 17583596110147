<script setup lang="ts">
import { ref, reactive } from "vue";
import Pagination from "@/components/Pagination.vue";
import axios from "axios";
import TitleBar from "@/components/TitleBar.vue";
import { useCompany } from "@/composables/useCompany";
import { VueFinalModal } from "vue-final-modal";
import { useStore } from "vuex";
const { companies } = useCompany();
const store = useStore();

const formData = reactive({
  recipientType: "all",
  companies: [],
  notificationTitle: "",
  notificationBody: "",
  users: "",
});
const error = ref("");
const show = ref(false);
const loading = ref(false);
const result = ref([]);
async function submit() {
  show.value = false;
  loading.value = true;
  error.value = "";
  result.value = [];
  try {
    const response = await axios.post(
      process.env.VUE_APP_API_URL + "PushNotification/sent",
      {
        recipientType: formData.recipientType,
        companies: formData.companies.join(","),
        notificationTitle: formData.notificationTitle,
        notificationBody: formData.notificationBody,
        users: formData.users,
      },
      {
        headers: {
          Authorization: "bearer " + store.state.token,
        },
      }
    );
    result.value = response.data;
  } catch (err) {
    error.value = err?.response.data ?? err.message;
  }
  loading.value = false;
}
</script>
<template>
  <div>
    <TitleBar />

    <div style="max-width: 600px">
      <div class="mb-4">
        <input
          type="text"
          id="notification-title"
          class="form-control"
          placeholder=" Notification Title"
          v-model="formData.notificationTitle"
        />
      </div>

      <div class="mb-4">
        <div class="form-floating">
          <textarea
            v-model="formData.notificationBody"
            class="form-control"
            placeholder="Leave a comment here"
            id="notification-body"
            style="height: 100px"
          ></textarea>
          <label for="notification-body">Notification Body</label>
        </div>
      </div>

      <label class="form-label" for="recipientType">
        Choose Notification Recipients
      </label>
      <select
        class="form-control mb-4"
        id="recipientType"
        placeholder="Please Select"
        v-model="formData.recipientType"
      >
        <option value="all">All Recipients</option>
        <option value="company">Specific Companies</option>
        <option value="users">Specific Users</option>
      </select>

      <div class="mb-4" v-if="formData.recipientType === 'company'">
        <div class="form-check" v-for="company in companies" :key="company.id">
          <input
            class="form-check-input"
            v-model="formData.companies"
            :id="'company-' + company.id"
            :value="company.id"
            type="checkbox"
          />
          <label
            style="cursor: pointer"
            class="form-check-label"
            :for="'company-' + company.id"
          >
            {{ company.companyName }}
          </label>
        </div>
      </div>
      <div class="mb-4" v-if="formData.recipientType === 'users'">
        <label for="users" class="form-label">Specific Users</label>
        <input
          v-model="formData.users"
          type="text"
          id="users"
          class="form-control"
          aria-describedby="users-help"
        />
        <div id="users-help" class="form-text">
          Enter phone numbers separated by commas. Example: 1234567890,
          0987654321
        </div>
      </div>
      <p class="text-danger" v-if="error !== ''">{{ error }}</p>
      <button
        class="btn btn-primary btn-sm btn-list btn-list-3 mb-4"
        @click="show = true"
      >
        Send Notification
      </button>
    </div>

    <Pagination
      v-if="result.length > 0"
      :totalItem="result.length"
      :items="result"
      :perPage="10"
    >
      <template #data="{ paginatedItems, currentIndex }">
        <table class="table table-list">
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col" class="pointer">Name</th>
              <th scope="col" class="pointer">Contact Number</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in paginatedItems"
              :key="index + currentIndex"
            >
              <td scope="row">{{ index + currentIndex }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.contactNumber }}</td>
            </tr>
          </tbody>
        </table>
      </template>
    </Pagination>
    <VueFinalModal
      v-model="show"
      class="sent-notification-modal"
      content-class="sent-notification-content"
      content-transition="dialog-show"
    >
      <div class="d-flex justify-content-end w-100">
        <button type="button" class="close-btn" @click="show = false">x</button>
      </div>
      <div class="w-100 mb-4 text-center" style="flex: 1">
        <h1 style="font-size: 32px">
          Are you sure you want to sent notification?
        </h1>
      </div>
      <button
        @click="submit"
        type="button"
        class="btn btn-secondary px-4 mb-4"
        style="width: fit-content"
      >
        Send Notification
      </button>
    </VueFinalModal>
  </div>
</template>
<style scoped>
.close-btn {
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.2em;
  height: 1.2em;
  overflow: hidden;
  transition: color 0.1s, box-shadow 0.1s;
  border: none;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0);
  color: #ccc;
  font-family: serif;
  font-family: monospace;
  font-size: 2.5em;
  cursor: pointer;
  justify-self: end;
}
</style>
<style>
.sent-notification-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000 !important;
}
.sent-notification-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline: 2rem;
  padding-bottom: 1rem;
  background: #fff;
  border-radius: 0.5rem;
  width: 95%;
  max-width: 600px;
  min-height: 300px;
}

/* Refer Vue 'Transition' component for this */
.dialog-show-enter-from,
.dialog-show-enter-active,
.dialog-show-enter-to {
  animation: dialog-show-animation 0.3s;
}

@keyframes dialog-show-animation {
  0% {
    transform: scale(0.7);
  }
  45% {
    transform: scale(1.05);
  }
  80% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}
</style>

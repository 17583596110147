<template>
  <div class="container">
    <TitleBar></TitleBar>
    <div class="text-center" v-if="loading == true">
      <img :src="require('@/assets/images/loading.gif')" />
    </div>

    <div v-if="loading == false">
      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-4 col-form-label detail-page-label">First Name</label>
        <div class="col-sm-8">
          <div class="form-control-plaintext detail-page-input">
            {{
              Nrequests.oriFirstName != null && Nrequests.oriFirstName != ""
              ? Nrequests.oriFirstName
              : "-"
            }}
            ->
            <span :class="Nrequests.oriFirstName != Nrequests.firstName ? 'bg-yellow' : ''
                ">
              {{
                Nrequests.firstName != null && Nrequests.firstName != ""
                ? Nrequests.firstName
                : "-"
              }}
            </span>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-4 col-form-label detail-page-label">Last Name</label>
        <div class="col-sm-8">
          <div class="form-control-plaintext detail-page-input">
            {{
              Nrequests.oriLastName != null && Nrequests.oriLastName != ""
              ? Nrequests.oriLastName
              : "-"
            }}
            ->
            <span :class="Nrequests.oriLastName != Nrequests.lastName ? 'bg-yellow' : ''
                ">
              {{
                Nrequests.lastName != null && Nrequests.lastName != ""
                ? Nrequests.lastName
                : "-"
              }}
            </span>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-4 col-form-label detail-page-label">IC Number</label>
        <div class="col-sm-8">
          <div class="form-control-plaintext detail-page-input">
            {{
              Nrequests.oriIcNumber != null && Nrequests.oriIcNumber != ""
              ? Nrequests.oriIcNumber
              : "-"
            }}
            ->
            <span :class="Nrequests.oriIcNumber != Nrequests.icNumber ? 'bg-yellow' : ''
                ">
              {{
                Nrequests.icNumber != null && Nrequests.icNumber != ""
                ? Nrequests.icNumber
                : "-"
              }}
            </span>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-4 col-form-label detail-page-label">Employee Number</label>
        <div class="col-sm-8">
          <div class="form-control-plaintext detail-page-input">
            {{
              Nrequests.oriEmployeeNumber != null &&
              Nrequests.oriEmployeeNumber != ""
              ? Nrequests.oriEmployeeNumber
              : "-"
            }}
            ->
            <span :class="Nrequests.oriEmployeeNumber != Nrequests.employeeNumber
                  ? 'bg-yellow'
                  : ''
                ">
              {{
                Nrequests.employeeNumber != null &&
                Nrequests.employeeNumber != ""
                ? Nrequests.employeeNumber
                : "-"
              }}
            </span>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-4 col-form-label detail-page-label">Email</label>
        <div class="col-sm-8">
          <div class="form-control-plaintext detail-page-input">
            {{
              Nrequests.oriEmail != null && Nrequests.oriEmail != ""
              ? Nrequests.oriEmail
              : "-"
            }}
            ->
            <span :class="Nrequests.oriEmail != Nrequests.email ? 'bg-yellow' : ''">
              {{
                Nrequests.email != null && Nrequests.email != ""
                ? Nrequests.email
                : "-"
              }}
            </span>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-4 col-form-label detail-page-label">Contact Number</label>
        <div class="col-sm-8">
          <div class="form-control-plaintext detail-page-input">
            {{
              Nrequests.oriContactNumber != null &&
              Nrequests.oriContactNumber != ""
              ? Nrequests.oriContactNumber
              : "-"
            }}
            ->
            <span :class="Nrequests.oriContactNumber != Nrequests.contactNumber
                  ? 'bg-yellow'
                  : ''
                ">
              {{
                Nrequests.contactNumber != null && Nrequests.contactNumber != ""
                ? Nrequests.contactNumber
                : "-"
              }}
            </span>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-4 col-form-label detail-page-label">Department</label>
        <div class="col-sm-8">
          <div class="form-control-plaintext detail-page-input">
            {{
              Nrequests.oriDepartmentName != null &&
              Nrequests.oriDepartmentName != ""
              ? Nrequests.oriDepartmentName
              : "-"
            }}
            ->
            <span :class="Nrequests.oriDepartmentName != Nrequests.departmentName
                  ? 'bg-yellow'
                  : ''
                ">
              {{
                Nrequests.departmentName != null &&
                Nrequests.departmentName != ""
                ? Nrequests.departmentName
                : "-"
              }}
            </span>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-4 col-form-label detail-page-label">Position</label>
        <div class="col-sm-8">
          <div class="form-control-plaintext detail-page-input">
            {{
              Nrequests.oriPosition != null && Nrequests.oriPosition != ""
              ? Nrequests.oriPosition
              : "-"
            }}
            ->
            <span :class="Nrequests.oriPosition != Nrequests.position ? 'bg-yellow' : ''
                ">
              {{
                Nrequests.position != null && Nrequests.position != ""
                ? Nrequests.position
                : "-"
              }}
            </span>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-4 col-form-label detail-page-label">Bank</label>
        <div class="col-sm-8">
          <div class="form-control-plaintext detail-page-input">
            {{
              Nrequests.oriBankName != null && Nrequests.oriBankName != ""
              ? Nrequests.oriBankName
              : "-"
            }}
            ->
            <span :class="Nrequests.oriBankName != Nrequests.bankName ? 'bg-yellow' : ''
                ">
              {{
                Nrequests.bankName != null && Nrequests.bankName != ""
                ? Nrequests.bankName
                : "-"
              }}
            </span>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-4 col-form-label detail-page-label">Bank Account Number</label>
        <div class="col-sm-8">
          <div class="form-control-plaintext detail-page-input">
            {{
              Nrequests.oriBankAccountNumber != null &&
              Nrequests.oriBankAccountNumber != ""
              ? Nrequests.oriBankAccountNumber
              : "-"
            }}
            ->
            <span :class="Nrequests.oriBankAccountNumber != Nrequests.bankAccountNumber
                  ? 'bg-yellow'
                  : ''
                ">
              {{
                Nrequests.bankAccountNumber != null &&
                Nrequests.bankAccountNumber != ""
                ? Nrequests.bankAccountNumber
                : "-"
              }}
            </span>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-4 col-form-label detail-page-label">Basic salary (including fixed
          allowance)</label>
        <div class="col-sm-8">
          <div class="form-control-plaintext detail-page-input">
            {{
              Nrequests.oriGrossSalary != null && Nrequests.oriGrossSalary != ""
              ? parseFloat(Nrequests.oriGrossSalary)
                .toFixed(2)
                .toLocaleString()
              : "-"
            }}
            ->
            <span :class="Nrequests.oriGrossSalary != Nrequests.grossSalary
                  ? 'bg-yellow'
                  : ''
                ">
              {{
                Nrequests.grossSalary != null && Nrequests.grossSalary != ""
                ? parseFloat(Nrequests.grossSalary)
                  .toFixed(2)
                  .toLocaleString()
                : "-"
              }}
            </span>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-4 col-form-label detail-page-label">Request At</label>
        <div class="col-sm-8">
          <div class="form-control-plaintext detail-page-input">
            {{
              Nrequests.updatedAt != null && Nrequests.updatedAt != ""
              ? moment(Nrequests.updatedAt).format("DD MMMM YYYY, h:mm A")
              : "-"
            }}
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-4 col-form-label detail-page-label">Status</label>
        <div class="col-sm-8">
          <div class="form-control-plaintext detail-page-input">
            {{
              Nrequests.statusString != null && Nrequests.statusString != ""
              ? Nrequests.statusString
              : "-"
            }}
          </div>
        </div>
      </div>
      <div class="text-danger" v-if="errorMsg != ''">
        {{ errorMsg }}
      </div>
      <!-- <hr class="my-4" /> -->
      <div class="pddtop30">
        <div class="btn btn-primary mrgrbtn" type="submit" v-if="Nrequests.status == 0" @click="approvePR">
          Approve
        </div>
        <div class="btn btn-primary mrgrbtn" type="submit" v-if="Nrequests.status == 0" @click="rejectPR">
          Reject
        </div>
        <router-link class="btn btn-secondary" :to="{
          name: 'updateprofilerequest-list',
        }">Back</router-link>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import store from "@/store";
import axios from "axios";
import { defineComponent, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import moment from "moment";
import { useToast } from "vue-toastification";
import TitleBar from "@/components/TitleBar.vue";
export default defineComponent({
  name: "UpdateProfileRequestDetails",
  setup() {
    const store = useStore();
    const token = store.state.token;
    const toast = useToast();
    const router = useRouter();
    const role = computed(() => {
      return store.state.role;
    });

    return { role, token, router, toast };
  },
  components: {
    TitleBar,
  },
  data() {
    return {
      id: ref(0),
      Nrequests: ref([] as any),
      loading: true,
      errorMsg: ref(""),
      moment: moment,
      approving: false,
      rejecting: false,
    };
  },
  methods: {
    async approvePR() {
      if (this.approving) return;
      this.approving = true;
      const token = store.state.token;
      await axios
        .put(
          process.env.VUE_APP_API_URL +
          "user/approveProfileRequestDetails/" +
          this.id,
          "",
          {
            headers: {
              Authorization: "bearer " + token,
            },
          }
        )
        .then((res) => {
          this.toast.success("Successfully updated", {
            timeout: 3000,
          });
          this.errorMsg = "";
          this.approving = false;
          this.router.push({
            name: "updateprofilerequest-list",
          });
        })
        .catch((err) => {
          this.approving = false;
          this.errorMsg = err.response.data;
        });
    },
    async rejectPR() {
      if (this.rejecting) return;
      this.rejecting = true;
      const token = store.state.token;
      await axios
        .put(
          process.env.VUE_APP_API_URL +
          "user/rejectProfileRequestDetails/" +
          this.id,
          "",
          {
            headers: {
              Authorization: "bearer " + token,
            },
          }
        )
        .then((res) => {
          this.toast.success("Successfully updated", {
            timeout: 3000,
          });
          this.errorMsg = "";
          this.rejecting = false;
          this.router.push({
            name: "updateprofilerequest-list",
          });
        })
        .catch((err) => {
          this.rejecting = false;
          this.errorMsg = err.response.data;
        });
    },
    async nrequestsDetails() {
      this.id = this.$route.params.id;

      const token = store.state.token;
      const response = await axios
        .get(
          process.env.VUE_APP_API_URL +
          "user/getUserProfileRequest/" +
          this.id +
          "/details",
          {
            headers: {
              Authorization: "bearer " + token,
            },
          }
        )
        .then((res) => {
          this.Nrequests = res.data;
        })
        .catch((err) => {
          // 
        });
      this.loading = false;
    },
  },
  mounted() {
    this.nrequestsDetails();

  },
});
</script>

<template>
  <header
    class="navbar navbar-dark sticky-top flex-md-nowrap p-0 shadow navbar-header"
  >
    <router-link
      v-if="role != 'visitor'"
      to="/dashboard"
      class="navbar-brand col-md-3 col-lg-2 me-0 px-2 px-md-3 fs-6 navbar-logo"
      href="#"
    >
      <div
        v-if="
          getuserdetail &&
          (getuserdetail['companyLogo'] || getuserdetail['companyName'])
        "
      >
        <div
          v-if="getuserdetail && getuserdetail['companyLogo']"
          class="nav-item-inline nav-item-border"
        >
          <div class="companylogo-bg">
            <img
              class="companylogo"
              :src="imgBaseUrl + getuserdetail['companyLogo']"
            />
          </div>
        </div>
        <span
          v-if="getuserdetail && getuserdetail['companyName']"
          class="nav-item-inline nav-company-name"
          >{{ getuserdetail["companyName"] }}</span
        >
      </div>
      <div v-else>
        <div class="nav-item-inline nav-item-border">
          <div class="companylogo-bg">
            <img
              class="companylogo"
              :src="require('@/assets/images/mulaaa.png')"
            />
          </div>
        </div>
        <span class="nav-item-inline nav-company-name">Mulaaa</span>
      </div>
    </router-link>

    <div
      v-else
      class="navbar-brand col-md-3 col-lg-2 me-0 px-2 px-md-3  fs-6 nav-background"
    ></div>

    <div class="nav-right-section" v-if="role != 'visitor'">
      <!-- <i class="fa fa-bell nav-right-btn nav-bell"></i> -->
      <span @click="toggle(menus.id)" ref="navheader" class="nav-right-btn">
        <img
          :src="require('@/assets/images/Group-1440.svg')"
          alt=""
          class="nav-right-logo-1"
        />
        <img
          :src="require('@/assets/images/Component-22–2.svg')"
          alt=""
          class="nav-right-logo-2"
        />
      </span>
      <!-- <i
        class="fa fa-user nav-right-btn"
        @click="toggle(menus.id)"
        ref="navheader"
      ></i> -->
      <!-- <button class="button is-white" @click="toggle(menus.id)">
            {{ menus.name }}
            <ion-icon name="chevron-down-outline"></ion-icon>
          </button> -->
      <div class="dropdown profile-btn-dd" v-show="menus.id === open">
        <!-- <div to="/" v-for="item in menus.subMenu" :key="item"> -->
        <router-link to="/my-profile" class="profile-btn-options">
          <div class="options profile-btn-options">My Profile</div>
        </router-link>
        <router-link to="/reset-password" class="profile-btn-options">
          <div class="options profile-btn-options">Reset Password</div>
        </router-link>
        <div class="options profile-btn-options" @click="logOut">
          <a class="nav-link" style="cursor: pointer">Logout</a>
        </div>
        <!-- </div> -->
      </div>
    </div>
    <button
      class="navbar-toggler position-absolute d-md-none collapsed"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#sidebarMenu"
      aria-controls="sidebarMenu"
      aria-expanded="false"
      aria-label="Toggle navigation"
      v-if="role != 'visitor'"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="navbar-nav nav-login-section">
      <div class="nav-item text-nowrap">
        <a href="/login" class="nav-link px-3 pointer" v-if="role == 'visitor'"
          >Login</a
        >
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { computed, getTransitionRawChildren, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import axios from "axios";
export default {
  name: "Header",
  setup() {
    const store = useStore();
    const router = useRouter();
    const role = computed(() => store.state.role);
    const getuserdetail = computed(() => {
      return store.state.userdetail;
    });

    const logOut = () => {
      store.commit("clearToken");
      router.push("/login");
      // const token = store.state.token;
      // axios
      //   .post(process.env.VUE_APP_API_URL + "Auth/Logout", {
      //     headers: {
      //       Authorization: "bearer " + token,
      //       withCredentials: true,
      //     },
      //   })
      //   .then((res) => {
      //     store.commit("clearToken");
      //     router.push("/login");
      //   })
      //   .catch((err) => {
      //     store.commit("clearToken");
      //     router.push("/login");
      //   });
    };

    return { role, getuserdetail, logOut };
  },
  watch: {},
  data() {
    return {
      open: null,
      menus: {
        id: 1,
        // name: "My Profile",
        // subMenu: ["My Profile", "Reset Password", "Logout"],
      },
      imgBaseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  mounted() {
    document.addEventListener("click", (e) => {
      if (
        this.$refs.navheader &&
        this.$refs.navheader.contains(e.target) === false
      ) {
        this.open = null;
      }
    });
  },
  methods: {
    toggle(id) {
      this.open = this.open === id ? null : id;
    },
  },
};
</script>

<style scoped>
.navbar .navbar-toggler {
  top: 0.7rem;
}
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-toggler {
  border-color: rgb(0, 0, 0);
}
</style>

<template>
  <nav
    v-if="role != 'visitor'"
    id="sidebarMenu"
    class="col-md-3 col-lg-2 d-md-block sidebar collapse sidemenu-bg"
  >
    <div class="position-sticky pt-5 sidebar-sticky">
      <ul class="nav flex-column nav-menu">
        <li class="nav-item s-nav-item">
          <router-link
            to="/dashboard"
            class="nav-link"
            aria-current="page"
            @click="onLinkClick"
          >
            <span data-feather="home" class="align-text-bottom"></span>
            <!-- <i class="fa-solid fa-house menu-icon-house menu-icon"></i> -->
            <div class="left-nav-menu">
              <img
                :src="require('@/assets/images/Group-649.svg')"
                alt="File Icon"
                class="left-nav-menu-icon"
              /><span class="left-nav-menu-title">Dashboard</span>
            </div>
          </router-link>
        </li>
        <li class="nav-item s-nav-item" v-if="role == 'employer'">
          <router-link
            to="/employee/list"
            class="nav-link"
            :class="$route.meta.group == 'Employee' ? 'active' : ''"
            @click="onLinkClick"
          >
            <span data-feather="file" class="align-text-bottom"></span>
            <!-- <i class="fa fa-address-book menu-icon"></i> -->
            <div class="left-nav-menu">
              <img
                :src="require('@/assets/images/Group-1828.svg')"
                alt="File Icon"
                class="left-nav-menu-icon"
              />
              <span class="left-nav-menu-title">Employee</span>
            </div>
          </router-link>
        </li>
        <li class="nav-item s-nav-item" v-if="role == 'employer'">
          <router-link
            to="/subcompany/list"
            class="nav-link"
            :class="$route.meta.group == 'subcompany' ? 'active' : ''"
            @click="onLinkClick"
          >
            <span data-feather="file" class="align-text-bottom"></span>
            <!-- <i class="fa-solid fa-building menu-icon"></i> -->
            <div class="left-nav-menu">
              <img
                :src="require('@/assets/images/Group-1829.svg')"
                alt="File Icon"
                class="left-nav-menu-icon"
              />
              <span class="left-nav-menu-title">Company</span>
            </div>
          </router-link>
        </li>
        <li class="nav-item s-nav-item">
          <router-link
            to="/updateprofilerequest/list"
            class="nav-link"
            :class="$route.meta.group == 'updateprofilerequest' ? 'active' : ''"
            @click="onLinkClick"
          >
            <span data-feather="file" class="align-text-bottom"></span>
            <!-- <i class="fa fa-user menu-icon"></i> -->
            <div class="left-nav-menu">
              <img
                :src="require('@/assets/images/Group-1436.svg')"
                alt="File Icon"
                class="left-nav-menu-icon"
              />
              <span class="left-nav-menu-title">Update Profile Request</span>
            </div>
          </router-link>
        </li>
        <li
          class="nav-item s-nav-item sub-menu-section"
          v-if="role == 'employer'"
        >
          <div ref="subMenu2" style="height: 36px">
            <button
              v-on:click="subMenuToggle('subMenu2', 'normal')"
              class="nav-link"
              :class="$route.meta.group == 'withdrawal' ? 'active' : ''"
            >
              <!-- <i class="fa-regular fa-calendar-days menu-icon"></i> -->
              <div class="left-nav-menu">
                <img
                  :src="require('@/assets/images/withdrawal.svg')"
                  alt="File Icon"
                  class="left-nav-menu-icon"
                />
                <span class="left-nav-menu-title">Withdrawal</span>
              </div>
            </button>
            <div
              class="sub-menu-content"
              ref="hsubMenu2"
              id="hsubMenu2"
              :class="$route.meta.group == 'withdrawal' ? 'active-sub' : ''"
            >
              <router-link
                to="/withdrawal/list"
                class="nav-link"
                @click="onLinkClick"
                >Withdrawal History</router-link
              >
            </div>
          </div>
        </li>
        <li class="nav-item s-nav-item sub-menu-section" v-if="role == 'admin'">
          <div ref="subMenu1" style="height: 36px">
            <button
              v-on:click="subMenuToggle('subMenu1', 'normal')"
              class="nav-link"
              :class="$route.meta.group == 'admin-withdrawal' ? 'active' : ''"
            >
              <!-- <i class="fa-regular fa-calendar-days menu-icon"></i> -->
              <div class="left-nav-menu">
                <img
                  :src="require('@/assets/images/withdrawal.svg')"
                  alt="File Icon"
                  class="left-nav-menu-icon"
                />
                <span class="left-nav-menu-title">Withdrawal</span>
              </div>
            </button>
            <div
              class="sub-menu-content"
              ref="hsubMenu1"
              id="hsubMenu1"
              :class="
                $route.meta.group == 'admin-withdrawal' ? 'active-sub' : ''
              "
              @click="onLinkClick"
            >
              <router-link
                to="/admin/withdrawal/list"
                class="nav-link"
                @click="onLinkClick"
                >Withdrawal History</router-link
              >
              <router-link
                to="/admin/withdrawal/auto/generate/list"
                class="nav-link"
                @click="onLinkClick"
                >Withdrawal Auto Generate</router-link
              >

              <router-link
                to="/admin/withdrawal/bulkimport/list"
                class="nav-link"
                @click="onLinkClick"
                >Bulk Update History</router-link
              >
              <router-link
                to="/admin/withdrawal/curlec/balance"
                class="nav-link"
                @click="onLinkClick"
                >Curlec Balance</router-link
              >
            </div>
          </div>
        </li>
        <li class="nav-item s-nav-item" v-if="role == 'admin'">
          <router-link
            to="/admin/company/list"
            class="nav-link"
            :class="$route.meta.group == 'admin-company' ? 'active' : ''"
            @click="onLinkClick"
          >
            <span data-feather="file" class="align-text-bottom"></span>
            <!-- <i class="fa-solid fa-building menu-icon"></i> -->
            <div class="left-nav-menu">
              <img
                :src="require('@/assets/images/Group-1829.svg')"
                alt="File Icon"
                class="left-nav-menu-icon"
              />
              <span class="left-nav-menu-title">Company</span>
            </div>
          </router-link>
        </li>
        <li class="nav-item s-nav-item sub-menu-section" v-if="role == 'admin'">
          <div ref="subMenu3" style="height: 36px">
            <button
              v-on:click="subMenuToggle('subMenu3', 'normal')"
              class="nav-link"
              :class="
                $route.meta.group == 'admin-ezpaenrolment' ? 'active' : ''
              "
            >
              <div class="left-nav-menu">
                <img
                  :src="require('@/assets/images/insurance.svg')"
                  alt="File Icon"
                  class="left-nav-menu-icon"
                />
                <span class="left-nav-menu-title">Insurance</span>
              </div>
            </button>
            <div
              class="sub-menu-content"
              ref="hsubMenu3"
              id="hsubMenu3"
              :class="
                $route.meta.group == 'admin-ezpaenrolment' ? 'active-sub' : ''
              "
              @click="onLinkClick"
            >
              <router-link
                to="/insurance-enrolment/list"
                class="nav-link"
                @click="onLinkClick"
                >Insurance Enrolment</router-link
              >
              <router-link
                to="/insurance-enrolment/bulkimport/list"
                class="nav-link"
                @click="onLinkClick"
                >Bulk Update History</router-link
              >
            </div>
          </div>
        </li>

        <li class="nav-item s-nav-item" v-if="role == 'admin'">
          <router-link
            to="/enquiry/list"
            class="nav-link"
            :class="$route.meta.group == 'admin-enquiry' ? 'active' : ''"
            @click="onLinkClick"
          >
            <span data-feather="file" class="align-text-bottom"></span>
            <!-- <i class="fa-solid fa-building menu-icon"></i> -->
            <div class="left-nav-menu">
              <img
                :src="require('@/assets/images/inquiry.svg')"
                alt="File Icon"
                class="left-nav-menu-icon"
              />
              <span class="left-nav-menu-title">Enquiry</span>
            </div>
          </router-link>
        </li>
        <li class="nav-item s-nav-item" v-if="role == 'admin'">
          <router-link
            to="/banks"
            class="nav-link"
            :class="$route.name == 'banks' ? 'active' : ''"
            @click="onLinkClick"
          >
            <span data-feather="file" class="align-text-bottom"></span>
            <div class="left-nav-menu">
              <img
                :src="require('@/assets/images/bank.svg')"
                alt="File Icon"
                class="left-nav-menu-icon"
              />
              <span class="left-nav-menu-title">Banks</span>
            </div>
          </router-link>
        </li>
        <li class="nav-item s-nav-item" v-if="role == 'admin'">
          <router-link
            to="/articles"
            class="nav-link"
            :class="$route.name == 'articles' ? 'active' : ''"
            @click="onLinkClick"
          >
            <span data-feather="file" class="align-text-bottom"></span>
            <div class="left-nav-menu">
              <img
                :src="require('@/assets/images/article-svgrepo-com.svg')"
                alt="File Icon"
                class="left-nav-menu-icon"
              />
              <span class="left-nav-menu-title">Articles</span>
            </div>
          </router-link>
        </li>

        <li class="nav-item s-nav-item" v-if="role == 'admin'">
          <router-link
            to="/admin/push-notification"
            class="nav-link"
            :class="$route.meta.group == 'push-notification' ? 'active' : ''"
          >
            <span data-feather="file" class="align-text-bottom"></span>
            <i style="font-size: 20px" class="fa-solid fa-bell menu-icon"></i>
            Push Notification
          </router-link>
        </li>

        <!-- <li class="nav-item s-nav-item">
          <a class="nav-link" style="cursor: pointer" @click="logOut">
            <span data-feather="layers" class="align-text-bottom"></span>
            <i class="fa-solid fa-right-from-bracket menu-icon"></i>
            Logout
          </a>
        </li> -->
        <li class="nav-item s-nav-item last-nav-block"></li>
        <li class="nav-item s-nav-item last-nav-block"></li>
      </ul>
    </div>
  </nav>
  <!--
  <nav class="navbar navbar-expand-md navbar-dark bg-dark mb-4">
    <div class="container-fluid">
      <router-link to="/" class="navbar-brand">Mulaa</router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav me-auto mb-2 mb-md-0">
          <li class="nav-item">
            <router-link to="/login" class="nav-link" v-if="role == 'visitor'"
              >Login</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              to="/register"
              class="nav-link"
              v-if="role == 'visitor'"
              >Register</router-link
            >
          </li>
          <li class="nav-item" v-if="role == 'user'">
            <router-link to="/dashboard" class="nav-link" v-if="role == 'user'"
              >Dashboard</router-link
            >
          </li>
          <li class="nav-item" v-if="role == 'user'">
            <router-link
              to="/employee-list"
              class="nav-link"
              v-if="role == 'user'"
              >Employee</router-link
            >
          </li>
          <li class="nav-item" v-if="role == 'admin'">
            <router-link to="/companies" class="nav-link"
              >Companies
            </router-link>
          </li>
          <li class="nav-item" v-if="role != 'visitor'">
            <a class="nav-link" @click="logOut">Logout </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  -->
</template>
<style>
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, 0.1);
  border: solid rgba(0, 0, 0, 0.15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1),
    inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -0.125em;
  fill: currentColor;
}

.last-nav-block {
  height: 50px;
}

.nav-menu .nav-item {
  background-color: #ec3894 !important;
  box-shadow: inset -1px 0 0 rgb(0 0 0 / 10%);
  padding-bottom: 10px;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.sub-menu-section button {
  background: none;
  border-style: none;
}

/* .sub-menu-section button:hover {
  text-decoration: underline;
} */

.sub-menu-section .sub-menu {
  overflow-y: hidden;
  transition: 0.5s all;
}

.sub-menu-section .sub-menu-content {
  /* padding: 0px 20px; */
  /* display:none; */
}

.sub-menu-section .sub-menu-content a {
  min-height: 36px;
  font-weight: 300;
  padding: 0;
  padding: 0px 20px 0px 30px;
  line-height: 36px;
}

.sub-menu-section .nav-link {
  width: 100%;
  text-align: left;
}

.s-nav-item .active,
.sub-menu-section .sub-menu-content .active {
  /* border-left: 2px solid #ec3894; */
  color: #ec3894 !important;
  background: #ffffff 0% 0% no-repeat padding-box;
}

.s-nav-item .active .left-nav-menu-icon,
.sub-menu-section .sub-menu-content .active .left-nav-menu-icon {
  filter: invert(39%) sepia(43%) saturate(5273%) hue-rotate(308deg)
    brightness(95%) contrast(95%);
}

.active-sub {
  display: block;
}
</style>
<script lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
export default {
  name: "NavBar",
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const token = ref(store.getters.token);

    const role = computed(() => {
      return store.state.role;
    });

    const getuserdetail = computed(() => {
      return store.state.userdetail;
    });

    const logOut = () => {
      store.commit("clearToken");
      router.push("/login");
    };

    watch(route, async (a) => {
      let roleRetrieved = false;
      if (store.state.token != null) {
        roleRetrieved = await store.dispatch("setRole");
        await store.dispatch("setUserdetail");
      }

      if (!roleRetrieved) {
        logOut();
      }
    });

    return { role, token, logOut, getuserdetail };
  },
  data() {
    return {
      menuopen: "",
      currenttab: "",
      currentLocation: location.href,
    };
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.currentroute(); // returns undefined
        });
      });
    }, 100);
    window.addEventListener("resize", this.windowResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.windowResize);
  },
  methods: {
    onLinkClick() {
      const sidebarMenu = document.querySelector("#sidebarMenu");

      if (!sidebarMenu) return;
      sidebarMenu.classList.remove("show");
    },

    currentroute() {
      if (window.location.pathname || this.$route.meta.group) {
        if (
          window.location.pathname.includes("/admin/withdrawal") ||
          this.$route.meta.group == "admin-withdrawal"
        ) {
          this.subMenuToggle("subMenu1", "normal");
        } else if (
          (window.location.pathname.includes("/withdrawal") &&
            !window.location.pathname.includes("/admin/withdrawal")) ||
          this.$route.meta.group == "Withdrawal"
        ) {
          this.subMenuToggle("subMenu2", "normal");
        } else if (
          window.location.pathname.includes("/insurance-enrolment") ||
          this.$route.meta.group == "admin-ezpaenrolment-name"
        ) {
          this.subMenuToggle("subMenu3", "normal");
        }
      }
    },

    windowResize() {
      this.subMenuToggle(this.currenttab, "resize");
    },
    subMenuToggle(data, type) {
      if (data) {
        if (type != "resize") {
          this.currenttab = data;
        }
        let subMenu = this.$refs[data];
        let hdate = "h" + data;
        let getHeightElement = this.$refs[hdate];
        let getHeight = 0;

        if (getHeightElement) {
          getHeight = getHeightElement.clientHeight;
        }
        if (!subMenu) return;
        if (type != "resize") {
          subMenu.style.height =
            subMenu.style.height == "36px" ? getHeight + 36 + "px" : "36px";
        } else {
          subMenu.style.height =
            subMenu.style.height == "36px" ? "36px" : getHeight + 36 + "px";
        }
      }
      return;
    },
  },
};
</script>

<template>
  <div class="container">
    <TitleBar></TitleBar>
    <div class="text-center" v-if="loading == true">
      <img :src="require('@/assets/images/loading.gif')" />
    </div>

    <div class="text-danger" v-if="errorMsg != ''">
      {{ errorMsg }}
    </div>
    <div v-if="loading == false">
      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">Full Name</label>
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ enquirys.fullName }}
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">Mobile Number</label>
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ enquirys.mobileNumber }}
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">Email</label>
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ enquirys.email }}
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">Company Name</label>
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ enquirys.companyName }}
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">Amount Requested</label>
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ enquirys.totalAmountRequested }}
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">Nett Amount Transfer</label>
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ enquirys.nettAmountToTransfer }}
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">Transaction Fees</label>
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ enquirys.transactionFees }}
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">Message</label>
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ enquirys.message }}
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">Date</label>
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{
              enquirys.createdAt
              ? moment(enquirys.createdAt).format("DD-MM-YYYY HH:mm:ss")
              : ""
            }}
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">Withdrawal Request At</label>
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{
              enquirys.requestedAt
              ? moment(enquirys.requestedAt).format("DD MMMM YYYY, h:mm A")
              : ""
            }}
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="withdrawalid" class="col-sm-3 col-form-label detail-page-label">Withdrawal ID</label>
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ enquirys.withdrawalRecordId }}
            <span v-if="enquirys.withdrawalRecordId"><router-link v-if="role == 'admin'" :to="{
              name: 'admin-withdrawal-details',
              params: {
                id: enquirys.withdrawalRecordId,
              },
            }"><img class="edit-btn-img" :src="require('@/assets/images/edit-btn.svg')" /></router-link>
              <router-link v-else :to="{
                name: 'withdrawal-details',
                params: {
                  id: enquirys.withdrawalRecordId,
                },
              }"><img class="edit-btn-img" :src="require('@/assets/images/edit-btn.svg')" /></router-link></span>
          </div>
        </div>
      </div>
      <!-- <hr class="my-4" /> -->
      <hr class="my-4" />

      <router-link class="btn btn-secondary" to="/enquiry/list">Back</router-link>
    </div>
  </div>
</template>
<script lang="ts">
import store from "@/store";
import axios from "axios";
import { defineComponent, ref, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import moment from "moment";
import TitleBar from "@/components/TitleBar.vue";
export default defineComponent({
  name: "EnquiryDetails",
  setup() {
    const route = useRoute;
    const store = useStore();

    const role = computed(() => {
      return store.state.role;
    });

    return { role };
  },
  components: {
    TitleBar,
  },
  data() {
    return {
      id: ref(0),
      enquirys: ref([]),
      loading: true,
      moment: moment,
      errorMsg: ref(""),
    };
  },
  methods: {
    async EnquiryDetails() {
      const id = this.$route.params.id;

      const token = store.state.token;
      const response = await axios
        .get(process.env.VUE_APP_API_URL + "Enquiry/" + id + "/details", {
          headers: {
            Authorization: "bearer " + token,
          },
        })
        .then((res) => {
          this.enquirys = res.data;
        })
        .catch((err) => {
          // 
        });
      this.loading = false;
    },
  },
  mounted() {
    this.EnquiryDetails();
  },
});
</script>
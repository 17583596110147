<template>
  <div class="container mt-5">
    <TitleBar :pageTitleCustom="id && id != '' ? 'Update Company' : 'Add Company'"></TitleBar>
    <div class="col-12 title-section">
      <span class="title-border-b" />
      <div class="text-danger" v-if="errorMsg != ''">
        {{ errorMsg }}
      </div>
    </div>
    <form @submit.prevent="submit">
      <div class="row g-3 pddbtm50">
        <div class="col-sm-5">
          <label for="CompanyName" class="form-label">Company Name</label>
          <input v-model="this.formData.CompanyName" type="text" class="form-control" id="CompanyName" placeholder="" />
          <span class="text-danger" v-for="error in v$.CompanyName.$errors" :key="error.$uid">{{ error.$message }}</span>
        </div>

        <div class="col-sm-5">
          <label for="ContactNumber" class="form-label">Contact Number</label>
          <input v-model="this.formData.ContactNumber" type="text" class="form-control" id="ContactNumber"
            placeholder="" />
          <span class="text-danger" v-for="error in v$.ContactNumber.$errors" :key="error.$uid">{{ error.$message
          }}</span>
        </div>
        <div class="col-sm-5">
          <label for="Email" class="form-label">Email</label>
          <input v-model="this.formData.Email" type="text" class="form-control" id="Email" placeholder="" />
          <span class="text-danger" v-for="error in v$.Email.$errors" :key="error.$uid">{{ error.$message }}</span>
        </div>
        <!-- <div class="col-sm-12">
          <label for="ezpaVsureEnable" class="form-label">EZPA Enrolment</label>
          <select
            v-model="formData.ezpaVsureEnable"
            type="text"
            class="form-control"
            id="ezpaVsureEnable"
            placeholder=""
          >
            <option value="1">Enable</option>
            <option value="0">Disable</option>
          </select>
          <span
            class="text-danger"
            v-for="error in v$.ezpaVsureEnable.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div> -->
        <div class="col-sm-5">
          <label for="status" class="form-label">Status</label>
          <select v-model="formData.status" type="text" class="form-control" id="status" placeholder="">
            <option value="1">Active</option>
            <option value="0">Inactive</option>
          </select>
          <span class="text-danger" v-for="error in v$.status.$errors" :key="error.$uid">{{ error.$message }}</span>
        </div>
      </div>
      <!-- <hr class="my-4" /> -->
      <button class="btn btn-secondary mrgrbtn" type="button" @click="goBack">
        Cancel
      </button>
      <button class="btn btn-primary" type="submit">Submit</button>

    </form>
  </div>
</template>

<script lang="ts">
import store from "@/store";
import axios from "axios";
import { defineComponent, ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  email,
  sameAs,
  helpers,
  decimal,
  numeric,
  minLength,
  maxLength,
} from "@vuelidate/validators";
import { reactive } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import TitleBar from "@/components/TitleBar.vue";
export default defineComponent({
  name: "SubCompanyEditor",
  setup() {
    const toast = useToast();
    const router = useRouter();
    const token = store.state.token;
    const errorMsg = ref("");

    const formData = reactive({
      pid: 0,
      Id: 0,
      CompanyName: "",
      ContactNumber: "",
      Email: "",
      CompanyLogo: "",
      ezpaVsureEnable: "",
      status: "",
    });

    const submit = async () => {
      const result = await v$.value.$validate();

      if (result) {
        formData.ezpaVsureEnable = "0";
        if (formData.pid && formData.pid != 0) {
          await axios
            .put(process.env.VUE_APP_API_URL + "SubCompany/update", formData, {
              headers: {
                Authorization: "bearer " + token,
              },
            })
            .then((res) => {
              errorMsg.value = "";

              toast.success("Company successfully updated", {
                timeout: 3000,
              });

              router.push({
                name: "subcompany-details",
                params: { id: formData.Id },
              });
            })
            .catch((err) => {
              errorMsg.value = err.response.data;
            });
        } else {
          await axios
            .post(process.env.VUE_APP_API_URL + "SubCompany/insert", formData, {
              headers: {
                Authorization: "bearer " + token,
              },
            })
            .then((res) => {
              errorMsg.value = "";

              toast.success("Company successfully added", {
                timeout: 3000,
              });

              router.push("/subcompany/list");
            })
            .catch((err) => {
              errorMsg.value = err.response.data;
            });
        }
      }
    };

    const rules = computed(() => {
      return {
        CompanyName: {
          required: helpers.withMessage("Please enter company name", required),
        },
        ContactNumber: {
          required: helpers.withMessage(
            "Please enter contact number",
            required
          ),
          numeric,
        },
        Email: {
          email,
          required: helpers.withMessage("Please enter email", required),
        },
        // ezpaVsureEnable: {
        //   required: helpers.withMessage("Please select EZPA enrolment", required),
        // },
        status: {
          required: helpers.withMessage("Please select status", required),
        },
      };
    });
    const v$ = useVuelidate(rules, formData);

    return { v$, formData, token, router, toast, errorMsg, submit };
  },
  data() {
    return {
      id: "",
      loading: true,
      showPassword: false,
      password: null,
    };
  },
  components: {
    TitleBar,
  },
  methods: {
    async subcompanyDetails() {
      const token = store.state.token;
      const response = await axios
        .get(process.env.VUE_APP_API_URL + "SubCompany/" + this.id + "/details", {
          headers: {
            Authorization: "bearer " + token,
          },
        })
        .then((res) => {
          this.formData.Id = res.data.id;
          this.formData.Email = res.data.email;
          this.formData.CompanyName = res.data.companyName;
          this.formData.ContactNumber = res.data.contactNumber;

          this.formData.ezpaVsureEnable = res.data.ezpaVsureEnable;
          this.formData.status = res.data.status;

          this.formData.pid = this.id;
        })
        .catch((err) => {
          // 
        });
      this.loading = false;
    },
    goBack() {
      this.$router.replace({
        name: "subcompany-list",
      });
    },
  },
  validations() {
    return {};
  },
  mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.subcompanyDetails();
    }
  },
});
</script>
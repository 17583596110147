<template>
  <div class="container">
    <TitleBar></TitleBar>
    <div class="text-center" v-if="loading == true">
      <img :src="require('@/assets/images/loading.gif')" />
    </div>
    <Pagination :totalItem="lists.length" :items="lists" :perPage="50">
      <template #data="{ paginatedItems, currentIndex }">
        <table class="table table-list" v-if="loading == false">
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col" class="pointer" @click="onSort('fileName')">
                <span class="sort-arrow" :class="currentSort == 'fileName'
                    ? currentSortDir == 'desc'
                      ? 'sort-arrow-desc'
                      : 'sort-arrow-asc'
                    : ''
                  ">File Name</span>
              </th>
              <th scope="col" class="pointer" @click="onSort('createdAt')">
                <span class="sort-arrow" :class="currentSort == 'createdAt'
                    ? currentSortDir == 'desc'
                      ? 'sort-arrow-desc'
                      : 'sort-arrow-asc'
                    : ''
                  ">Created At</span>
              </th>
              <th scope="col" class="pointer" @click="onSort('createdBy')">
                <span class="sort-arrow" :class="currentSort == 'createdBy'
                    ? currentSortDir == 'desc'
                      ? 'sort-arrow-desc'
                      : 'sort-arrow-asc'
                    : ''
                  ">Created By</span>
              </th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(list, index) in paginatedItems" :key="index + currentIndex">
              <td scope="row">{{ index + currentIndex }}</td>
              <td>
                <span>{{ list.fileName }}</span>
              </td>
              <td>
                {{ moment(list.createdAt).format("DD-MM-YYYY") }}
              </td>
              <td>{{ list.createdBy }}</td>
              <td>
                <router-link :to="{
                  name: 'employee-bulkimport-failed-detail',
                  params: {
                    id: list.id,
                  },
                }"><img class="edit-btn-img" :src="require('@/assets/images/edit-btn.svg')" /></router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </Pagination>
    <router-link class="btn btn-secondary" to="/employee/list">Back</router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
// import { useVuelidate } from "@vuelidate/core";
// import { required, email, helpers, decimal } from "@vuelidate/validators";
// import { reactive } from "@vue/reactivity";
// import { computed } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import store from "@/store";
import moment from "moment";

import axios from "axios";
import Pagination from "@/components/Pagination.vue";
import TitleBar from "@/components/TitleBar.vue";
export default defineComponent({
  name: "EmployeeBulkImportFailedList",
  components: {
    Pagination,
    TitleBar,
  },
  mounted() {
    this.id = this.$route.params.id;
    this.bulkfailedlist();
  },
  data() {
    return {
      id: "",
      count: 1,
      lists: "",
      loading: true,
      moment: moment,
      currentSort: "name",
      currentSortDir: "asc",
    };
  },
  methods: {
    onSort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;

      this.lists = this.lists.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
    async bulkfailedlist() {
      const token = store.state.token;
      const response = await axios.get(
        process.env.VUE_APP_API_URL + "employee/bulkimport/list",
        {
          headers: {
            Authorization: "bearer " + token,
          },
        }
      );
      this.loading = false;
      this.lists = response.data;
    },
    async DownloadExcel(filename) {
      const token = store.state.token;
      //@click="DownloadExcel(list.fileName)" class="file-download"
      const response = await axios
        .get(
          process.env.VUE_APP_API_URL + "employee/file/download/" + filename,
          {
            responseType: "blob",
            headers: {
              Authorization: "bearer " + token,
            },
          }
        )
        .then((res) => {
          var fileURL = window.URL.createObjectURL(new Blob([res.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((err) => {
          //
        });
    },
  },
});
</script>

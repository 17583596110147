<template>
  <button
    class="download-btn btn btn-primary btn-sm btn-list btn-list-3"
    @click="show = true"
    type="button"
    :disabled="downloading"
  >
    <template v-if="downloading">...</template>
    <template v-else>
      <i class="fa fa-download"></i> {{ text ?? "Download" }}
    </template>
  </button>

  <VueFinalModal
    v-model="show"
    class="download-modal"
    content-class="download-modal-content"
    content-transition="dialog-show"
  >
    <div class="d-flex justify-content-end w-100">
      <button type="button" class="close-btn" @click="show = false">x</button>
    </div>
    <div class="w-100 mb-4" style="flex: 1">
      <slot />
    </div>
    <button
      type="button"
      class="btn btn-secondary"
      @click="download"
      :disabled="downloading"
    >
      <template v-if="downloading">...</template>
      <template v-else>
        <i class="fa fa-download"></i> {{ text ?? "Download" }}
      </template>
    </button>
  </VueFinalModal>
</template>

<script lang="ts" setup>
import { usePasswordVerify } from "@/composables/usePasswordVerify";
import axios from "axios";
import { defineProps, defineEmits, ref } from "vue";
import { VueFinalModal } from "vue-final-modal";
import { useStore } from "vuex";
const props = defineProps<{
  text?: string;
  url?: string;
  fileName?: string;
  fileType?: string;
  verifyUser?: boolean;
}>();

const emit = defineEmits<{
  (e: "confirm"): void;
}>();

const store = useStore();
const { verifyPassword } = usePasswordVerify();

const show = ref(false);
const downloading = ref(false);

async function download() {
  if (downloading.value) return;
  downloading.value = true;
  const fileUri = await fetchFile();
  if (fileUri) _download(fileUri);
  downloading.value = false;
  show.value = false;
}

async function fetchFile() {
  let fileUri = null;
  if (!props.url) return fileUri;
  if (props.verifyUser === true && !(await verifyPassword())) return fileUri;
  try {
    const token = store.state.token;
    const response = await axios.get(props.url, {
      responseType: "blob",
      headers: {
        Authorization: "bearer " + token,
      },
    });
    fileUri = window.URL.createObjectURL(
      new Blob([response.data], {
        type:
          props.fileType ??
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
  } catch (error) {
    //
  }
  return fileUri;
}

function _download(fileUrl: string) {
  const anchor = document.createElement("a");
  anchor.href = fileUrl;
  anchor.setAttribute("download", props.fileName ?? "excel.xlsx");
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
}
</script>
<style scoped>
.download-btn {
  height: fit-content;
}

.close-btn {
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.2em;
  height: 1.2em;
  overflow: hidden;
  transition: color 0.1s, box-shadow 0.1s;
  border: none;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0);
  color: #ccc;
  font-family: serif;
  font-family: monospace;
  font-size: 2.5em;
  cursor: pointer;
  justify-self: end;
}
</style>
<style>
.download-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000 !important;
}
.download-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline: 2rem;
  padding-bottom: 1rem;
  background: #fff;
  border-radius: 0.5rem;
  width: 95%;
  max-width: 600px;
  min-height: 300px;
}

/* Refer Vue 'Transition' component for this */
.dialog-show-enter-from,
.dialog-show-enter-active,
.dialog-show-enter-to {
  animation: dialog-show-animation 0.3s;
}

@keyframes dialog-show-animation {
  0% {
    transform: scale(0.7);
  }
  45% {
    transform: scale(1.05);
  }
  80% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}
</style>

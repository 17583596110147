import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import Register from "@/views/Register.vue";
import Companies from "@/views/Companies.vue";
import Dashboard from "@/views/Dashboard.vue";
import MyProfile from "@/views/MyProfile.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import EmployeeList from "@/views/Employee/EmployeeList.vue";
import EmployeeAdd from "@/views/Employee/EmployeeAdd.vue";
import EmployeeBulkImport from "@/views/Employee/EmployeeBulkImport.vue";
import EmployeeBulkImportFailedDetails from "@/views/Employee/EmployeeBulkImportFailedDetails.vue";
import EmployeeBulkImportFailedList from "@/views/Employee/EmployeeBulkImportFailedList.vue";
import Employee from "@/views/Employee/Employee.vue";
import EmployeeUpdate from "@/views/Employee/EmployeeUpdate.vue";
import EmployeeDetails from "@/views/Employee/EmployeeDetails.vue";
import AdminEmployeeDetails from "@/views/Employee/AdminEmployeeDetails.vue";
import Withdrawal from "@/views/Withdrawal/Withdrawal.vue";
import WithdrawalList from "@/views/Withdrawal/WithdrawalList.vue";
import WithdrawalDetails from "@/views/Withdrawal/WithdrawalDetails.vue";
import AdminWithdrawalList from "@/views/Withdrawal/AdminWithdrawalList.vue";
import AdminWithdrawalAutoRequestList from "@/views/Withdrawal/AdminWithdrawalAutoRequestList.vue";
import AdminWithdrawalDetails from "@/views/Withdrawal/AdminWithdrawalDetails.vue";
import AdminWithdrawalBulkImport from "@/views/Withdrawal/AdminWithdrawalBulkImport.vue";
import AdminWithdrawalTransactionBatchList from "@/views/Withdrawal/AdminWithdrawalTransactionBatchList.vue";
import AdminWithdrawalTransactionBatchListDetails from "@/views/Withdrawal/AdminWithdrawalTransactionBatchListDetails.vue";
import Company from "@/views/Company/Company.vue";
import AdminCompanyList from "@/views/Company/AdminCompanyList.vue";
import AdminCompanyDetails from "@/views/Company/AdminCompanyDetails.vue";
import AdminCompanyEditor from "@/views/Company/AdminCompanyEditor.vue";

import EzpaEnrolment from "@/views/EzpaEnrolment/EzpaEnrolment.vue";
import EzpaEnrolmentList from "@/views/EzpaEnrolment/EzpaEnrolmentList.vue";
import EzpaEnrolmentDetails from "@/views/EzpaEnrolment/EzpaEnrolmentDetails.vue";
import AdminInsuranceBulkImport from "@/views/EzpaEnrolment/AdminInsuranceBulkImport.vue";
import AdminInsuranceUploads from "@/views/EzpaEnrolment/AdminInsuranceUploads.vue";
import AdminInsuranceUploadDetail from "@/views/EzpaEnrolment/AdminInsuranceUploadDetail.vue";

import WithdrawalInvoice from "@/views/Withdrawal/WithdrawalInvoice.vue";
import WithdrawalInvoiceDetails from "@/views/Withdrawal/WithdrawalInvoiceDetails.vue";

import Configuration from "@/views/Configuration/Configuration.vue";
import AdminConfigurationList from "@/views/Configuration/AdminConfigurationList.vue";
import AdminConfigurationDetails from "@/views/Configuration/AdminConfigurationDetails.vue";
import AdminConfigurationEditor from "@/views/Configuration/AdminConfigurationEditor.vue";

import UpdateProfileRequest from "@/views/UpdateProfileRequest/UpdateProfileRequest.vue";
import UpdateProfileRequestList from "@/views/UpdateProfileRequest/UpdateProfileRequestList.vue";
import UpdateProfileRequestDetails from "@/views/UpdateProfileRequest/UpdateProfileRequestDetails.vue";

import Enquiry from "@/views/Enquiry/Enquiry.vue";
import EnquiryList from "@/views/Enquiry/EnquiryList.vue";
import EnquiryDetails from "@/views/Enquiry/EnquiryDetails.vue";

import SubCompany from "@/views/SubCompany/SubCompany.vue";
import SubCompanyList from "@/views/SubCompany/SubCompanyList.vue";
import SubCompanyDetails from "@/views/SubCompany/SubCompanyDetails.vue";
import SubCompanyEditor from "@/views/SubCompany/SubCompanyEditor.vue";
import Banks from "@/views/Banks.vue";
import Articles from "@/views/Articles/Articles.vue";
import ArticlesDetails from "@/views/Articles/ArticlesDetails.vue";
import ArticlesList from "@/views/Articles/ArticlesList.vue";
import PushNotification from "@/views/PushNotification/PushNotification.vue";
import PushNotificationManagement from "@/views/PushNotification/PushNotificationManagement.vue";

import { useStore } from "vuex";
import store from "../store";
import AdminCurlecBalance from "@/views/Withdrawal/AdminCurlecBalance.vue";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: Login,
    meta: {
      guest: true,
    },
  },
  {
    path: "/login",
    component: Login,
    meta: {
      guest: true,
    },
  },
  // {
  //   path: '/register', component: Register,
  //   meta: {
  //     guest: true,
  //   },
  // },
  {
    path: "/companies",
    component: Companies,
    meta: {
      guest: true,
    },
  },
  {
    path: "/home",
    component: Home,
    meta: {
      guest: true,
    },
  },
  {
    path: "/dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
      pageTitle: "Dashboard",
      pageCategory: "Dashboard",
      pageSubCategory: "",
      pageName: "",
    },
  },
  {
    path: "/my-profile",
    component: MyProfile,
    meta: {
      requiresAuth: true,
      pageTitle: "Update Profile",
      pageCategory: "Dashboard",
      pageSubCategory: "",
      pageName: "Update Profile",
    },
  },
  {
    path: "/reset-password",
    component: ResetPassword,
    meta: {
      requiresAuth: true,
      pageTitle: "Reset Password",
      pageCategory: "Dashboard",
      pageSubCategory: "",
      pageName: "Reset Password",
    },
  },
  {
    path: "/banks",
    name: "banks",
    component: Banks,
    meta: {
      requiresAuth: true,
      pageTitle: "Registered Banks",
      pageCategory: "Banks",
      pageSubCategory: "",
      pageName: "Registered Banks",
    },
  },
  {
    path: "/articles",
    component: Articles,
    children: [
      {
        path: "",
        name: "articles-list",
        component: ArticlesList,
        meta: {
          requiresAuth: true,
          pageTitle: "Articles",
          pageCategory: "Articles",
          pageSubCategory: "",
          pageName: "Articles",
        },
      },
      {
        path: ":id/details",
        name: "article-details",
        component: ArticlesDetails,
        props: true,
        meta: {
          requiresAuth: true,
          pageTitle: "Article Details",
          pageCategory: "Articles",
          pageSubCategory: "",
          pageName: "Article Details",
        },
      },
      {
        path: "create-article",
        name: "create-article",
        component: ArticlesDetails,
        props: true,
        meta: {
          requiresAuth: true,
          pageTitle: "Create Article",
          pageCategory: "Articles",
          pageSubCategory: "",
          pageName: "Create Article",
        },
      },
    ],
    meta: {
      requiresAuth: true,
      pageTitle: "Articles",
      pageCategory: "Articles",
      pageSubCategory: "",
      pageName: "Articles",
    },
  },
  {
    path: "/admin/employee",
    component: Employee,
    children: [
      {
        path: ":id/details",
        name: "admin-employee-details",
        component: AdminEmployeeDetails,
        meta: {
          group: "Employee",
          requiresAuth: true,
          pageTitle: "Employee Details",
          pageCategory: "Employee List",
          pageSubCategory: "",
          pageName: "Employee Details",
        },
      },
    ],
  },
  {
    path: "/employee",
    component: Employee,

    children: [
      {
        path: "list",
        component: EmployeeList,
        meta: {
          group: "Employee",
          requiresAuth: true,
          pageTitle: "Employee List",
          pageCategory: "Employee List",
          pageSubCategory: "",
          pageName: "",
        },
      },
      {
        path: "add",
        component: EmployeeAdd,
        meta: {
          group: "Employee",
          requiresAuth: true,
          pageTitle: "Add New Employee",
          pageCategory: "Employee List",
          pageSubCategory: "",
          pageName: "Add New Employee",
        },
      },
      {
        path: ":id/details",
        name: "employee-details",
        component: EmployeeDetails,
        meta: {
          group: "Employee",
          requiresAuth: true,
          pageTitle: "Employee Details",
          pageCategory: "Employee List",
          pageSubCategory: "",
          pageName: "Employee Details",
        },
      },
      {
        path: ":id/update",
        name: "employee-update",
        component: EmployeeUpdate,
        meta: {
          group: "Employee",
          requiresAuth: true,
          pageTitle: "Update Employee",
          pageCategory: "Employee List",
          pageSubCategory: "Employee Details",
          pageName: "Update Employee",
        },
      },
      {
        path: "bulkimport",
        component: EmployeeBulkImport,
        meta: {
          group: "Employee",
          requiresAuth: true,
          pageTitle: "Employee Bulk Import",
          pageCategory: "Employee List",
          pageSubCategory: "",
          pageName: "Employee Bulk Import",
        },
      },
      {
        path: "bulkimport/:id/failed/details",
        component: EmployeeBulkImportFailedDetails,
        name: "employee-bulkimport-failed-detail",
        meta: {
          group: "Employee",
          requiresAuth: true,
          pageTitle: "Employee Bulk Import History",
          pageCategory: "Employee List",
          pageSubCategory: "Employee Bulk Import History",
          pageName: "Employee Bulk Import History",
        },
      },
      {
        path: "bulkimport/failed/list",
        component: EmployeeBulkImportFailedList,
        name: "employee-bulkimport-failed-list",
        meta: {
          group: "Employee",
          requiresAuth: true,
          pageTitle: "Employee Bulk Import History",
          pageCategory: "Employee List",
          pageSubCategory: "",
          pageName: "Employee Bulk Import History",
        },
      },
    ],
  },
  {
    path: "/withdrawal",
    component: Withdrawal,

    children: [
      {
        path: "list",
        component: WithdrawalList,
        name: "withdrawal-list",
        meta: {
          group: "withdrawal",
          requiresAuth: true,
          pageTitle: "Withdrawal History",
          pageCategory: "Withdrawal",
          pageSubCategory: "",
          pageName: "Withdrawal History",
        },
      },
      {
        path: ":id/details",
        name: "withdrawal-details",
        component: WithdrawalDetails,
        meta: {
          group: "withdrawal",
          requiresAuth: true,
          pageTitle: "Withdrawal Details",
          pageCategory: "Withdrawal",
          pageSubCategory: "Withdrawal History",
          pageName: "Withdrawal Details",
        },
      },
    ],
  },
  {
    path: "/admin/withdrawal",
    component: Withdrawal,

    children: [
      {
        path: "list",
        component: AdminWithdrawalList,
        meta: {
          group: "admin-withdrawal",
          requiresAuth: true,
          pageTitle: "Withdrawal History",
          pageCategory: "Withdrawal",
          pageSubCategory: "",
          pageName: "Withdrawal History",
        },
      },
      {
        path: ":id/details",
        name: "admin-withdrawal-details",
        component: AdminWithdrawalDetails,
        meta: {
          group: "admin-withdrawal",
          requiresAuth: true,
          pageTitle: "Withdrawal Details",
          pageCategory: "Withdrawal",
          pageSubCategory: "Withdrawal History",
          pageName: "Withdrawal Details",
        },
      },
      {
        path: "bulkimport",
        component: AdminWithdrawalBulkImport,
        meta: {
          group: "admin-withdrawal",
          requiresAuth: true,
          pageTitle: "Bulk Update",
          pageCategory: "Withdrawal",
          pageSubCategory: "",
          pageName: "Bulk Update",
        },
      },
      {
        path: "bulkimport/list",
        component: AdminWithdrawalTransactionBatchList,
        name: "admin-withdrawal-transaction-batch-list",
        meta: {
          group: "admin-withdrawal",
          requiresAuth: true,
          pageTitle: "Bulk Update History",
          pageCategory: "Withdrawal",
          pageSubCategory: "",
          pageName: "Bulk Update History",
        },
      },
      {
        path: "bulkimport/list/:id/details",
        component: AdminWithdrawalTransactionBatchListDetails,
        name: "admin-withdrawal-transaction-batch-list-Details",
        meta: {
          group: "admin-withdrawal",
          requiresAuth: true,
          pageTitle: "Transaction Details",
          pageCategory: "Withdrawal",
          pageSubCategory: "Bulk Update History",
          pageName: "Transaction Details",
        },
      },
      {
        path: "auto/generate/list",
        component: AdminWithdrawalAutoRequestList,
        meta: {
          group: "admin-withdrawal",
          requiresAuth: true,
          pageTitle: "Withdrawal Auto Generate",
          pageCategory: "Withdrawal",
          pageSubCategory: "",
          pageName: "Withdrawal Auto Generate",
        },
      },
      {
        path: "curlec/balance",
        component: AdminCurlecBalance,
        meta: {
          group: "admin-withdrawal",
          requiresAuth: true,
          pageTitle: "Curlec Balance",
          pageCategory: "Withdrawal",
          pageSubCategory: "",
          pageName: "Curlec Balance",
        },
      },
    ],
  },
  {
    path: "/admin/withdrawal/invoice",
    component: WithdrawalInvoice,

    children: [
      {
        path: ":id/details",
        name: "admin-invoice-details",
        component: WithdrawalInvoiceDetails,
        meta: {
          group: "admin-withdrawal",
          requiresAuth: true,
          pageTitle: "Billing Details",
          pageCategory: "Withdrawal",
          pageSubCategory: "Billing",
          pageName: "Billing Details",
        },
      },
    ],
  },
  {
    path: "/withdrawal/invoice",
    component: WithdrawalInvoice,

    children: [
      {
        path: ":id/details",
        name: "invoice-details",
        component: WithdrawalInvoiceDetails,
        meta: {
          group: "withdrawal",
          requiresAuth: true,
          pageTitle: "Billing Details",
          pageCategory: "Withdrawal",
          pageSubCategory: "Billing",
          pageName: "Billing Details",
        },
      },
    ],
  },
  {
    path: "/admin/company",
    component: Company,

    children: [
      {
        path: "list",
        name: "admin-company-name",
        component: AdminCompanyList,
        meta: {
          group: "admin-company",
          requiresAuth: true,
          pageTitle: "Company List",
          pageCategory: "Company List",
          pageSubCategory: "",
          pageName: "",
        },
      },
      {
        path: ":id/details",
        name: "admin-company-details",
        component: AdminCompanyDetails,
        meta: {
          group: "admin-company",
          requiresAuth: true,
          pageTitle: "Company Details",
          pageCategory: "Company List",
          pageSubCategory: "",
          pageName: "Company Details",
        },
      },
      {
        path: "add",
        name: "admin-company-add",
        component: AdminCompanyEditor,
        meta: {
          group: "admin-company",
          requiresAuth: true,
          pageTitle: "Add Company",
          pageCategory: "Company List",
          pageSubCategory: "",
          pageName: "Add Company",
        },
      },
      {
        path: ":id/update",
        name: "admin-company-update",
        component: AdminCompanyEditor,
        meta: {
          group: "admin-company",
          requiresAuth: true,
          pageTitle: "Update Company",
          pageCategory: "Company List",
          pageSubCategory: "Company Details",
          pageName: "Update Company",
        },
      },
    ],
  },

  {
    path: "/insurance-enrolment/",
    component: EzpaEnrolment,
    children: [
      {
        path: "list",
        name: "admin-ezpaenrolment-name",
        component: EzpaEnrolmentList,
        meta: {
          group: "admin-ezpaenrolment",
          requiresAuth: true,
          pageTitle: "Insurance Enrollment List",
          pageCategory: "Insurance Enrollment List",
          pageSubCategory: "",
          pageName: "",
        },
      },
      {
        path: ":id/details",
        name: "admin-ezpaenrolment-details",
        component: EzpaEnrolmentDetails,
        meta: {
          group: "admin-ezpaenrolment",
          requiresAuth: true,
          pageTitle: "Insurance Enrolment Details",
          pageCategory: "Insurance Enrollment List",
          pageSubCategory: "",
          pageName: "Insurance Enrolment Details",
        },
      },
      {
        path: "bulkimport",
        name: "admin-insurance-bulkimport",
        component: AdminInsuranceBulkImport,
        meta: {
          group: "admin-ezpaenrolment",
          requiresAuth: true,
          pageTitle: "Insurance Enrolment Details",
          pageCategory: "Insurance Enrollment List",
          pageSubCategory: "",
          pageName: "Insurance Enrolment Details",
        },
      },
      {
        path: "bulkimport/list",
        name: "admin-insurance-bulkimport-list",
        component: AdminInsuranceUploads,
        meta: {
          group: "admin-ezpaenrolment",
          requiresAuth: true,
          pageTitle: "Insurance Enrolment Details",
          pageCategory: "Insurance Enrollment List",
          pageSubCategory: "",
          pageName: "Insurance Enrolment Details",
        },
      },
      {
        path: "bulkimport/list/:id/details",
        name: "admin-insurance-bulkimport-details",
        component: AdminInsuranceUploadDetail,
        meta: {
          group: "admin-ezpaenrolment",
          requiresAuth: true,
          pageTitle: "Insurance Enrolment Details",
          pageCategory: "Insurance Enrollment List",
          pageSubCategory: "",
          pageName: "Insurance Enrolment Details",
        },
      },
    ],
  },
  {
    path: "/admin/configuration",
    component: Configuration,

    children: [
      {
        path: "list",
        name: "admin-configuration-list",
        component: AdminConfigurationList,
        meta: {
          group: "admin-configuration",
          requiresAuth: true,
          pageTitle: "Configuration",
          pageCategory: "Configuration",
          pageSubCategory: "",
          pageName: "",
        },
      },
      {
        path: ":id/details",
        name: "admin-configuration-details",
        component: AdminConfigurationDetails,
        meta: {
          group: "admin-configuration",
          requiresAuth: true,
          pageTitle: "Configuration Details",
          pageCategory: "Configuration",
          pageSubCategory: "",
          pageName: "Configuration Details",
        },
      },
      {
        path: "add",
        name: "admin-configuration-add",
        component: AdminConfigurationEditor,
        meta: {
          group: "admin-configuration",
          requiresAuth: true,
          pageTitle: "Add Configuration",
          pageCategory: "Company",
          pageSubCategory: "Company List",
          pageSubCategory1: "Company Details",
          pageName: "Add Configuration",
        },
      },
      {
        path: ":id/update",
        name: "admin-configuration-update",
        component: AdminConfigurationEditor,
        meta: {
          group: "admin-configuration",
          requiresAuth: true,
          pageTitle: "Update Configuration",
          pageCategory: "Company List",
          pageSubCategory: "Company Details",
          // pageSubCategory1: "Company Details",
          pageName: "Update Configuration",
        },
      },
    ],
  },
  {
    path: "/updateprofilerequest",
    component: UpdateProfileRequest,

    children: [
      {
        path: "list",
        name: "updateprofilerequest-list",
        component: UpdateProfileRequestList,
        meta: {
          group: "updateprofilerequest",
          requiresAuth: true,
          pageTitle: "Update Profile Request List",
          pageCategory: "Update Profile Request List",
          pageSubCategory: "",
          pageName: "",
        },
      },
      {
        path: ":id/details",
        name: "updateprofilerequest-details",
        component: UpdateProfileRequestDetails,
        meta: {
          group: "updateprofilerequest",
          requiresAuth: true,
          pageTitle: "Update Profile Request Details",
          pageCategory: "Update Profile Request List",
          pageSubCategory: "",
          pageName: "Update Profile Request Details",
        },
      },
    ],
  },
  {
    path: "/enquiry/",
    component: Enquiry,

    children: [
      {
        path: "list",
        name: "admin-enquiry-list",
        component: EnquiryList,
        meta: {
          group: "admin-enquiry",
          requiresAuth: true,
          pageTitle: "Enquiry List",
          pageCategory: "Enquiry List",
          pageSubCategory: "",
          pageName: "",
        },
      },
      {
        path: ":id/details",
        name: "admin-enquiry-details",
        component: EnquiryDetails,
        meta: {
          group: "admin-enquiry",
          requiresAuth: true,
          pageTitle: "Enquiry Details",
          pageCategory: "Enquiry List",
          pageSubCategory: "",
          pageName: "Enquiry Details",
        },
      },
    ],
  },
  {
    path: "/subcompany",
    component: SubCompany,

    children: [
      {
        path: "list",
        name: "subcompany-list",
        component: SubCompanyList,
        meta: {
          group: "subcompany",
          requiresAuth: true,
          pageTitle: "Company List",
          pageCategory: "Company List",
          pageSubCategory: "",
          pageName: "",
        },
      },
      {
        path: ":id/details",
        name: "subcompany-details",
        component: SubCompanyDetails,
        meta: {
          group: "subcompany",
          requiresAuth: true,
          pageTitle: "Company Details",
          pageCategory: "Company List",
          pageSubCategory: "",
          pageName: "Company Details",
        },
      },
      {
        path: "add",
        name: "subcompany-add",
        component: SubCompanyEditor,
        meta: {
          group: "subcompany",
          requiresAuth: true,
          pageTitle: "Add Company",
          pageCategory: "Company List",
          pageSubCategory: "",
          pageName: "Add Company",
        },
      },
      {
        path: ":id/update",
        name: "subcompany-update",
        component: SubCompanyEditor,
        meta: {
          group: "subcompany",
          requiresAuth: true,
          pageTitle: "Update Company",
          pageCategory: "Company List",
          pageSubCategory: "Company Details",
          pageName: "Update Company",
        },
      },
    ],
  },
  {
    path: "/admin/push-notification",
    component: PushNotification,
    children: [
      {
        path: "",
        component: PushNotificationManagement,
        meta: {
          group: "push-notification",
          requiresAuth: true,
          pageTitle: "Push Notification Management",
          pageCategory: "Push Notification",
          pageName: "Management",
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "active",
});

router.beforeEach(async (to, from, next) => {
  if (store.getters["token"] != null && store.getters.isAuthenticated) {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      proceedNext();
    } else if (to.matched.some((record) => record.meta.guest)) {
      // if is authenticated and trying to go to Login pages

      returnDashboard();
    }
  } else {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      // if is not authenticated and trying to go to CMS pages
      //proceedNext();
      returnLogin();
    } else if (to.matched.some((record) => record.meta.guest)) {
      // if is not authenticated and trying to go to Login pages

      proceedNext();
    }
  }

  function proceedNext() {
    next();
    return false;
  }

  function returnLogin() {
    next("/login");
    return false;
  }

  function returnDashboard() {
    next("/dashboard");
    return false;
  }

  // function passwordProtect() {
  //   next("/passwordprotect");
  //   return false;
  // }
});
export default router;

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { loadFonts } from "./plugins/webfontloader";
import "./assets/css/style.css";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueApexCharts from "vue3-apexcharts";
import { createVfm } from "vue-final-modal";
import "vue-final-modal/style.css";


loadFonts();
createApp(App)
  .use(router)
  .use(store)
  .use(Toast)
  .use(VueSweetalert2)
  .use(VueApexCharts)
  .use(createVfm)
  .mount("#app");

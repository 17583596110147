<template>
  <div class="container">
    <TitleBar></TitleBar>
    <div class="text-center" v-if="loading == true">
      <img :src="require('@/assets/images/loading.gif')" />
    </div>

    <div class="text-danger" v-if="errorMsg != ''">
      {{ errorMsg }}
    </div>
    <div v-if="loading == false">
      <tab :tabs="tabs" @tabChanged="onTabChange">
        <template v-slot:tab1>
          <div class="row title-section">
            <div class="col">
              <h4 class="pull-left">Company Profile</h4>
            </div>
          </div>
          <div class="row">
            <label
              for="staticEmail"
              class="col-sm-3 col-form-label detail-page-label"
              >Name</label
            >
            <div class="col-sm-9">
              <div class="form-control-plaintext detail-page-input">
                {{ companyDetail.companyName }}
              </div>
            </div>
          </div>
          <div class="row">
            <label
              for="staticEmail"
              class="col-sm-3 col-form-label detail-page-label"
              >Contact Number</label
            >
            <div class="col-sm-9">
              <div class="form-control-plaintext detail-page-input">
                {{ companyDetail.contactNumber }}
              </div>
            </div>
          </div>
          <div class="row">
            <label
              for="staticEmail"
              class="col-sm-3 col-form-label detail-page-label"
              >Email</label
            >
            <div class="col-sm-9">
              <div class="form-control-plaintext detail-page-input">
                {{ companyDetail.email }}
              </div>
            </div>
          </div>
          <div class="row">
            <label
              for="staticEmail"
              class="col-sm-3 col-form-label detail-page-label"
            >
              Status</label
            >
            <div class="col-sm-9">
              <div
                class="form-control-plaintext detail-page-input"
                :class="companyDetail.statusString === 'Inactive' ? 'red' : ''"
              >
                {{ companyDetail.statusString }}
              </div>
            </div>
          </div>

          <div class="row mt-5 title-section">
            <div class="col">
              <h4 class="pull-left">Employer</h4>
            </div>
          </div>
          <div class="row">
            <label
              for="staticEmail"
              class="col-sm-3 col-form-label detail-page-label"
              >Name</label
            >
            <div class="col-sm-9">
              <div class="form-control-plaintext detail-page-input">
                {{ companyDetail.eFirstName }} {{ companyDetail.eLastName }}
              </div>
            </div>
          </div>

          <div class="mb-3 row">
            <label
              for="staticEmail"
              class="col-sm-3 col-form-label detail-page-label"
              >Email</label
            >
            <div class="col-sm-9">
              <div class="form-control-plaintext detail-page-input">
                {{ companyDetail.eEmail }}
              </div>
            </div>
          </div>
          <div class="pddtop30">
            <router-link
              v-if="companyDetail.id"
              class="btn btn-primary mrgrbtn"
              type="submit"
              :to="{
                name: 'admin-company-update',
                params: {
                  id: companyDetail.id,
                },
              }"
            >
              Update Company
            </router-link>
          </div>
        </template>
        <template v-slot:tab2>
          <div class="text-center" v-if="loading">
            <img :src="require('@/assets/images/loading.gif')" />
          </div>

          <div v-if="!loading">
            <div class="mb-3 row">
              <label
                for="staticEmail"
                class="col-sm-3 col-form-label detail-page-label"
                >Min Per Transaction (RM)</label
              >
              <div class="col-sm-9">
                <div class="form-control-plaintext detail-page-input">
                  {{
                    configuration.minPerTransaction
                      ? parseFloat(configuration.minPerTransaction)
                          .toFixed(2)
                          .toLocaleString()
                      : "0"
                  }}
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <label
                for="staticEmail"
                class="col-sm-3 col-form-label detail-page-label"
                >Max Per Transaction (RM)</label
              >
              <div class="col-sm-9">
                <div class="form-control-plaintext detail-page-input">
                  {{
                    configuration.maxPerTransaction
                      ? parseFloat(configuration.maxPerTransaction)
                          .toFixed(2)
                          .toLocaleString()
                      : "0"
                  }}
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <label
                for="staticEmail"
                class="col-sm-3 col-form-label detail-page-label"
                >Max Per Month (RM)</label
              >
              <div class="col-sm-9">
                <div class="form-control-plaintext detail-page-input">
                  {{
                    configuration.maxPerMonth
                      ? parseFloat(configuration.maxPerMonth)
                          .toFixed(2)
                          .toLocaleString()
                      : "0"
                  }}
                </div>
              </div>
            </div>

            <div class="mb-3 row">
              <label
                for="staticEmail"
                class="col-sm-3 col-form-label detail-page-label"
                >Transaction Fees</label
              >
              <div class="col-sm-9">
                <div class="form-control-plaintext detail-page-input">
                  {{
                    configuration.transactionFees
                      ? parseFloat(configuration.transactionFees)
                          .toFixed(2)
                          .toLocaleString()
                      : "0"
                  }}
                </div>
              </div>
            </div>

            <div class="mb-3 row">
              <label
                for="staticEmail"
                class="col-sm-3 col-form-label detail-page-label"
                >Start Day</label
              >
              <div class="col-sm-9">
                <div class="form-control-plaintext detail-page-input">
                  {{ configuration.startDay }}
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <label
                for="staticEmail"
                class="col-sm-3 col-form-label detail-page-label"
                >Cut Off Day</label
              >
              <div class="col-sm-9">
                <div class="form-control-plaintext detail-page-input">
                  {{ configuration.cutOffDay }} {{ configuration.cutOffDay < 1 ? ' (Set 0 for end of month)' : '' }}
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <label
                for="staticEmail"
                class="col-sm-3 col-form-label detail-page-label"
                >Block Start Day</label
              >
              <div class="col-sm-9">
                <div class="form-control-plaintext detail-page-input">
                  {{ configuration.blockStart }}
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <label
                for="staticEmail"
                class="col-sm-3 col-form-label detail-page-label"
                >Block End Day</label
              >
              <div class="col-sm-9">
                <div class="form-control-plaintext detail-page-input">
                  {{ configuration.blockEnd }}
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <label
                for="staticEmail"
                class="col-sm-3 col-form-label detail-page-label"
                >Curlec Integration</label
              >
              <div class="col-sm-9">
                <div class="form-control-plaintext detail-page-input">
                  {{
                    configuration.allowCurlecIntegration == 1
                      ? "Enable"
                      : "Disable"
                  }}
                </div>
              </div>
            </div>

            <div class="mb-3 row">
              <label
                for="staticEmail"
                class="col-sm-3 col-form-label detail-page-label"
                >Total Working Day</label
              >
              <div class="col-sm-9">
                <div class="form-control-plaintext detail-page-input">
                  {{ configuration.totalWorkingDay }}
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <label
                for="staticEmail"
                class="col-sm-3 col-form-label detail-page-label"
                >Insurance Policy</label
              >
              <div class="col-sm-9">
                <div class="form-control-plaintext detail-page-input">
                  {{ selectedInsurance.insuranceName }}
                </div>
              </div>
            </div>
            <div class="mb-3 row" v-if="selectedInsurance.insuranceType !== 0">
              <label
                for="staticEmail"
                class="col-sm-3 col-form-label detail-page-label"
                >Premium Fees</label
              >
              <div class="col-sm-9">
                <div class="form-control-plaintext detail-page-input">
                  {{
                    parseFloat(selectedInsurance.insuranceFee)
                      .toFixed(2)
                      .toLocaleString()
                  }}
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <label
                for="staticEmail"
                class="col-sm-3 col-form-label detail-page-label"
                >Application Company Logo</label
              >
              <div class="col-sm-9">
                <div class="form-control-plaintext detail-page-input">
                  {{
                    configuration.allowAppCompanyLogo == 1
                      ? "Enable"
                      : "Disable"
                  }}
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <label
                for="staticEmail"
                class="col-sm-3 col-form-label detail-page-label"
                >Auto Withdrawal Request</label
              >
              <div class="col-sm-9">
                <div class="form-control-plaintext detail-page-input">
                  {{
                    configuration.allowAutoWithdrawRequest == 1
                      ? "Enable"
                      : "Disable"
                  }}
                </div>
              </div>
            </div>
            <div
              class="mb-3 row"
              v-if="configuration.allowAutoWithdrawRequest == 1"
            >
              <label
                for="staticEmail"
                class="col-sm-3 col-form-label detail-page-label"
                >Auto Withdrawal Request Payday</label
              >
              <div class="col-sm-9">
                <div class="form-control-plaintext detail-page-input">
                  {{ configuration.autoWithdrawRequestPayoutDate }}
                </div>
              </div>
            </div>
            <div
              class="mb-3 row"
              v-if="configuration.allowAutoWithdrawRequest == 1"
            >
              <label
                for="staticEmail"
                class="mb-3 col-sm-12 col-form-label detail-page-label"
                >Auto Withdrawal Request Rank</label
              >
              <div class="col-sm-12">
                <div class="form-control-plaintext detail-page-input">
                  <table class="table table-list">
                    <thead>
                      <tr>
                        <th>Min Salary (RM)</th>
                        <th>Max Salary (RM)</th>
                        <th>Payout (RM)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(
                          row, index
                        ) in configuration.autoWithdrawRankList"
                        :key="index"
                      >
                        <td>
                          {{
                            row.firstField && row.firstField != 0
                              ? parseFloat(row.firstField)
                                  .toFixed(2)
                                  .toLocaleString()
                              : "0"
                          }}
                        </td>
                        <td>
                          {{
                            row.secondField && row.secondField != 0
                              ? parseFloat(row.secondField)
                                  .toFixed(2)
                                  .toLocaleString()
                              : "0"
                          }}
                        </td>
                        <td>
                          {{
                            row.thirdField && row.thirdField != 0
                              ? parseFloat(row.thirdField)
                                  .toFixed(2)
                                  .toLocaleString()
                              : "0"
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="pddtop30">
            <router-link
              v-if="configuration.companyId"
              class="btn btn-primary mrgrbtn"
              type="submit"
              :to="{
                name: 'admin-configuration-update',
                params: {
                  id: configuration.companyId,
                },
              }"
            >
              Update Configuration
            </router-link>
          </div>
        </template>
        <template v-slot:tab3>
          <div class="row">
            <div class="col-12 col-sm-4">
              <div class="page-title-s">
                <div
                  class="search-box"
                  v-if="employeeQuery !== ''"
                  @click="employeeQuery = ''"
                >
                  <i class="fas fa-times"></i>
                </div>
                <input
                  type="text"
                  v-model="employeeQuery"
                  placeholder="Search"
                />
              </div>
            </div>
            <div class="col-12 col-sm-2">
              <button
                class="btn btn-primary btn-sm btn-list btn-list-3"
                @click="downloadCompanyEmployeeList()"
              >
                <i class="fa fa-download"></i>
                {{ downloading ? "Download..." : "Download" }}
              </button>
            </div>
            <div class="col text-sm-end">
              <div class="mb-4 float-sm-right">
                Total User: {{ employees.length }}<br />
                Total Active User:
                {{
                  employees.filter(function (employee) {
                    return employee.mainStatus == "Active";
                  }).length
                }}<br />
                Total App Activated User:
                {{
                  employees.filter(function (employee) {
                    return employee.status == "Active";
                  }).length
                }}
              </div>
            </div>
          </div>

          <div
            class="row mb-4"
            v-if="configuration.allowCurlecIntegration === 1"
          >
            <div class="col d-flex align-items-center gap-2">
              <p class="m-0">
                Curlec Profile Generated {{ curlecCount }}/{{
                  employees.length
                }}
              </p>

              <button
                v-if="curlecCount < employees.length"
                class="btn btn-primary btn-sm btn-list btn-list-3"
                @click="generateCurlecProfiles"
              >
                {{ generatingCurlecProfile ? "Generating..." : "Generate" }}
              </button>
            </div>
          </div>
          <Pagination
            :totalItem="employees.length"
            :items="employees"
            :perPage="50"
            queryStringForPageNumber="employeesPageNumber"
          >
            <template #data="{ paginatedItems, currentIndex }">
              <table
                class="table table-list"
                v-if="loading == false && employees.length > 0"
              >
                <thead>
                  <tr>
                    <th scope="col">No</th>
                    <th
                      scope="col"
                      class="pointer"
                      @click="onEmployeeSort('fullName')"
                    >
                      <span
                        class="sort-arrow"
                        :class="
                          employeeSortField === 'fullName'
                            ? employeeSortOrder === 'desc'
                              ? 'sort-arrow-desc'
                              : 'sort-arrow-asc'
                            : ''
                        "
                        >Name</span
                      >
                    </th>

                    <th
                      scope="col"
                      class="pointer"
                      @click="onEmployeeSort('contactNumber')"
                    >
                      <span
                        class="sort-arrow"
                        :class="
                          employeeSortField === 'contactNumber'
                            ? employeeSortOrder === 'desc'
                              ? 'sort-arrow-desc'
                              : 'sort-arrow-asc'
                            : ''
                        "
                        >Contact Number</span
                      >
                    </th>
                    <th
                      scope="col"
                      class="pointer"
                      @click="onEmployeeSort('subCompanyName')"
                    >
                      <span
                        class="sort-arrow"
                        :class="
                          employeeSortField === 'subCompanyName'
                            ? employeeSortOrder === 'desc'
                              ? 'sort-arrow-desc'
                              : 'sort-arrow-asc'
                            : ''
                        "
                        >Company</span
                      >
                    </th>
                    <th
                      scope="col"
                      class="pointer"
                      @click="onEmployeeSort('mainStatus')"
                    >
                      <span
                        class="sort-arrow"
                        :class="
                          employeeSortField === 'mainStatus'
                            ? employeeSortOrder === 'desc'
                              ? 'sort-arrow-desc'
                              : 'sort-arrow-asc'
                            : ''
                        "
                        >Account Status</span
                      >
                    </th>

                    <th
                      scope="col"
                      class="pointer"
                      @click="onEmployeeSort('status')"
                    >
                      <span
                        class="sort-arrow"
                        :class="
                          employeeSortField === 'status'
                            ? employeeSortOrder === 'desc'
                              ? 'sort-arrow-desc'
                              : 'sort-arrow-asc'
                            : ''
                        "
                        >App Activation status</span
                      >
                    </th>
                    <th
                      v-if="configuration.allowCurlecIntegration === 1"
                      scope="col"
                      class="pointer"
                      @click="onEmployeeSort('curlecProfileExist')"
                    >
                      <span
                        class="sort-arrow"
                        :class="
                          employeeSortField === 'curlecProfileExist'
                            ? employeeSortOrder === 'desc'
                              ? 'sort-arrow-desc'
                              : 'sort-arrow-asc'
                            : ''
                        "
                        >Curlec Profile</span
                      >
                    </th>

                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(employee, index) in paginatedItems"
                    :key="employee.id"
                  >
                    <td scope="row">{{ index + currentIndex }}</td>
                    <td>
                      {{ employee.fullName }}
                    </td>

                    <td>{{ employee.contactNumber }}</td>
                    <td>
                      {{ employee.subCompanyName }}
                    </td>
                    <td
                      :class="employee.mainStatus === 'Inactive' ? 'red' : ''"
                    >
                      {{ employee.mainStatus }}
                    </td>
                    <td :class="employee.status === 'Inactive' ? 'red' : ''">
                      {{ employee.status }}
                    </td>
                    <td :class="employee.curlecProfileExist === 1 ? '' : 'red'">
                      {{ employee.curlecProfileExist == 1 ? "Active" : "Inactive" }}
                    </td>

                    <td>
                      <router-link
                        :to="{
                          name: 'admin-employee-details',
                          params: {
                            id: employee.id,
                          },
                        }"
                        target="_blank"
                        ><img
                          class="edit-btn-img"
                          :src="require('@/assets/images/edit-btn.svg')"
                      /></router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </Pagination>
        </template>
        <template v-slot:tab4>
          <div class="text-center" v-if="loading">
            <img :src="require('@/assets/images/loading.gif')" />
          </div>
          <Pagination
            :totalItem="subCompanies.length"
            :items="subCompanies"
            :perPage="50"
            queryStringForPageNumber="companyPageNumber"
          >
            <template #data="{ paginatedItems, currentIndex }">
              <table class="table table-list" v-if="loading == false">
                <thead>
                  <tr>
                    <th scope="col">No</th>
                    <th
                      scope="col"
                      class="pointer"
                      @click="onSubCompanySort('companyName')"
                    >
                      <span
                        class="sort-arrow"
                        :class="
                          subCompanySortField == 'companyName'
                            ? subCompanySortOrder == 'desc'
                              ? 'sort-arrow-desc'
                              : 'sort-arrow-asc'
                            : ''
                        "
                        >Name</span
                      >
                    </th>
                    <th
                      scope="col"
                      class="pointer"
                      @click="onSubCompanySort('contactNumber')"
                    >
                      <span
                        class="sort-arrow"
                        :class="
                          subCompanySortField == 'contactNumber'
                            ? subCompanySortOrder == 'desc'
                              ? 'sort-arrow-desc'
                              : 'sort-arrow-asc'
                            : ''
                        "
                        >Contact Number</span
                      >
                    </th>
                    <th
                      scope="col"
                      class="pointer"
                      @click="onSubCompanySort('email')"
                    >
                      <span
                        class="sort-arrow"
                        :class="
                          subCompanySortField == 'email'
                            ? subCompanySortOrder == 'desc'
                              ? 'sort-arrow-desc'
                              : 'sort-arrow-asc'
                            : ''
                        "
                        >Email</span
                      >
                    </th>

                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(subcompany, index) in paginatedItems"
                    :key="subcompany.id"
                  >
                    <td scope="row">{{ index + currentIndex }}</td>
                    <td>{{ subcompany.companyName }}</td>
                    <td>{{ subcompany.contactNumber }}</td>
                    <td>{{ subcompany.email }}</td>
                  </tr>
                </tbody>
              </table>
            </template>
          </Pagination>
        </template>
      </tab>
      <router-link class="btn btn-secondary mt-4" to="/admin/company/list">
        Back
      </router-link>
    </div>
  </div>
</template>
<script lang="ts" setup>
import axios from "axios";
import { Ref, computed, ref } from "vue";
import Pagination from "@/components/Pagination.vue";
import Tab from "@/components/Tab.vue";
import TitleBar from "@/components/TitleBar.vue";
import { usePasswordVerify } from "@/composables/usePasswordVerify";
import { useListData } from "@/composables/useListData";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
const route = useRoute();
const store = useStore();
const {
  list: employees,
  onSort: onEmployeeSort,
  search: employeeQuery,
  sortField: employeeSortField,
  sortOrder: employeeSortOrder,
} = useListData({
  loadFunction: loadEmployees,
  searchFunction: onSearchEmployees,
  defaultSortField: "fullName",
});
const {
  list: subCompanies,
  onSort: onSubCompanySort,
  sortField: subCompanySortField,
  sortOrder: subCompanySortOrder,
} = useListData({
  loadFunction: loadSubCompanies,
  defaultSortField: "companyName",
});
const { verifyPassword } = usePasswordVerify();
const downloading = ref(false);
const companyDetail = ref({}) as Ref<any>;
const configuration = ref({}) as Ref<any>;

const insuranceList = ref([]);
const selectedInsurance = computed(() => {
  const found = insuranceList.value.find(
    (list) => configuration.value.insuranceType === list.insuranceType
  );
  if (!found) return {};
  return { ...found, insuranceFee: configuration.value.insuranceFee };
});

const loading = ref(true);
const errorMsg = ref("");
const tabs = [
  { label: "Company Profile", slotName: "tab1" },
  { label: "Configuration", slotName: "tab2" },
  { label: "Employee", slotName: "tab3" },
  { label: "Company", slotName: "tab4" },
];

function parseWithdrawalRank(withdrawRankInString) {
  const splitRanks = withdrawRankInString.split(",");
  return splitRanks.map((value) => {
    const splitFields = value.split("@");
    return {
      firstField: splitFields[0],
      secondField: splitFields[1],
      thirdField: splitFields[2],
    };
  });
}

async function downloadCompanyEmployeeList() {
  if (!(await verifyPassword())) return;
  try {
    downloading.value = true;
    const id = route.params.id;
    const token = store.state.token;
    const response = await axios.get(
      process.env.VUE_APP_API_URL + "Company/" + id + "/download-employee-list",
      {
        responseType: "blob",
        headers: {
          Authorization: "bearer " + token,
        },
      }
    );

    var fileURL = window.URL.createObjectURL(
      new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    var fileLink = document.createElement("a");

    fileLink.href = fileURL;
    fileLink.setAttribute("download", "company_employee_list_" + id + ".csv");
    document.body.appendChild(fileLink);

    fileLink.click();
    downloading.value = false;
  } catch (error) {
    //
  }

  downloading.value = false;
}

async function getInsuranceList() {
  try {
    const token = store.state.token;
    const response = await axios.get(
      process.env.VUE_APP_API_URL + "Insurance/list",
      {
        headers: {
          Authorization: "bearer " + token,
        },
      }
    );
    insuranceList.value = response.data;
  } catch (error) {
    //
  }
}

async function getCompanyDetail() {
  try {
    const id = route.params.id;
    const token = store.state.token;
    const response = await axios.get(
      process.env.VUE_APP_API_URL + "company/Cemployer/" + id,
      {
        headers: {
          Authorization: "bearer " + token,
        },
      }
    );
    companyDetail.value = response.data;
  } catch (error) {
    //
  }
  loading.value = false;
}

async function getConfiguration() {
  try {
    const id = route.params.id;
    const token = store.state.token;
    const response = await axios.get(
      process.env.VUE_APP_API_URL + "Configuration/" + id + "/company/details",
      {
        headers: {
          Authorization: "bearer " + token,
        },
      }
    );

    let data = response.data;
    if (response.data.autoWithdrawRank) {
      data = {
        ...data,
        autoWithdrawRankList: parseWithdrawalRank(
          response.data.autoWithdrawRank
        ),
      };
    }
    configuration.value = data;
  } catch (error) {
    //
  }
  loading.value = false;
}

async function loadSubCompanies() {
  let result = [];
  try {
    const id = route.params.id;
    const token = store.state.token;
    const response = await axios.get(
      process.env.VUE_APP_API_URL + "SubCompany/" + id + "/list",
      {
        headers: {
          Authorization: "bearer " + token,
        },
      }
    );
    result = response.data;
  } catch (error) {
    //
  }
  loading.value = false;
  return result;
}

async function loadEmployees() {
  let result = [];
  try {
    const id = route.params.id;
    const token = store.state.token;
    const response = await axios.get(
      process.env.VUE_APP_API_URL + "employee/list?companyId=" + id,
      {
        headers: {
          Authorization: "bearer " + token,
        },
      }
    );
    result = response.data;
  } catch (error) {
    //
  }

  loading.value = false;
  return result;
}

function onSearchEmployees(list: any[], query: string) {
  if (query === "") return list;
  return list.filter((employee) => {
    return (
      (
        employee.firstName.toLowerCase() +
        " " +
        (employee.lastName ?? "").toLowerCase()
      ).indexOf(query.toLowerCase()) != -1 ||
      (employee.email ?? "").toLowerCase().indexOf(query.toLowerCase()) != -1 ||
      (employee.position ?? "").toLowerCase().indexOf(query.toLowerCase()) !=
        -1 ||
      (employee.contactNumber ?? "").indexOf(query.toLowerCase()) != -1 ||
      (employee.subCompanyName ?? "").indexOf(query.toLowerCase()) != -1
    );
  });
}

const curlecCount = ref(0);
async function getAllEmployeesCurlecProfileCount() {
  try {
    const id = route.params.id;
    const token = store.state.token;
    const response = await axios.get(
      process.env.VUE_APP_API_URL + "Employee/curlec-profiles/count/" + id,
      {
        headers: {
          Authorization: "bearer " + token,
        },
      }
    );
    curlecCount.value = response.data.count;
  } catch (error) {
    //
  }
}
const generatingCurlecProfile = ref(false);
async function generateCurlecProfiles() {
  try {
    generatingCurlecProfile.value = true;
    const id = route.params.id;
    const token = store.state.token;
    const response = await axios.post(
      process.env.VUE_APP_API_URL +
        "Employee/generate/curlec-profile-list/" +
        id,
      {},
      {
        headers: {
          Authorization: "bearer " + token,
        },
      }
    );
    getAllEmployeesCurlecProfileCount();
  } catch (error) {
    //
  }
  generatingCurlecProfile.value = false;
}

async function onTabChange() {
  employeeQuery.value = "";
}

function fetchAll() {
  getInsuranceList();
  getCompanyDetail();
  getConfiguration();
  getAllEmployeesCurlecProfileCount();
}

fetchAll();
</script>
<style scoped>
.page-title-s {
  margin-bottom: 10px;
  width: 100%;
}
.page-title-s input {
  width: 100%;
}
</style>

<template>
  <div class="container">
    <TitleBar></TitleBar>
    <div class="text-center" v-if="loading == true">
      <img :src="require('@/assets/images/loading.gif')" />
    </div>
    <div class="row mt-4 title-section gap-2" v-if="loading == false">
      <div class="col-12 col-md-6 d-flex gap-2">
        <Datepicker v-model="startDate" :enable-time-picker="false" :format="formatDateToDDMMYYYY" :max-date="endDate"
          :auto-apply="true" :close-on-auto-apply="true" class="calender-picker-field w-100"><template #input-icon>
            <img :src="require('@/assets/images/Group-714.svg')" alt="" class="calander-logo" /> </template></Datepicker>
        <Datepicker v-model="endDate" :enable-time-picker="false" :format="formatDateToDDMMYYYY" :min-date="startDate"
          :auto-apply="true" :close-on-auto-apply="true" class="calender-picker-field w-100"><template #input-icon>
            <img :src="require('@/assets/images/Group-714.svg')" alt="" class="calander-logo" />
          </template>
        </Datepicker>
      </div>

      <div class="col-12 col-md-5 d-flex gap-2">
        <button class="btn btn-primary btn-sm me-2 btn-list btn-list-3 w-100" style="height: fit-content" @click="filter">
          <i class="fa fa-search"></i> Filter
        </button>
        <button class="btn btn-primary btn-sm btn-list btn-list-3 w-100" style="height: fit-content"
          @click="DownloadExcel">
          <i class="fa fa-download"></i> Download
        </button>
      </div>
    </div>

    <p v-if="totalamount > 0 && totalNetAmount > 0">
      Total Fees : RM
      {{
        parseFloat(totalamount - totalNetAmount + "")
          .toFixed(2)
          .toLocaleString()
      }}
    </p>
    <Pagination :totalItem="withdrawalList.length" :items="withdrawalList" :perPage="50">
      <template #data="{ paginatedItems, currentIndex }">
        <table class="table table-list" v-if="loading == false">
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col" class="pointer" @click="onSort('fullName')">
                <span class="sort-arrow" :class="currentSort == 'fullName'
                    ? currentSortDir == 'desc'
                      ? 'sort-arrow-desc'
                      : 'sort-arrow-asc'
                    : ''
                  ">Name</span>
              </th>
              <th scope="col" class="pointer" @click="onSort('bankName')">
                <span class="sort-arrow" :class="currentSort == 'bankName'
                    ? currentSortDir == 'desc'
                      ? 'sort-arrow-desc'
                      : 'sort-arrow-asc'
                    : ''
                  ">Bank</span>
              </th>

              <th scope="col" class="pointer" @click="onSort('totalAmountRequested')">
                <span class="sort-arrow" :class="currentSort == 'totalAmountRequested'
                    ? currentSortDir == 'desc'
                      ? 'sort-arrow-desc'
                      : 'sort-arrow-asc'
                    : ''
                  ">Amount Requested</span>
              </th>
              <th scope="col" class="pointer" @click="onSort('nettAmountToTransfer')">
                <span class="sort-arrow" :class="currentSort == 'nettAmountToTransfer'
                    ? currentSortDir == 'desc'
                      ? 'sort-arrow-desc'
                      : 'sort-arrow-asc'
                    : ''
                  ">Nett Amount Transfer</span>
              </th>
              <th scope="col" class="pointer" @click="onSort('isAutoWithdrawType')" v-if="autowithdrawalStatus === 1">
                <span class="sort-arrow" :class="currentSort == 'isAutoWithdrawType'
                    ? currentSortDir == 'desc'
                      ? 'sort-arrow-desc'
                      : 'sort-arrow-asc'
                    : ''
                  ">Type</span>
              </th>
              <th scope="col" class="pointer" @click="onSort('requestedAt')">
                <span class="sort-arrow" :class="currentSort == 'requestedAt'
                    ? currentSortDir == 'desc'
                      ? 'sort-arrow-desc'
                      : 'sort-arrow-asc'
                    : ''
                  ">Requested At</span>
              </th>
              <th scope="col" class="pointer" @click="onSort('statusString')">
                <span class="sort-arrow" :class="currentSort == 'statusString'
                    ? currentSortDir == 'desc'
                      ? 'sort-arrow-desc'
                      : 'sort-arrow-asc'
                    : ''
                  ">Status</span>
              </th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="
                  font-size: 16px;
                  letter-spacing: 0px;
                  color: #472667;
                  font-weight: 700;
                ">
                Total
              </td>
              <td>---</td>
              <td>---</td>
              <td>
                {{
                  parseFloat(totalamount + "")
                    .toFixed(2)
                    .toLocaleString()
                }}
              </td>
              <td>
                {{
                  parseFloat(totalNetAmount + "")
                    .toFixed(2)
                    .toLocaleString()
                }}
              </td>
              <td>---</td>
              <td>---</td>
              <td>---</td>
            </tr>

            <tr v-for="(withdrawal, index) in paginatedItems" :key="index + currentIndex">
              <td scope="row">{{ index + currentIndex }}</td>
              <td>{{ withdrawal.fullName }}</td>
              <td>{{ withdrawal.bankName }}</td>
              <!-- <td>{{ withdrawal.bankAccountNumber }}</td> -->
              <td>
                {{
                  parseFloat(withdrawal.totalAmountRequested)
                    .toFixed(2)
                    .toLocaleString()
                }}
              </td>
              <td>
                {{
                  parseFloat(withdrawal.nettAmountToTransfer)
                    .toFixed(2)
                    .toLocaleString()
                }}
              </td>
              <td v-if="autowithdrawalStatus === 1">
                {{ withdrawal.isAutoWithdrawType == 1 ? "Auto" : "Manual" }}
              </td>
              <td>
                {{
                  moment(withdrawal.requestedAt).format("DD-MM-YYYY")
                }}
              </td>
              <td :class="withdrawal.statusString == 'Rejected' ? 'red' : ''">
                {{ withdrawal.statusString }}
              </td>
              <td>
                <router-link :to="{
                  name: 'withdrawal-details',
                  params: {
                    id: withdrawal.id,
                  },
                }"><img class="edit-btn-img" :src="require('@/assets/images/edit-btn.svg')" /></router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </Pagination>
  </div>
</template>

<script lang="ts">
import store from "@/store";
import axios from "axios";
import {
  defineComponent,
  ref,
  onMounted,
  reactive,
  computed,
  watch,
} from "vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import Pagination from "@/components/Pagination.vue";
import moment from "moment";
import TitleBar from "@/components/TitleBar.vue";
import { useStore } from "vuex";
import { useDateParam } from "@/composables/useDateParam";
import {
  formatDateToYYYYMMDD,
  formatDateToDDMMYYYY,
} from "@/helpers/date-formatter";
export default defineComponent({
  setup() {
    const { startDate, endDate, dateChange } = useDateParam();
    const store = useStore();

    const DownloadFilter = reactive({
      Date1: formatDateToYYYYMMDD(new Date()),
      Date2: formatDateToYYYYMMDD(new Date()),
    });
    const autowithdrawalStatus = computed(() => {
      return store.state.userdetail?.autowithdrawalStatus ?? 0;
    });

    return {
      autowithdrawalStatus,
      moment: moment,
      startDate,
      endDate,
      DownloadFilter,
      dateChange,
      formatDateToDDMMYYYY,
    };
  },
  name: "WithdrawalList",
  data() {
    return {
      currentSort: "name",
      currentSortDir: "asc",
      withdrawalList: ref([]),
      loading: true,
      count: 1,
      type: [
        { id: "All", name: "All" },
        { id: "0", name: "Manual" },
        { id: "1", name: "Auto" },
      ],
      typeDefault: "All",
      totalamount: 0,
      totalNetAmount: 0,
    };
  },
  components: {
    Datepicker,
    Pagination,
    TitleBar,
  },
  methods: {
    onSort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;

      this.withdrawalList = this.withdrawalList.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
    async getList(startDate?: Date, endDate?: Date) {
      const dateString1 = formatDateToYYYYMMDD(startDate ?? this.startDate);
      const dateString2 = formatDateToYYYYMMDD(endDate ?? this.endDate);

      this.DownloadFilter.Date1 = dateString1;
      this.DownloadFilter.Date2 = dateString2;

      const token = store.state.token;
      const response = await axios.get(
        process.env.VUE_APP_API_URL + "Withdrawal/history",
        {
          params: {
            date1: dateString1,
            date2: dateString2,
          },
          headers: {
            Authorization: "bearer " + token,
          },
        }
      );
      this.loading = false;
      this.withdrawalList = response.data;
      this.totalamount = 0;
      this.totalNetAmount = 0;

      this.withdrawalList.forEach((element) => {
        if (element.statusString === "Rejected") return;

        this.totalamount += element.totalAmountRequested;
        this.totalNetAmount += element.nettAmountToTransfer;
      });
    },

    filter() {
      this.dateChange();
      this.getList();
    },

    async DownloadExcel() {
      const token = store.state.token;
      const response = await axios
        .post(
          process.env.VUE_APP_API_URL + "withdrawal/download-list",
          this.DownloadFilter,
          {
            responseType: "blob",
            headers: {
              Authorization: "bearer " + token,
            },
          }
        )
        .then((res) => {
          var fileURL = window.URL.createObjectURL(
            new Blob([res.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            "withdrawal_list_" +
            this.DownloadFilter.Date1 +
            "_" +
            this.DownloadFilter.Date2 +
            ".xlsx"
          );
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((err) => {
          // 
        });
    },
  },
  mounted() {
    this.getList();
  },
});
</script>

<template>
  <div class="container mt-5">
    <TitleBar></TitleBar>
    <div class="text-danger" v-if="errorMsg != ''">
      {{ errorMsg }}
    </div>
    <form @submit.prevent="submit" enctype="multipart/form-data">
      <div class="row g-3 pddbtm20">
        <div class="col-sm-5">
          <label for="CompanyName" class="form-label">Company Name</label>
          <input v-model="this.formData.CompanyName" type="text" class="form-control" id="CompanyName" placeholder="" />
          <span class="text-danger" v-for="error in v$.CompanyName.$errors" :key="error.$uid">{{ error.$message }}</span>
        </div>
        <div class="col-sm-5"></div>
        <div class="col-5">
          <label for="ContactNumber" class="form-label">Contact Number</label>
          <input v-model="this.formData.ContactNumber" type="text" class="form-control" id="ContactNumber"
            placeholder="" />
          <span class="text-danger" v-for="error in v$.ContactNumber.$errors" :key="error.$uid">{{ error.$message
          }}</span>
        </div>
        <div class="col-5">
          <label for="Email" class="form-label">Email</label>
          <input v-model="this.formData.Email" type="text" class="form-control" id="Email" placeholder="" />
          <span class="text-danger" v-for="error in v$.Email.$errors" :key="error.$uid">{{ error.$message }}</span>
        </div>

        <div class="col-sm-10">
          <label for="companyLogo" class="form-label">Company Logo</label>
          <div class="input-file-div">
            <input type="file" id="file" name="file" ref="file" class="form-control file-input"
              accept=".png, .jpg, .jpeg, .gif" v-on:change="handleFile()" />
            <label class="custom-file-input">
              <div @click="openFileInput" class="custom-file-div">
                <div class="custom-file-inner">
                  <span class="icon"><img :src="require('@/assets/images/Group-1429.svg')" alt="File Icon" /></span>
                  <span class="label">Choose file</span>
                </div>
              </div>
            </label>
            <label class="custom-file-input-no-file-chosen" @click="openFileInput" v-if="!formData.file">
              <div class="custom-file-input-no-file-chosen-opacity">
                No file selected
              </div>
            </label>
          </div>
          <span class="text-danger" v-for="error in v$.CompanyLogo.$errors" :key="error.$uid">{{ error.$message }}</span>
        </div>
        <div class="col-sm-12" v-if="this.data.CompanyLogoURL != null && this.data.CompanyLogoURL != ''
          ">
          <img class="profile-img-display" :src="this.data.imgBaseUrl + this.data.CompanyLogoURL" />
        </div>
      </div>
      <div class="row mt-2 g-3">
        <div class="col-12 title-section">
          <h3 class="pull-left">Employer</h3>
          <span class="title-border-b" />
        </div>
        <div class="col-sm-5">
          <label for="EfirstName" class="form-label">First name</label>
          <input v-model="this.formData.EFirstName" type="text" class="form-control" id="EfirstName" placeholder="" />
          <span class="text-danger" v-for="error in v$.EFirstName.$errors" :key="error.$uid">{{ error.$message }}</span>
        </div>

        <div class="col-sm-5">
          <label for="ElastName" class="form-label">Last name</label>
          <input v-model="formData.ELastName" type="text" class="form-control" id="ElastName" placeholder="" />
          <span class="text-danger" v-for="error in v$.ELastName.$errors" :key="error.$uid">{{ error.$message }}</span>
        </div>

        <div class="col-sm-5">
          <label for="EEmail" class="form-label">Email <span class="text-muted"></span></label>
          <input v-model="this.formData.EEmail" type="text" class="form-control" id="EEmail" placeholder="" />
          <span class="text-danger" v-for="error in v$.EEmail.$errors" :key="error.$uid">{{ error.$message }}</span>
        </div>

        <!-- <div class="col-sm-6">
          <label for="EContactNumber" class="form-label">Contact Number </label>
          <input
            v-model="formData.EContactNumber"
            type="text"
            class="form-control"
            id="EContactNumber"
            placeholder=""
          />
          <span
            class="text-danger"
            v-for="error in v$.EContactNumber.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div> -->
      </div>

      <div class="button-section">
        <router-link class="btn btn-secondary mrgrbtn" to="/dashboard">Cancel</router-link>
        <button class="btn btn-primary" type="submit" :disabled="sending">
          {{ sending ? "Submitting..." : "Submit" }}
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onUpdated } from "vue";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  email,
  sameAs,
  helpers,
  decimal,
  requiredIf,
  numeric,
  minLength,
  maxLength,
} from "@vuelidate/validators";
import { reactive } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import store from "@/store";
import axios from "axios";
import TitleBar from "@/components/TitleBar.vue";
var maxfilesize = 600; //in MB
const file_size_validation = (value, vm) => {
  if (!value) {
    return true;
  }
  let file = value;
  return file.size < maxfilesize * 1024 * 1024;
};
const file_type_validation = (value, vm) => {
  if (!value) {
    return true;
  }
  let file = value;
  return /\.(png)$/.test(file.name);
};

export default defineComponent({
  name: "MyProfile",
  components: {
    TitleBar,
  },
  setup() {
    const toast = useToast();
    const router = useRouter();
    const token = store.state.token;
    const role = computed(() => {
      return store.state.role;
    });
    const errorMsg = ref("");

    const data = reactive({
      loading: true,
      CompanyLogoURL: "",
      imgBaseUrl: process.env.VUE_APP_BASE_URL,
    });

    const formData = reactive({
      CompanyName: "",
      ContactNumber: "",
      Email: "",
      CompanyLogo: "",
      EFirstName: "",
      ELastName: "",
      EEmail: "",
      EContactNumber: "",
      file: "",
    });

    const sending = ref(false);

    const file = ref(null);

    const openFileInput = async () => {
      let fileInputElement = file.value;
      fileInputElement.click();
    };

    const handleFile = async () => {
      formData.CompanyLogo = file.value.files[0];
      formData.file = file.value.files[0];
    };

    const submit = async () => {
      if (sending.value) return;
      sending.value = true;

      const result = await v$.value.$validate();

      if (result) {
        await axios
          .put(process.env.VUE_APP_API_URL + "user/update-profile", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "bearer " + token,
            },
          })
          .then((res) => {
            errorMsg.value = "";

            toast.success("User successfully updated", {
              timeout: 3000,
            });
            profileDetails();
            sending.value = false;
          })
          .catch((err) => {
            errorMsg.value = err.response.data;
            sending.value = false;
          });
      } else {
        sending.value = false;
      }
    };

    const rules = computed(() => {
      return {
        CompanyLogo: {
          // file_size_validation,
          // file_type_validation,
        },
        CompanyName: {
          required: helpers.withMessage("Please enter company name", required),
        },
        ContactNumber: {
          required: helpers.withMessage(
            "Please enter contact number",
            required
          ),
          numeric,
        },
        Email: {
          email,
          required: helpers.withMessage("Please enter email", required),
        },
        EFirstName: {
          required: helpers.withMessage("Please enter first name", required),
        },
        ELastName: {
          required: helpers.withMessage("Please enter last name", required),
        },
        EEmail: {
          email,
          required: helpers.withMessage("Please enter email", required),
        },
        EContactNumber: {
          // required: helpers.withMessage(
          //   "Please enter contact number",
          //   required
          // ),
        },
      };
    });
    const v$ = useVuelidate(rules, formData);

    const profileDetails = async () => {
      await axios
        .get(process.env.VUE_APP_API_URL + "user/get-employerprofile", {
          headers: {
            Authorization: "bearer " + token,
          },
        })
        .then((res) => {
          data.CompanyLogoURL = res.data.companyLogo;
          formData.Email = res.data.email;
          formData.CompanyName = res.data.companyName;
          formData.ContactNumber = res.data.contactNumber;

          formData.EFirstName = res.data.eFirstName;
          formData.ELastName = res.data.eLastName;
          formData.EEmail = res.data.eEmail;
          formData.EContactNumber = res.data.eContactNumber;
        })
        .catch((err) => {
          //
        });

      data.loading = false;
    };

    onMounted(async () => {
      profileDetails();
    });

    return {
      v$,
      data,
      formData,
      token,
      router,
      toast,
      errorMsg,
      file,
      submit,
      handleFile,
      openFileInput,
      sending,
    };
  },
  methods: {},
  validations() {
    return {};
  },
});
</script>

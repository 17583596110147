<template>
  <Header />

  <div class="container-fluid pb-5content-bg">
    <div class="row">
      <NavBar />
      <main
        :class="
          role == 'visitor'
            ? 'col-md-12 col-lg-12 ms-sm-auto  px-md-4'
            : 'col-md-9 col-lg-10 ms-sm-auto  px-md-4 content-bg'
        "
      >
        <router-view></router-view>
      </main>
    </div>
  </div>
</template>
<style></style>
<script lang="ts">
import "./assets/bootstrap-5.2.3-dist/css/bootstrap.min.css";
import NavBar from "@/components/NavBar.vue";
import Header from "./components/Header.vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";
export default {
  components: { NavBar, Header },

  setup() {
    const store = useStore();
    const role = computed(() => store.state.role);
    const col = ref("");
    return { role, col };
  },
  data() {
    return {};
  },
};
</script>

<template>
  <div class="container">
    <TitleBar></TitleBar>
    <div class="text-center" v-if="loading == true">
      <img :src="require('@/assets/images/loading.gif')" />
    </div>

    <div class="text-danger" v-if="errorMsg != ''">
      {{ errorMsg }}
    </div>
    <div v-if="loading == false">
      <div class="mb-3 row">
        <label
          for="staticEmail"
          class="col-sm-3 col-form-label detail-page-label"
          >Company Name</label
        >
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ configuration.companyName }}
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label
          for="staticEmail"
          class="col-sm-3 col-form-label detail-page-label"
          >Min Per Transaction</label
        >
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ configuration.minPerTransaction }}
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label
          for="staticEmail"
          class="col-sm-3 col-form-label detail-page-label"
          >Max Per Transaction</label
        >
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ configuration.maxPerTransaction }}
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label
          for="staticEmail"
          class="col-sm-3 col-form-label detail-page-label"
          >Max Per Month</label
        >
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ configuration.maxPerMonth }}
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label
          for="staticEmail"
          class="col-sm-3 col-form-label detail-page-label"
          >Transaction Fees</label
        >
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ configuration.transactionFees }}
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label
          for="staticEmail"
          class="col-sm-3 col-form-label detail-page-label"
          >Cut Off Day</label
        >
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ configuration.cutOffDay }}
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label
          for="staticEmail"
          class="col-sm-3 col-form-label detail-page-label"
          >Total Working Day</label
        >
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ configuration.totalWorkingDay }}
          </div>
        </div>
      </div>
      <!-- <hr class="my-4" /> -->
      <hr class="my-4" />
      <router-link
        class="btn btn-primary mrgrbtn"
        type="submit"
        :to="{
          name: 'admin-configuration-update',
          params: {
            id: this.id,
          },
        }"
      >
        Update
      </router-link>
      <router-link
        class="btn btn-secondary"
        :to="{
          name: 'admin-configuration-list',
        }"
        >Back</router-link
      >
    </div>
  </div>
</template>
<script lang="ts">
import store from "@/store";
import axios from "axios";
import { defineComponent, ref, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import TitleBar from "@/components/TitleBar.vue";
export default defineComponent({
  name: "AdminConfigurationDetails",
  components: {
    TitleBar,
  },
  setup() {
    const route = useRoute;
    const store = useStore();

    const role = computed(() => {
      return store.state.role;
    });

    return { role };
  },
  data() {
    return {
      id: ref(0),
      configuration: ref([]),
      loading: true,
      errorMsg: ref(""),
    };
  },
  methods: {
    async configurationDetails() {
      this.id = this.$route.params.id;

      const token = store.state.token;
      const response = await axios
        .get(
          process.env.VUE_APP_API_URL + "Configuration/" + this.id + "/details",
          {
            headers: {
              Authorization: "bearer " + token,
            },
          }
        )
        .then((res) => {
          this.configuration = res.data;
        })
        .catch((err) => {
           //
        });
      this.loading = false;
    },
  },
  mounted() {
    this.configurationDetails();
  },
});
</script>
import moment from "moment";

/**
 * Formats a date as "DD-MM-YYYY".
 *
 * @param date - The date to format.
 * @returns The formatted date string in "DD-MM-YYYY" format.
 */
export function formatDateToDDMMYYYY(date: Date): string {
  return moment(date).format("DD-MM-YYYY");
}

/**
 * Formats a date as "YYYY-MM-DD".
 *
 * @param date - The date to format.
 * @returns The formatted date string in "YYYY-MM-DD" format.
 */
export function formatDateToYYYYMMDD(date: Date): string {
  return moment(date).format("YYYY-MM-DD");
}

const convertDateStringToMoment = (dateString) => {
  return moment(dateString, 'YYYY-MM-DD');
};


/**
 * Converts a date string in "YYYY-MM-DD" format to a JavaScript Date object.
 *
 * @param {string} dateString - The date string in "YYYY-MM-DD" format.
 * @returns {Date} The JavaScript Date object.
 */
export function convertDateStringToJSDate(dateString) {
  return moment(dateString, 'YYYY-MM-DD').toDate();
}

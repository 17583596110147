<template>
  <div class="container">
    <TitleBar></TitleBar>
    <div class="text-center" v-if="loading == true">
      <img :src="require('@/assets/images/loading.gif')" />
    </div>

    <div class="text-danger" v-if="errorMsg != ''">
      {{ errorMsg }}
    </div>
    <div v-if="loading == false">
      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">Name</label>
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ enrolmentDetails.fullName }}
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">NRIC</label>
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ enrolmentDetails.icNumber }}
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">DOB</label>
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ moment(enrolmentDetails.dateOfBirth).format("DD-MM-YYYY") }}
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">Address 1</label>
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ enrolmentDetails.address1 }}
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">Address 2</label>
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ enrolmentDetails.address2 }}
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">City</label>
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ enrolmentDetails.city }}
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">Postcode</label>
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ enrolmentDetails.postCode }}
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">Telephone</label>
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ enrolmentDetails.telephonenNo }}
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">Occupation</label>
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ enrolmentDetails.occupation }}
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">Created At</label>
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{
              moment(enrolmentDetails.createdAt).format("DD MMMM YYYY, h:mm A")
            }}
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">Effective Date</label>
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{
              moment(enrolmentDetails.effectiveDate).format(
                "DD MMMM YYYY, h:mm A"
              )
            }}
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">Expiry Date</label>
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{
              moment(enrolmentDetails.expiry).format(
                "DD MMMM YYYY, h:mm A"
              )
            }}
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">Email</label>
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ enrolmentDetails.email }}
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">Policy Number</label>
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ enrolmentDetails.policyNumber }}
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">Status</label>
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ enrolmentDetails.statusString }}
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">Withdrawal ID</label>
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ enrolmentDetails.withdrawalRecordId }}
            <router-link v-if="role == 'admin'" :to="{
              name: 'admin-withdrawal-details',
              params: {
                id: enrolmentDetails.withdrawalRecordId,
              },
            }"><img class="edit-btn-img" :src="require('@/assets/images/edit-btn.svg')" /></router-link>
            <router-link v-else :to="{
              name: 'withdrawal-details',
              params: {
                id: enrolmentDetails.withdrawalRecordId,
              },
            }"><img class="edit-btn-img" :src="require('@/assets/images/edit-btn.svg')" /></router-link>
          </div>
        </div>
      </div>

      <hr class="my-4" />



      <div class="pddtop30">
        <router-link class="btn btn-secondary" to="/insurance-enrolment/list">
          Back
        </router-link>
        <!-- <button class="btn btn-danger mrgrbtn ms-4">
          Reject
        </button>
        <button class="btn btn-success">
          Approve
        </button> -->
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import axios from "axios";
import { Ref, ref, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import TitleBar from "@/components/TitleBar.vue";
import moment from "moment";
const route = useRoute();
const store = useStore();

const role = computed(() => {
  return store.state.role;
});
const enrolmentDetails = ref({}) as Ref<any>;
const loading = ref(true);
const errorMsg = ref("");
async function getEnrolmentDetails() {
  try {
    const id = route.params.id;
    const token = store.state.token;
    const response = await axios.get(
      process.env.VUE_APP_API_URL + "Insurance/record/" + id + "/details",
      {
        headers: {
          Authorization: "bearer " + token,
        },
      }
    );
    enrolmentDetails.value = response.data;
  } catch (error) {
    //
  }

  loading.value = false;
}

getEnrolmentDetails();
</script>
<style scoped>
.btn-danger {
  width: 150px;
  border-radius: 25px;
  font-size: 18px;
  padding: 6px 12px;
}
</style>

<template>
  <div
    class="background5 form-signin"
    data-aos="fade-left"
    data-aos-duration="1000"
    data-aos-delay="0"
    data-aos-once="true"
  >
    <form @submit.prevent="submit">
      <div class="container" v-if="formData.isFirstAttempt == 0">
        <div class="row">
          <div class="col-md-6 col-lg-6 offset-lg-1 login order-one">
            <h1
              class="h1-title mt-3"
              data-aos="fade-right"
              data-aos-duration="700"
              data-aos-once="true"
              data-aos-delay="300"
            >
              Client Login
            </h1>
            <p
              class="text-content col-7"
              data-aos="fade-up"
              data-aos-duration="700"
              data-aos-once="true"
              data-aos-delay="500"
            >
              Kindly key in the login details provided from your company.
            </p>
            <div class="text-danger" v-if="invalidLogin">
              <!-- Invalid email or password -->
              {{ invalidLoginMsg }}
            </div>
            <div class="text-danger" v-if="pleaseWait">Please wait...</div>
            <div class="col-7">
              <div
                class="form-text"
                data-aos="fade-up"
                data-aos-duration="700"
                data-aos-once="true"
                data-aos-delay="700"
              >
                EMAIL
              </div>
              <input
                data-aos="fade-up"
                data-aos-duration="700"
                data-aos-once="true"
                data-aos-delay="800"
                class="blank-space"
                type="text"
                v-model="formData.email"
              />
              <span
                class="text-danger"
                v-for="error in v$.email.$errors"
                :key="error.$uid"
                >{{ error.$message }}</span
              >
              <div
                class="form-text"
                data-aos="fade-up"
                data-aos-duration="700"
                data-aos-once="true"
                data-aos-delay="1000"
              >
                PASSWORD
              </div>
              <input
                data-aos="fade-up"
                data-aos-duration="700"
                data-aos-once="true"
                data-aos-delay="1200"
                class="blank-space"
                type="password"
                v-model="formData.password"
              />
              <span
                class="text-danger"
                v-for="error in v$.password.$errors"
                :key="error.$uid"
                >{{ error.$message }}</span
              >
            </div>
            <div class="flexbox">
              <input
                data-aos="fade-up"
                data-aos-duration="700"
                data-aos-once="true"
                data-aos-delay="1300"
                class="form-check-input mt-4"
                type="checkbox"
                value="remember-me"
                v-model="formData.rememberMe"
                aria-label="Checkbox for following text input"
              />
              <p
                class="checkbox form-text"
                data-aos="fade-up"
                data-aos-duration="700"
                data-aos-once="true"
                data-aos-delay="1400"
              >
                Remember me
              </p>
              <!-- <p
                class="checkbox forgot-p"
                data-aos="fade-up"
                data-aos-duration="700"
                data-aos-once="true"
                data-aos-delay="1600"
              >
                Forgot your password?
              </p> -->
            </div>
            <div
              id="send-button"
              data-aos="fade-up"
              data-aos-duration="700"
              data-aos-once="true"
              data-aos-delay="1600"
            >
              <button id="form-button" type="submit" :disabled="btnDisabled">
                {{ btnText }}
              </button>
            </div>
          </div>
          <div
            class="col-sm-12 col-md-6 col-lg-6 order-two"
            data-aos="fade-left"
            data-aos-duration="700"
            data-aos-once="true"
          >
            <img
              style="width: 100%"
              :src="require('@/assets/images/Banner-Images-clientlogin.png')"
              alt=""
            />
          </div>
          <div
            class="col-sm-12 col-md-6 col-lg-6 order-two hide-banner"
            data-aos="fade-left"
            data-aos-duration="700"
            data-aos-once="true"
          >
            <img
              style="width: 100%; transform: translateX(24px)"
              :src="require('@/assets/images/login.png')"
              alt=""
            />
          </div>
        </div>
      </div>
    </form>
    <form @submit.prevent="submit1">
      <div class="container" v-if="formData.isFirstAttempt == 1">
        <div class="row">
          <div class="col-md-6 col-lg-6 offset-lg-1 login order-one">
            <h1
              class="h1-title mt-3"
              data-aos="fade-right"
              data-aos-duration="700"
              data-aos-once="true"
              data-aos-delay="300"
            >
              Client Login
            </h1>
            <p
              class="text-content col-7"
              data-aos="fade-up"
              data-aos-duration="700"
              data-aos-once="true"
              data-aos-delay="500"
            >
              For first time login, please reset your password
            </p>
            <div class="text-danger" v-if="invalidLogin">
              <!-- Invalid email or password -->
              {{ invalidLoginMsg }}
            </div>
            <div class="text-danger" v-if="pleaseWait">Please wait...</div>
            <div class="col-7">
              <div
                class="form-text"
                data-aos="fade-up"
                data-aos-duration="700"
                data-aos-once="true"
                data-aos-delay="1000"
              >
                CURRENT PASSWORD
              </div>
              <input
                data-aos="fade-up"
                data-aos-duration="700"
                data-aos-once="true"
                data-aos-delay="1200"
                class="blank-space"
                type="password"
                v-model="formData.currentpassword"
              />
              <span
                class="text-danger"
                v-for="error in v$.currentpassword.$errors"
                :key="error.$uid"
                >{{ error.$message }}</span
              >
              <div
                class="form-text"
                data-aos="fade-up"
                data-aos-duration="700"
                data-aos-once="true"
                data-aos-delay="1000"
              >
                NEW PASSWORD
              </div>
              <input
                data-aos="fade-up"
                data-aos-duration="700"
                data-aos-once="true"
                data-aos-delay="1200"
                class="blank-space"
                type="password"
                v-model="formData.newpassword"
              />
              <span
                class="text-danger"
                v-for="error in v$.newpassword.$errors"
                :key="error.$uid"
                >{{ error.$message }}<br
              /></span>
              <div
                class="form-text"
                data-aos="fade-up"
                data-aos-duration="700"
                data-aos-once="true"
                data-aos-delay="1000"
              >
                CONFIRM PASSWORD
              </div>
              <input
                data-aos="fade-up"
                data-aos-duration="700"
                data-aos-once="true"
                data-aos-delay="1200"
                class="blank-space"
                type="password"
                v-model="formData.newconfirmpassword"
              />
              <span
                class="text-danger"
                v-for="error in v$.newconfirmpassword.$errors"
                :key="error.$uid"
                >{{ error.$message }}<br
              /></span>
            </div>
            <div
              id="send-button"
              data-aos="fade-up"
              data-aos-duration="700"
              data-aos-once="true"
              data-aos-delay="1600"
            >
              <button
                id="reset-p-form-button"
                type="submit"
                :disabled="btnDisabled"
              >
                {{ btnTextP }}
              </button>
            </div>
          </div>
          <div
            class="col-sm-12 col-md-6 col-lg-6 order-two"
            data-aos="fade-left"
            data-aos-duration="700"
            data-aos-once="true"
          >
            <img
              style="width: 100%"
              :src="require('@/assets/images/Banner-Images-clientlogin.png')"
              alt=""
            />
          </div>
          <div
            class="col-sm-12 col-md-6 col-lg-6 order-two hide-banner"
            data-aos="fade-left"
            data-aos-duration="700"
            data-aos-once="true"
          >
            <img
              style="width: 100%; transform: translateX(24px)"
              :src="require('@/assets/images/login.png')"
              alt=""
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script lang="ts">
import { computed, ref, onMounted } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers, requiredIf } from "@vuelidate/validators";
const { regex } = helpers;
import { reactive } from "@vue/reactivity";
import { useRouter } from "vue-router";
import axios from "axios";
import { useStore } from "vuex";

export default {
  name: "Login",
  setup() {
    const router = useRouter();
    const store = useStore();
    const invalidLogin = ref(false);
    const pleaseWait = ref(false);
    const invalidLoginMsg = ref("");
    const submit = async () => {
      const result = await v$.value.$validate();

      if (result) {
        invalidLogin.value = false;
        pleaseWait.value = true;
        try {
          const res = await axios.post(
            process.env.VUE_APP_API_URL + "Auth/login",
            formData,
            {
              withCredentials: true,
            }
          );

          if (
            res &&
            res.data &&
            res.data.resultCode &&
            res.data.email &&
            res.data.resultCode == "Yes" &&
            res.data.email == formData.email
          ) {
            formData.isFirstAttempt = 1;
            pleaseWait.value = false;
          } else {
            invalidLogin.value = false;
            if (formData.rememberMe) {
              const expiration = new Date();

              localStorage.setItem("tokenExpiration", expiration.toISOString());
              localStorage.setItem("rememberMe", "true");
            }
            store.commit("setToken", res.data);
            await store.dispatch("setRole");
            await store.dispatch("setUserdetail");

            pleaseWait.value = false;
            router.push("/dashboard");
          }
        } catch (error) {
          invalidLogin.value = true;
          pleaseWait.value = false;
          invalidLoginMsg.value = error.response.data;
        }
      }
    };

    const submit1 = async () => {
      formData.isFirstError = 1;
      const result = await v$.value.$validate();

      submit();
    };
    const role = computed(() => store.state.role);
    const getuserdetail = computed(() => store.state.userdetail);
    onMounted(async () => {
      await store.dispatch("setRole");
      if (role.value != "visitor") {
        router.push("dashboard");
      }
    });

    const formData = reactive({
      email: "",
      password: "",
      rememberMe: true,
      isFirstAttempt: 0,
      isFirstError: 0,
      currentpassword: "",
      newpassword: "",
      newconfirmpassword: "",
    });
    const sameAs = (passwordRef) => {
      return {
        $validator: (value) => value === formData[passwordRef],
        $message: "Password does not match",
      };
    };
    const rules = {
      email: {
        required: helpers.withMessage("Please enter your email", required),
      },
      password: {
        required: helpers.withMessage("Please enter password", required),
      },
      rememberMe: {},
      currentpassword: {
        requiredIf: helpers.withMessage(
          "Please enter current password",
          requiredIf(
            () => formData.isFirstAttempt == 1 && formData.isFirstError == 1
          )
        ),
      },
      newpassword: {
        requiredIf: helpers.withMessage(
          "Please enter new password",
          requiredIf(
            () => formData.isFirstAttempt == 1 && formData.isFirstError == 1
          )
        ),
        passwordformat: helpers.withMessage(
          "Password must be at least 8 characters long, contain at least one uppercase letter, one number, and one special character",
          helpers.regex(
            /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
          )
        ),
      },
      newconfirmpassword: {
        requiredIf: helpers.withMessage(
          "Please enter confirm password",
          requiredIf(
            () => formData.isFirstAttempt == 1 && formData.isFirstError == 1
          )
        ),
        sameAsPassword: sameAs("newpassword"),
      },
    };

    const v$ = useVuelidate(rules, formData);

    return {
      v$,
      submit,
      submit1,
      formData,
      invalidLogin,
      invalidLoginMsg,
      role,
      pleaseWait,
      getuserdetail,
    };
  },
  data() {
    return {
      btnDisabled: ref(false),
      btnText: ref("Sign In"),
      btnTextP: ref("Update Password"),
    };
  },

  components: {},
};
</script>
<style>
.form-signin {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.form-signin .h1-title {
  margin-left: 0px;
  margin-bottom: 0px;
  color: #ec3894;
  font-size: 53px;
  text-align: left;
  font-weight: bolder;
}
.background5 {
  background-image: url("~@/assets//images/login.png");
  background-repeat: no-repeat;
  background-size: 50%;
  height: 100vh;
  background-position: top right;
}
.form-signin .order-one {
  margin-top: 40px;
}
.form-signin .login {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}
.form-signin .text-content {
  margin-bottom: 8px;
  padding-top: 1rem;
  text-align: left;
}
.form-signin .blank-space {
  max-width: 360px;
  width: 100%;
  border-radius: 5px;
  outline: none;
  border: 1px solid #ec3894;
}
.form-signin .form-text {
  color: #2d2d2d;
  font-weight: 500;
  font-size: 12px !important;
  margin-top: 24px;
}
.form-signin .flexbox {
  display: flex;
  flex-direction: row;
}
.form-signin .checkbox {
  margin: 24px 0px 0px 11px;
}
.form-signin .forgot-p {
  margin: 24px 0px 0px 105px;
  color: #2d2d2d;
  font-weight: 500;
  font-size: 12px !important;
  text-decoration: underline;
}
#form-button,
#reset-p-form-button {
  color: #fff;
  background-color: #472667;
  border-radius: 5px;
  max-width: 280px;
  width: 100%;
  outline: none;
  border: none;
  height: 40px;
  margin: 24px 0px 0px 0px;
}
.form-signin .order-two {
  display: none;
}
.form-signin p {
  font-size: 16px !important;
}
@media screen and (max-width: 769px) {
  .form-signin .order-one {
    order: 1;
    margin-top: unset;
  }
  .form-signin .hide-banner {
    order: 2;
    display: block;
    margin-top: 30px;
  }
  .background5 {
    background-image: none;
    min-height: 120vh;
  }
}
@media (max-width: 426px) {
  .form-signin .row {
    margin-inline: -1%;
  }
  .form-signin .blank-space {
    max-width: unset;
  }
  #form-button,
  #reset-p-form-button {
    max-width: unset;
    margin: 24px 0px 0px 0px;
  }
  .form-signin .login {
    width: 100% !important;
  }
}

@media screen and (min-width: 1921px) {
  .background5 {
    background-image: none;
    height: auto;
  }
  .form-signin .order-two {
    display: block;
  }
  .form-signin .hide-banner {
    display: none;
  }
}
</style>

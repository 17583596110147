<template>
  <div class="container mt-5">
    <TitleBar></TitleBar>
    <div class="text-danger" v-if="errorMsg != ''">
      {{ errorMsg }}
    </div>
    <div class="text-danger" v-if="invalidReset">Invalid password reset</div>
    <div class="text-danger" v-if="pleaseWait">Please wait...</div>
    <form @submit.prevent="submit">
      <div class="row g-3 pddtop20">
        <div class="col-sm-12">
          <div class="col-sm-5">
            <!-- <div class="form-floating"> -->
            <input
              type="password"
              class="form-control"
              id="currentPassword"
              placeholder="Current Password"
              v-model="formData.currentPassword"
            />
            <!-- <label for="floatingPassword">Current Password</label> -->
            <span
              class="text-danger"
              v-for="error in v$.currentPassword.$errors"
              :key="error.$uid"
              >{{ error.$message }}</span
            >
          </div>
        </div>
        <div class="col-sm-12">
          <div class="col-sm-5">
            <input
              type="password"
              class="form-control"
              id="newPassword"
              placeholder="New Password"
              v-model="formData.newPassword"
            />
            <!-- <label for="floatingPassword">New Password</label> -->
            <span
              class="text-danger"
              v-for="error in v$.newPassword.$errors"
              :key="error.$uid"
              >{{ error.$message }}</span
            >
          </div>
        </div>
        <div class="col-sm-12">
          <div class="col-sm-5">
            <input
              type="password"
              class="form-control"
              id="confirmPassword"
              placeholder="Confirmed Password"
              v-model="formData.confirmPassword"
            />
            <!-- <label for="floatingPassword">Confirm Password</label> -->
            <span
              class="text-danger"
              v-for="error in v$.confirmPassword.$errors"
              :key="error.$uid"
              >{{ error.$message }}</span
            >
          </div>
        </div>
      </div>
      <div class="button-section">
        <router-link class="btn btn-secondary mrgrbtn" to="/dashboard"
          >Cancel</router-link
        >
        <button
          class="btn btn-primary"
          type="submit"
          :disabled="btnDisabled || sending"
        >
          {{ sending ? "Submitting..." : "Submit" }}
        </button>
      </div>
    </form>
  </div>
</template>
<script lang="ts">
import { computed, ref, onMounted } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers, sameAs, not } from "@vuelidate/validators";
import { reactive } from "@vue/reactivity";
import { useRouter } from "vue-router";
import axios from "axios";
import { useStore } from "vuex";
import TitleBar from "@/components/TitleBar.vue";
export default {
  name: "ResetPassword",
  setup() {
    const router = useRouter();
    const store = useStore();
    const invalidReset = ref(false);
    const pleaseWait = ref(false);
    const sending = ref(false);

    const submit = async () => {
      if (sending.value) return;
      sending.value = true;

      const result = await v$.value.$validate();

      if (result) {
        invalidReset.value = false;
        pleaseWait.value = true;
        const token = store.state.token;
        await axios
          .post(process.env.VUE_APP_API_URL + "User/reset-password", formData, {
            headers: {
              Authorization: "bearer " + token,
              "Access-Control-Allow-Origin": "*",
            },
          })
          .then((res) => {
            invalidReset.value = false;
            pleaseWait.value = false;
            sending.value = false;
            router.push("/dashboard");
          })
          .catch((err) => {
            invalidReset.value = true;
            pleaseWait.value = false;
            sending.value = false;
          });
      }
    };

    const formData = reactive({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });

    const rules = {
      currentPassword: {
        required: helpers.withMessage(
          "Please enter current password",
          required
        ),
      },
      newPassword: {
        required: helpers.withMessage("Please enter new password", required),
        notSameAs: helpers.withMessage(
          "New password must be different from current password",
          not(sameAs(computed(() => formData.currentPassword)))
        ),
      },
      confirmPassword: {
        required: helpers.withMessage(
          "Please enter new password confirmation",
          required
        ),
        sameAs: helpers.withMessage(
          "Confirm password must be same as new password",
          sameAs(computed(() => formData.newPassword))
        ),
      },
    };

    const v$ = useVuelidate(rules, formData);

    return { v$, submit, formData, invalidReset, pleaseWait, sending };
  },
  data() {
    return {
      btnDisabled: ref(false),
      btnText: ref("Update Password"),
    };
  },

  components: {
    TitleBar,
  },
};
</script>
<style>
.form-resetpw {
  max-width: 330px;
  padding: 15px;
}

.form-resetpw .form-floating:focus-within {
  z-index: 2;
}

.form-resetpw input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>

<template>
  <TitleBar></TitleBar>
  <div class="text-center" v-if="recordData.loading == true">
    <img :src="require('@/assets/images/loading.gif')" />
  </div>

  <div v-if="recordData.loading == false">
    <div class="mb-3 row">
      <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label"
        >Name</label
      >
      <div class="col-sm-9">
        <input
          type="text"
          readonly
          class="form-control-plaintext detail-page-input"
          v-model="recordData.FirstName"
        />
      </div>
    </div>
    <div class="mb-3 row">
      <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label"
        >Company</label
      >
      <div class="col-sm-9">
        <input
          type="text"
          readonly
          class="form-control-plaintext detail-page-input"
          v-model="recordData.CompanyName"
        />
      </div>
    </div>
    <div class="mb-3 row">
      <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label"
        >Bank</label
      >
      <div class="col-sm-9">
        <input
          type="text"
          readonly
          class="form-control-plaintext detail-page-input"
          v-model="recordData.BankName"
        />
      </div>
    </div>
    <div class="mb-3 row">
      <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label"
        >Bank Account Number</label
      >
      <div class="col-sm-9">
        <input
          type="text"
          readonly
          class="form-control-plaintext detail-page-input"
          v-model="recordData.BankAccountNumber"
        />
      </div>
    </div>

    <div class="mb-3 row">
      <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label"
        >Total Amount Requested</label
      >
      <div class="col-sm-9">
        <input
          type="text"
          readonly
          class="form-control-plaintext detail-page-input"
          v-model="recordData.TotalAmountRequested"
        />
      </div>
    </div>
    <div class="mb-3 row">
      <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label"
        >Transaction Fees</label
      >
      <div class="col-sm-9">
        <input
          type="text"
          readonly
          class="form-control-plaintext detail-page-input"
          v-model="recordData.TransactionFees"
        />
      </div>
    </div>

    <div class="mb-3 row">
      <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label"
        >Nett Amount Transfer</label
      >
      <div class="col-sm-9">
        <input
          type="text"
          readonly
          class="form-control-plaintext detail-page-input"
          v-model="recordData.NettAmountToTransfer"
        />
      </div>
    </div>
    <div class="mb-3 row">
      <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label"
        >Requested At</label
      >
      <div class="col-sm-9">
        <input
          type="text"
          readonly
          class="form-control-plaintext detail-page-input"
          v-model="recordData.RequestedAt"
        />
      </div>
    </div>
    <div class="mb-3 row">
      <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label"
        >Status</label
      >
      <div class="col-sm-9">
        <input
          type="text"
          readonly
          class="form-control-plaintext detail-page-input"
          v-model="recordData.StatusString"
        />
      </div>
    </div>

    <template v-if="recordData.details.isCurlec">
      <div class="mb-3 row">
        <label
          for="staticEmail"
          class="col-sm-3 col-form-label detail-page-label"
          >Curlec Reference</label
        >
        <div class="col-sm-9">
          <p class="form-control-plaintext detail-page-input">
            {{ recordData.details.curlecReference }}
          </p>
        </div>
      </div>
      <div class="mb-3 row">
        <label
          for="staticEmail"
          class="col-sm-3 col-form-label detail-page-label"
          >Curlec Recipient Reference</label
        >
        <div class="col-sm-9">
          <p class="form-control-plaintext detail-page-input">
            {{ recordData.details.curlecRecipientReference }}
          </p>
        </div>
      </div>
      <div class="mb-3 row">
        <label
          for="staticEmail"
          class="col-sm-3 col-form-label detail-page-label"
          >Curlec Status</label
        >
        <div class="col-sm-9">
          <p
            class="form-control-plaintext detail-page-input"
            v-if="recordData.details.curlecStatus === 0"
          >
            Pending
          </p>
          <p
            class="form-control-plaintext detail-page-input"
            v-if="recordData.details.curlecStatus === 1"
          >
            Approved
          </p>
          <p
            class="form-control-plaintext detail-page-input"
            v-if="recordData.details.curlecStatus === 2"
          >
            Rejected
          </p>
        </div>
      </div>
      <div class="mb-3 row">
        <label
          for="staticEmail"
          class="col-sm-3 col-form-label detail-page-label"
          >Curlec Remark</label
        >
        <div class="col-sm-9" v-if="recordData.details.curlecStatus !== 1">
          <p class="form-control-plaintext detail-page-input">
            {{ recordData.curlecTransactionStatus.message }}
          </p>
        </div>
      </div>
    </template>

    <div class="pddtop30">
      <button class="btn btn-secondary" @click="$router.back()">Back</button>
      <button
        class="btn btn-danger ms-4"
        @click="openRejectModal"
        v-if="recordData.Status != 2"
      >
        Reject
      </button>
      <button
        class="btn btn-success ms-4"
        @click="openModal"
        v-if="recordData.Status === 0"
      >
        Approve
      </button>
    </div>
    <div
      class="modal"
      id="approveModal"
      ref="approveModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-danger" id="exampleModalLabel">
              Approve Withdrawal
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form @submit.prevent="submit">
            <div class="modal-body">
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label"
                  >Transaction ID</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="TransactionId"
                  name="TransactionId"
                  v-model="formData.TransactionId"
                />
                <span
                  class="text-danger"
                  v-for="error in v$.TransactionId.$errors"
                  :key="error.$uid"
                  >{{ error.$message }}</span
                >
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary mrgrbtn"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="submit" class="btn btn-primary">Approve</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, inject } from "vue";
import store from "@/store";
import axios from "axios";
import { useRoute } from "vue-router";
import { reactive } from "@vue/reactivity";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { computed } from "@vue/runtime-core";
import { useToast } from "vue-toastification";
import TitleBar from "@/components/TitleBar.vue";
import moment from "moment";

declare var bootstrap;

export default defineComponent({
  name: "AdminWithdrawalDetails",
  components: {
    TitleBar,
  },
  setup() {
    const swal = inject("$swal") as any;
    const route = useRoute();
    const showModal = ref(false);
    const toast = useToast();
    const formData = reactive({
      Status: 0,
      TransactionId: "",
      withdrawalRecordId: 0,
    });

    const recordData = reactive({
      Id: ref(0),
      UpdateStatusValue: ref(0),
      FirstName: "",
      LastName: "",
      CompanyName: "",
      BankAccountNumber: "",
      BankName: "",
      TransactionFees: "",
      TotalAmountRequested: "",
      NettAmountToTransfer: "",
      StatusString: "",
      Status: 0,
      RequestedAt: "",
      TransactionId: "",
      TransactionCreatedAt: "",
      loading: true,
      details: {} as any,
      curlecTransactionStatus: {} as any,
    });

    const approveModal = ref(null);

    const UpdateStatus = async () => {
      const token = store.state.token;
      const response = await axios
        .post(
          process.env.VUE_APP_API_URL + "withdrawal/update-status",
          formData,
          {
            headers: {
              Authorization: "bearer " + token,
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          approveModal.value?.hide();
          toast.success("Status Updated", {
            timeout: 3000,
          });

          WithdrawalDetails();
        })
        .catch((err) => {
          //
        });
    };

    const submit = async () => {
      const result = await v$.value.$validate();
      if (result) {
        swal({
          title: "Confirm to approve?",
          icon: "info",
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        }).then((result) => {
          if (result.isConfirmed) {
            formData.withdrawalRecordId = parseInt(route.params.id as string);
            formData.Status = 1;
            UpdateStatus();
          }
        });
      }
    };

    const curlecStatusDetail = async () => {
      try {
        const id = route.params.id;

        const token = store.state.token;
        const response = await axios.get(
          process.env.VUE_APP_API_URL + "Withdrawal/curlec-transaction-status/" + id,
          {
            headers: {
              Authorization: "bearer " + token,
            },
          }
        );

        recordData.curlecTransactionStatus = response.data;
      } catch (error) {
        //
      }
    };

    const WithdrawalDetails = async () => {
      const id = route.params.id;

      const token = store.state.token;
      const response = await axios
        .get(process.env.VUE_APP_API_URL + "withdrawal/" + id + "/details", {
          headers: {
            Authorization: "bearer " + token,
          },
        })
        .then((res) => {
          recordData.details = res.data;
          recordData.Id = res.data.id;
          recordData.FirstName = res.data.firstName;
          recordData.LastName = res.data.lastName;
          recordData.CompanyName = res.data.companyName;
          recordData.BankAccountNumber = res.data.bankAccountNumber;
          recordData.BankName = res.data.bankName;
          recordData.TransactionFees = res.data.transactionFees
            ? parseFloat(res.data.transactionFees).toFixed(2).toLocaleString()
            : "0";
          recordData.TotalAmountRequested = res.data.totalAmountRequested
            ? parseFloat(res.data.totalAmountRequested)
                .toFixed(2)
                .toLocaleString()
            : "0";
          recordData.NettAmountToTransfer = res.data.nettAmountToTransfer
            ? parseFloat(res.data.nettAmountToTransfer)
                .toFixed(2)
                .toLocaleString()
            : "0";
          recordData.RequestedAt = moment(res.data.requestedAt).format(
            "DD MMMM YYYY, h:mm A"
          );
          recordData.StatusString = res.data.statusString;
          recordData.Status = res.data.status;
        })
        .catch((err) => {
          //
        });
      recordData.loading = false;
      if (recordData.details.isCurlec) {
        curlecStatusDetail();
      }
    };

    const openModal = () => {
      var myModal = new bootstrap.Modal(
        document.getElementById("approveModal")
      );
      myModal.show();
      approveModal.value = myModal;
    };
    const openRejectModal = () => {
      swal({
        title: "Confirm to reject?",
        icon: "error",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          formData.withdrawalRecordId = parseInt(route.params.id as string);
          formData.Status = 2;
          UpdateStatus();
        }
      });
    };
    const rules = computed(() => {
      return {
        TransactionId: {
          required: helpers.withMessage(
            "Please enter Transaction ID",
            required
          ),
        },
      };
    });
    const v$ = useVuelidate(rules, formData);

    return {
      v$,
      formData,
      recordData,
      submit,
      WithdrawalDetails,
      showModal,
      openModal,
      openRejectModal,
    };
  },
  data() {
    return {
      errorMsg: ref(""),
    };
  },

  mounted() {
    this.WithdrawalDetails();
  },
});
</script>
<style scoped>
.btn-danger {
  width: 150px;
  border-radius: 25px;
  font-size: 18px;
  padding: 6px 12px;
}
</style>

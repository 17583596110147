<template>
  <div>
    <TitleBar/>

    <button class="btn btn-primary btn-sm btn-list btn-list-3" @click="createArticle">Create New Article</button>

    <table class="table table-list">
      <thead>
        <tr>
          <th>No</th>
          <th>Title</th>
          <th>Author</th>
          <th>Created At</th>
          <th>Created by</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in articles" :key="index" @click="viewDetails(row.id)">
          <td>{{ row.id }}</td>
          <td>{{ row.title }}</td>
          <td>{{ row.author }}</td>
          <td>{{ formatCreatedAt(row.createdAt) }}</td>
          <td>{{ row.createdBy }}</td>
          <td>{{ getStatusLabel(row.status) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import TitleBar from '@/components/TitleBar.vue';

const router = useRouter();
const articles = ref([]);

onMounted(() => {
  fetchArticles();
});

const fetchArticles = async () => {
  try {
    const response = await axios.get(process.env.VUE_APP_API_URL + 'Article');
    articles.value = response.data;
  } catch (error) {
    console.error('Error fetching articles:', error);
  }
};

const viewDetails = (id) => {
  // Navigate to the details page using the router
  router.push({ name: 'article-details', params: { id } });
};

const createArticle = () => {
  // Navigate to the create article page using the router
  router.push({ name: 'create-article' });
};

const getStatusLabel = (status) => (status === 1 ? 'Active' : 'Inactive');

const formatCreatedAt = (createdAt) => {
  const date = new Date(createdAt);
  return date.toLocaleString(); 
}
</script>

<style scoped>
tr:hover {
  background-color: #ddd;

}

table {
  margin-top: 20px;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  cursor: pointer;
}
button:hover {
  background-color: #0056b3;
}
</style>

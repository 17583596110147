import axios from "axios";
import { ref } from "vue";
import { useStore } from "vuex";

export function useInsurance() {
  const store = useStore();
  const insuranceListLoading = ref(true);
  const insuranceList = ref([]);

  async function fetchInsuranceList() {
    try {
      const token = store.state.token;
      const response = await axios.get(
        process.env.VUE_APP_API_URL + "Insurance/list",
        {
          headers: {
            Authorization: "bearer " + token,
          },
        }
      );
      insuranceList.value = response.data;
    } catch (error) {
      //
    }
  }

  fetchInsuranceList();

  return { insuranceList, fetchInsuranceList, insuranceListLoading };
}

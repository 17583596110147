import { createStore } from "vuex";
import axios from "axios";
import moment from "moment";
const mulaaa_login_token = "mulaaJwt";
const mulaaa_token_expired = "tokenExpiration";
export default createStore({
  state: {
    token: JSON.parse(localStorage.getItem(mulaaa_login_token)!),
    role: "visitor",
    userdetail: "",
    tokenExpiration: localStorage.getItem(mulaaa_token_expired)! as
      | moment.Moment
      | string
      | null,
    tokenExpirationIntervalCheck: null,
  },
  getters: {
    isAuthenticated(state) {
      if (!state.token && !state.tokenExpiration) {
        return false;
      }

      try {
        const datenow = moment();
        const payload = state.token;
        const expiresIn = JSON.parse(atob((payload ?? "").split(".")[1])).exp;
        const tokenExpiration1 = moment(expiresIn * 1000);

        state.tokenExpiration = tokenExpiration1;
        localStorage.setItem(
          mulaaa_token_expired,
          state.tokenExpiration.toString()
        );

        if (moment(tokenExpiration1).isSameOrBefore(datenow)) {
          state.token = null;

          localStorage.removeItem(mulaaa_token_expired);
          localStorage.removeItem(mulaaa_login_token);
          localStorage.removeItem("rememberMe");
          state.role = "visitor";
          state.userdetail = null;
          state.token = null;
          state.tokenExpiration = "";
        }
        return moment(tokenExpiration1).isAfter(datenow);
      } catch (_) {
        // ..
      }
      return false;
    },
    token(state) {
      return state.token;
    },
    role(state) {
      return state.role;
    },
    userdetail(state) {
      return state.userdetail;
    },
  },
  mutations: {
    setToken(state, payload) {
      try {
        state.token = payload;
        localStorage.setItem(mulaaa_login_token, JSON.stringify(payload));
        const expiresIn = JSON.parse(atob((payload ?? "").split(".")[1])).exp;
        state.tokenExpiration = moment(expiresIn * 1000).toString();
        localStorage.setItem(mulaaa_token_expired, state.tokenExpiration);
      } catch (_) {
        //
      }
    },
    clearToken(state) {
      clearTimeout(state.tokenExpirationIntervalCheck);
      state.tokenExpirationIntervalCheck = null;
      state.token = null;
      localStorage.removeItem(mulaaa_token_expired);
      localStorage.removeItem(mulaaa_login_token);
      localStorage.removeItem("rememberMe");
      state.role = "visitor";
      state.userdetail = null;
      state.token = null;
      state.tokenExpiration = "";
    },
    setRole(state, payload) {
      state.role = payload;
    },
    setUserdetail(state, payload) {
      state.userdetail = payload;
    },
  },
  actions: {
    async setRole({ commit, state }) {
      let isRoleRetrieved = false;
      try {
        const response = await axios.get(
          process.env.VUE_APP_API_URL + "User/get-role",
          {
            headers: {
              Authorization: "bearer " + state.token,
            },
          }
        );
        commit("setRole", response.data);
        isRoleRetrieved = true;

        clearTimeout(state.tokenExpirationIntervalCheck);
        state.tokenExpirationIntervalCheck = setInterval(async () => {
          try {
            const res2 = await axios.post(
              process.env.VUE_APP_API_URL + "Auth/refresh-access-token",
              {},
              {
                headers: {
                  Authorization: "bearer " + state.token,
                },
              }
            );

            commit("setToken", res2.data);
          } catch (_) {
            // ...
          }
        }, 14000);
      } catch (_) {
        commit("setRole", "visitor");
        commit("setToken", null);
        commit("setUserdetail", null);
      }
      return isRoleRetrieved;
    },
    async setUserdetail({ commit, state }) {
      try {
        const response = await axios.get(
          process.env.VUE_APP_API_URL + "User/get-usercompany",
          {
            headers: {
              Authorization: "bearer " + state.token,
            },
          }
        );

        commit("setUserdetail", response.data);
        return response.data;
      } catch (error) {
        commit("setUserdetail", null);
      }
    },
  },
  modules: {},
});

<template>
  <div class="container">
    <div class="row mt-5 title-section">
      <div class="col"><h3 class="pull-left">Configuration List</h3><span class="title-border-b" /></div>
      <div class="col text-end">
        <router-link
          to="/admin/configuration/add"
          class="btn btn-primary btn-sm me-2"
          ><i class="fa fa-add"></i> Add New Configuration</router-link
        >
      </div>
    </div>
    <div class="text-center" v-if="loading == true">
      <img :src="require('@/assets/images/loading.gif')" />
    </div>
    <Pagination
      :totalItem="configurations.length"
      :items="configurations"
      :perPage="50"
    >
      <template #data="{ paginatedItems, currentIndex }">
        <table class="table" v-if="loading == false">
          <thead>
            <tr>
              <th scope="col">No</th>
              <th
                scope="col"
                class="pointer sort-arrow"
                :class="
                  currentSort == 'companyName'
                    ? currentSortDir == 'desc'
                      ? 'sort-arrow-desc'
                      : 'sort-arrow-asc'
                    : ''
                "
                @click="onSort('companyName')"
              >
                Company Name
              </th>
              <th
                scope="col"
                class="pointer sort-arrow"
                :class="
                  currentSort == 'minPerTransaction'
                    ? currentSortDir == 'desc'
                      ? 'sort-arrow-desc'
                      : 'sort-arrow-asc'
                    : ''
                "
                @click="onSort('minPerTransaction')"
              >
                Min Per Transaction
              </th>
              <th
                scope="col"
                class="pointer sort-arrow"
                :class="
                  currentSort == 'maxPerTransaction'
                    ? currentSortDir == 'desc'
                      ? 'sort-arrow-desc'
                      : 'sort-arrow-asc'
                    : ''
                "
                @click="onSort('maxPerTransaction')"
              >
                Max Per Transaction
              </th>
              <th
                scope="col"
                class="pointer sort-arrow"
                :class="
                  currentSort == 'maxPerMonth'
                    ? currentSortDir == 'desc'
                      ? 'sort-arrow-desc'
                      : 'sort-arrow-asc'
                    : ''
                "
                @click="onSort('maxPerMonth')"
              >
                Max Per Month
              </th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(configuration, index) in paginatedItems"
              :key="configuration.id"
            >
              <th scope="row">{{ index + currentIndex }}</th>
              <td>{{ configuration.companyName }}</td>
              <td>{{ configuration.minPerTransaction }}</td>
              <td>{{ configuration.maxPerTransaction }}</td>
              <td>{{ configuration.maxPerMonth }}</td>
              <td>
                <router-link
                  :to="{
                    name: 'admin-configuration-details',
                    params: {
                      id: configuration.id,
                    },
                  }"
                  ><img
                  class="edit-btn-img"
                  :src="require('@/assets/images/edit-btn.svg')"
                /></router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </Pagination>
  </div>
</template>
<script lang="ts">
import store from "@/store";
import axios from "axios";
import Pagination from "@/components/Pagination.vue";
import {
  computed,
  getTransitionRawChildren,
  onMounted,
  ref,
  watch,
  defineComponent,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import moment from "moment";
export default defineComponent({
  name: "AdminConfigurationList",
  setup() {
    const store = useStore();

    const role = computed(() => {
      return store.state.role;
    });

    return { role };
  },
  components: {
    Pagination,
  },
  data() {
    return {
      configurations: ref([]),
      count: 1,
      loading: true,
      moment: moment,
      currentSort: "name",
      currentSortDir: "asc",
    };
  },
  methods: {
    onSort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;

      this.configurations = this.configurations.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
    async configurationList() {
      const token = store.state.token;
      const response = await axios.get(
        process.env.VUE_APP_API_URL + "Configuration/list",
        {
          headers: {
            Authorization: "bearer " + token,
          },
        }
      );
      this.loading = false;
      this.configurations = response.data;
    },
  },
  mounted() {
    this.configurationList();
  },
});
</script>
<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th>Min Salary</th>
          <th>Max Salary</th>
          <th>Payout</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in rows" :key="index">
          <td>
            <input
              v-model="row.firstField"
              type="number"
              :min="getMinValue(index, 'firstField')"
              @change="updateFields(index, 'firstField')"
              required
              class="form-control"
            />
          </td>
          <td>
            <input
              v-model="row.secondField"
              type="number"
              :min="getMinValue(index, 'secondField')"
              @change="updateFields(index, 'secondField')"
              required
              class="form-control"
            />
          </td>
          <td>
            <input
              v-model="row.thirdField"
              type="number"
              :min="getMinValue(index, 'thirdField')"
              required
              class="form-control"
            />
          </td>
          <td>
            <button
              @click="removeRow(index)"
              :disabled="rows.length === 1"
              class="btn btn-danger"
            >
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <button @click="addRow" class="btn btn-primary">Add Row</button>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";

export default {
  props: ["WRDataArray"],

  setup(props, { emit }) {
    const rows = ref(props.WRDataArray);

    onMounted(() => {
      emit("data-updated", rows.value);
    });

    const addRow = () => {
      const lastRow = rows.value[rows.value.length - 1];
      const firstField = lastRow.secondField + 1;
      const secondField = firstField + 1;
      const newRow = {
        firstField,
        secondField,
        thirdField: null,
      };

      // Insert the new row at the correct position to maintain the sequence
      let insertIndex = rows.value.length;

      for (let i = rows.value.length - 1; i >= 0; i--) {
        const currentRow = rows.value[i];

        if (currentRow.secondField > secondField) {
          insertIndex = i + 1;
          break;
        }
      }

      rows.value.splice(insertIndex, 0, newRow);

      // Update the fields for all rows
      updateFields();
    };

    const removeRow = (index) => {
      rows.value.splice(index, 1);

      // Update the fields for all rows
      updateFields();
    };

    const updateFields = (index, field) => {
      const row = rows.value[index];

      for (let i = index; i < rows.value.length; i++) {
        const currentRow = rows.value[i];
        const prevRow = rows.value[i - 1];

        if (i === index) {
          // Update the current field based on the given index and field type
          if (field === "firstField") {
            currentRow.firstField = row.firstField;
            currentRow.secondField = Math.max(
              row.secondField,
              row.firstField + 1
            );
          } else if (field === "secondField") {
            currentRow.secondField = row.secondField;
            currentRow.firstField = Math.min(
              row.firstField,
              row.secondField - 1
            );
          }
        } else {
          // Update the current field based on the previous row's field values
          if (currentRow.firstField <= prevRow.secondField) {
            currentRow.firstField = prevRow.secondField + 1;
            currentRow.secondField = currentRow.firstField + 1;
          } else {
            currentRow.secondField = Math.max(
              currentRow.firstField + 1,
              currentRow.secondField
            );
          }
        }
      }

      // Check if the changes affect the previous rows
      for (let i = index - 1; i >= 0; i--) {
        const currentRow = rows.value[i];
        const nextRow = rows.value[i + 1];

        if (currentRow.secondField >= nextRow.firstField) {
          currentRow.secondField = nextRow.firstField - 1;
          currentRow.firstField = currentRow.secondField - 1;
        } else {
          currentRow.firstField = Math.min(
            currentRow.firstField,
            currentRow.secondField - 1
          );
        }
      }

      emit("data-updated", rows.value);
    };
    const getMinValue = (index, field) => {
      if (index === 0) {
        return 0;
      }
      const prevRow = rows.value[index - 1];
      return prevRow[field];
    };

    return {
      rows,
      addRow,
      removeRow,
      updateFields,
      getMinValue,
    };
  },
};
</script>

<style>
.table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.form-control {
  width: 100%;
  padding: 6px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.btn {
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
  border: none;
}

.btn-danger {
  background-color: #dc3545;
  color: #fff;
  border: none;
}
</style>
<template>
  <div>Home</div>
</template>

<script lang="ts">

export default {
  name: "Home",
 
};
</script>
<template>
  <div class="container">
    <TitleBar></TitleBar>

    <div class="text-center" v-if="loading">
      <img :src="require('@/assets/images/loading.gif')" />
    </div>
    <template v-if="!loading">
      <div class="d-flex gap-2 flex-column flex-md-row mb-2">
        <div class="d-flex gap-2 flex-wrap" style="flex: 1">
          <Datepicker :format="formatDateToDDMMYYYY" v-model="filterValue.startDate" :enable-time-picker="false"
            placeholder="From" :auto-apply="true" :close-on-auto-apply="true" class="calender-picker-field"><template
              #input-icon>
              <img :src="require('@/assets/images/Group-714.svg')" alt="" class="calander-logo" /> </template>
          </Datepicker>
          <Datepicker :format="formatDateToDDMMYYYY" v-model="filterValue.endDate" :enable-time-picker="false"
            placeholder="To" :auto-apply="true" :close-on-auto-apply="true" class="calender-picker-field"><template
              #input-icon>
              <img :src="require('@/assets/images/Group-714.svg')" alt="" class="calander-logo" /> </template>
          </Datepicker>
          <select class="form-control max-240" placeholder="Please Select" v-model="filterValue.status">
            <template v-for="c in recordStatuses" :key="c.text">
              <option :value="c.value">
                {{ c.text }}
              </option>
            </template>
          </select>
          <select class="form-control max-240" placeholder="Please Select" v-model="filterValue.companyId">
            <option :value="null">Please Select</option>
            <option v-for="c in companies" :key="c.id" v-bind:value="c.id">
              {{ c.companyName }}
            </option>
          </select>
          <select class="form-control max-240" placeholder="" v-model="filterValue.insuranceType">
            <option :value="null">Please Select</option>
            <template v-for="c in insuranceList" :key="c.insuranceType">
              <option v-bind:value="c.insuranceType" v-if="c.insuranceType !== 0">
                {{ c.insuranceName }}
              </option>
            </template>
          </select>

          <div class="page-title-s">
            <div class="search-box">
              <i class="fa fa-search"></i>
            </div>
            <input type="text" placeholder="Search" />
          </div>
        </div>

        <div class="d-flex gap-2">
          <button class="btn btn-primary btn-sm btn-list btn-list-3" style="height: fit-content" @click="filterData">
            <i class="fa fa-search"></i> Filter
          </button>
          <DownloadButton :url="downloadUrl" :file-name="downloadFileName">
            <div class="d-flex flex-column gap-3 align-items-center">
              <div class="w-100">
                <label class="form-label" for="status">Status</label>
                <select class="form-control w-100" id="status" placeholder="Please Select" v-model="filterValue.status">
                  <template v-for="c in recordStatuses" :key="c.text">
                    <option :value="c.value">
                      {{ c.text }}
                    </option>
                  </template>
                </select>
              </div>
              <div class="w-100">
                <label class="form-label" for="companyId">Company</label>
                <select class="form-control" id="companyId" placeholder="Please Select" v-model="filterValue.companyId">
                  <option :value="null">Please Select</option>
                  <option v-for="c in companies" :key="c.id" v-bind:value="c.id">
                    {{ c.companyName }}
                  </option>
                </select>
              </div>

              <div class="w-100">
                <label class="form-label" for="insuranceType">Company</label>
                <select class="form-control" id="insuranceType" placeholder="Please Select"
                  v-model="filterValue.insuranceType">
                  <option :value="null">Please Select</option>
                  <template v-for="c in insuranceList" :key="c.insuranceType">
                    <option v-bind:value="c.insuranceType" v-if="c.insuranceType !== 0">
                      {{ c.insuranceName }}
                    </option>
                  </template>
                </select>
              </div>
            </div>
          </DownloadButton>
        </div>
      </div>
      <Pagination :totalItem="insuranceEnrolmentList.length" :items="insuranceEnrolmentList" :perPage="50">
        <template #data="{ paginatedItems, currentIndex }">
          <table class="table table-list">
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col" class="pointer" @click="onInsuranceEnrolmentSort('fullName')">
                  <span class="sort-arrow" :class="insuranceEnrolmentSortField == 'fullName'
                      ? insuranceEnrolmentSortOrder == 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                    ">Employee Name</span>
                </th>
                <th scope="col" v-if="role == 'admin'" class="pointer" @click="onInsuranceEnrolmentSort('companyName')">
                  <span class="sort-arrow" :class="insuranceEnrolmentSortField == 'companyName'
                      ? insuranceEnrolmentSortOrder == 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                    ">Company</span>
                </th>
                <th scope="col" class="pointer" @click="onInsuranceEnrolmentSort('createdAt')">
                  <span class="sort-arrow" :class="insuranceEnrolmentSortField == 'createdAt'
                      ? insuranceEnrolmentSortOrder == 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                    ">Enrolled Date</span>
                </th>
                <th scope="col" class="pointer" @click="onInsuranceEnrolmentSort('email')">
                  <span class="sort-arrow" :class="insuranceEnrolmentSortField == 'email'
                      ? insuranceEnrolmentSortOrder == 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                    ">Email</span>
                </th>
                <th scope="col" class="pointer" @click="onInsuranceEnrolmentSort('statusString')">
                  <span class="sort-arrow" :class="insuranceEnrolmentSortField == 'statusString'
                      ? insuranceEnrolmentSortOrder == 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                    ">Status</span>
                </th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(insuranceEnrolment, index) in paginatedItems" :key="insuranceEnrolment.id">
                <td scope="row">{{ index + currentIndex }}</td>
                <td>{{ insuranceEnrolment.fullName }}</td>
                <td v-if="role == 'admin'">
                  {{ insuranceEnrolment.companyName }}
                </td>
                <td>
                  {{
                    moment(insuranceEnrolment.createdAt).format("DD MMM YYYY, h:mm A")
                  }}
                </td>
                <td>{{ insuranceEnrolment.email }}</td>
                <td>{{ insuranceEnrolment.statusString }}</td>
                <td>
                  <router-link :to="{
                    name: 'admin-ezpaenrolment-details',
                    params: {
                      id: insuranceEnrolment.id,
                    },
                  }"><img class="edit-btn-img" :src="require('@/assets/images/edit-btn.svg')" /></router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </Pagination>
    </template>
  </div>
</template>
<script lang="ts" setup>
import axios from "axios";
import Pagination from "@/components/Pagination.vue";
import DownloadButton from "@/components/DownloadButton.vue";
import Datepicker from "@vuepic/vue-datepicker";
import {
  computed,
  reactive,
  ref,
  toRaw,
  toValue,
  watch,
  watchEffect,
} from "vue";
import { useStore } from "vuex";
import _moment from "moment";
import TitleBar from "@/components/TitleBar.vue";
import { useListData } from "@/composables/useListData";
import { useCompany } from "@/composables/useCompany";
import { useInsurance } from "@/composables/useInsurance";
import {
  formatDateToYYYYMMDD,
  formatDateToDDMMYYYY,
  convertDateStringToJSDate,
} from "@/helpers/date-formatter";
import { useRoute, useRouter } from "vue-router";
import { useDateFormat } from "@vueuse/core";

const route = useRoute();
const router = useRouter();

const temp = new Date();
temp.setDate(1);

const filterValue = reactive({
  startDate: route.query.startDate
    ? convertDateStringToJSDate(route.query.startDate)
    : temp,
  endDate: route.query.endDate
    ? convertDateStringToJSDate(route.query.endDate)
    : new Date(),
  status: (route.query.status ?? null) as any,
  companyId: (route.query.companyId ?? null) as string,
  insuranceType: (route.query.insuranceType ?? null) as string,
});
const dataUrl = computed(() => {
  return getUrl(filterValue);
});
const downloadUrl = computed(() =>
  getUrl(filterValue, "Insurance/download-list")
);
const downloadFileName = computed(() => {
  const startDate = useDateFormat(filterValue.startDate, "DDMMYYYY");
  const endDate = useDateFormat(filterValue.endDate, "DDMMYYYY");
  return "insurance_record_" + startDate.value + endDate.value + ".xlsx";
});

function getUrl(value, endpoint = "Insurance/record/list") {
  const baseUrl = process.env.VUE_APP_API_URL + endpoint;
  const url = new URL(baseUrl);

  if (value.startDate) {
    url.searchParams.append("StartDate", formatDateToYYYYMMDD(value.startDate));
  }

  if (value.endDate) {
    url.searchParams.append("EndDate", formatDateToYYYYMMDD(value.endDate));
  }

  if (value.companyId) {
    url.searchParams.append("CompanyId", value.companyId);
  }
  if (value.insuranceType) {
    url.searchParams.append("InsuranceType", value.insuranceType);
  }
  if (value.status) {
    url.searchParams.append("Status", value.status);
  }

  return url.toString();
}

const moment = _moment;
const store = useStore();

const { companies } = useCompany();
const { insuranceList } = useInsurance();
const loading = ref(true);
const {
  list: insuranceEnrolmentList,
  onSort: onInsuranceEnrolmentSort,
  sortField: insuranceEnrolmentSortField,
  sortOrder: insuranceEnrolmentSortOrder,
  refresh,
} = useListData({
  loadFunction: loadInsuranceEnrolmentList,
  defaultSortField: "employeeName",
});

watch(
  () => route.fullPath,
  () => {
    filterValue.startDate = route.query.startDate
      ? convertDateStringToJSDate(route.query.startDate)
      : temp;
    filterValue.endDate = route.query.endDate
      ? convertDateStringToJSDate(route.query.endDate)
      : new Date();
    filterValue.status = (route.query.status ?? null) as any;
    filterValue.companyId = (route.query.companyId ?? null) as string;
    filterValue.insuranceType = (route.query.insuranceType ?? null) as string;

    refresh();
  }
);

const recordStatuses = [
  { text: "All", value: null },
  { text: "Active", value: 1 },
  { text: "Pending", value: 0 },
];

const role = computed(() => {
  return store.state.role;
});

async function loadInsuranceEnrolmentList() {
  let result = [];
  try {
    const token = store.state.token;
    const response = await axios.get(dataUrl.value, {
      headers: {
        Authorization: "bearer " + token,
      },
    });
    result = response.data;
  } catch (error) {
    // 
  }
  loading.value = false;
  return result;
}

function filterData() {
  const query: any = {
    startDate: formatDateToYYYYMMDD(filterValue.startDate),
    endDate: formatDateToYYYYMMDD(filterValue.endDate),
  };

  if (filterValue.companyId) {
    query.companyId = filterValue.companyId;
  }
  if (filterValue.insuranceType) {
    query.insuranceType = filterValue.insuranceType;
  }

  if (!(!filterValue.status && filterValue.status != 0)) {
    query.status = filterValue.status;
  }

  router.push({
    name: route.name,
    query,
  });
}
</script>
<style scoped>
.page-title-s {
  width: 100%;
  max-width: 240px;
}

.page-title-s input {
  width: 100%;
}
</style>

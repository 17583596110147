<template>
  <div>
    <TitleBar></TitleBar>
    <div class="text-center" v-if="loading == true">
      <img :src="require('@/assets/images/loading.gif')" />
    </div>

    <template v-if="loading == false">
      <div class="my-4 d-flex gap-2 flex-wrap flex-column flex-md-row">
        <div class="d-flex gap-2 flex-wrap" style="flex: 1">
          <Datepicker v-model="startDate" :enable-time-picker="false" :format="formatDateToDDMMYYYY" :max-date="endDate"
            placeholder="From" :auto-apply="true" :close-on-auto-apply="true" class="calender-picker-field"
            @closed="dateChange"><template #input-icon>
              <img :src="require('@/assets/images/Group-714.svg')" alt="" class="calander-logo" /> </template>
          </Datepicker>
          <Datepicker v-model="endDate" :enable-time-picker="false" :format="formatDateToDDMMYYYY" :min-date="startDate"
            placeholder="To" :auto-apply="true" :close-on-auto-apply="true" class="calender-picker-field"
            @closed="dateChange"><template #input-icon>
              <img :src="require('@/assets/images/Group-714.svg')" alt="" class="calander-logo" /> </template>
          </Datepicker>
          <select v-model="companyId" class="form-control max-240" id="companyId" placeholder="">
            <option value="null">Please select</option>
            <template v-for="c in companies">
              <option :key="c.id" :value="c.id" v-if="c.statusString !== 'Inactive'">
                {{ c.companyName }}
              </option>
            </template>
          </select>
          <select v-model="typeDefault" class="form-control max-240" id="companyId" placeholder="">
            <option :selected="typeDefault === c.id" v-for="c in type" :key="c.id" v-bind:value="c.id">
              {{ c.name }}
            </option>
          </select>

          <div class="page-title-s">
            <div class="search-box" @click="onSearch($event)">
              <i class="fa fa-search"></i>
            </div>
            <input type="text" v-model="search" placeholder="Search" />
          </div>
        </div>
        <div class="d-flex gap-2 flex-wrap">
          <button class="btn btn-primary btn-sm btn-list btn-list-3" style="height: fit-content" @click="getList">
            <i class="fa fa-search"></i> Filter
          </button>
          <button class="btn btn-primary btn-sm btn-list btn-list-3" style="height: fit-content" @click="DownloadPopSA">
            <i class="fa fa-download"></i>
            {{ downloading ? "..." : "Download" }}
          </button>
        </div>
      </div>

      <p v-if="totalamount > 0 && totalNetAmount > 0">
        Total Fees : RM
        {{
          parseFloat(totalamount - totalNetAmount + "")
            .toFixed(2)
            .toLocaleString()
        }}
      </p>
      <Pagination :totalItem="withdrawalList.length" :items="withdrawalList" :perPage="50">
        <template #data="{ paginatedItems, currentIndex }">
          <table class="table table-list" v-if="loading == false">
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col" class="pointer" @click="onSort('fullName')">
                  <span class="sort-arrow" :class="currentSort == 'fullName'
                      ? currentSortDir == 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                    ">Name</span>
                </th>
                <th scope="col" class="pointer" @click="onSort('companyName')">
                  <span class="sort-arrow" :class="currentSort == 'companyName'
                      ? currentSortDir == 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                    ">Company</span>
                </th>
                <th scope="col" class="pointer" @click="onSort('bankName')">
                  <span class="sort-arrow" :class="currentSort == 'bankName'
                      ? currentSortDir == 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                    ">Bank</span>
                </th>

                <th scope="col" class="pointer" @click="onSort('totalAmountRequested')">
                  <span class="sort-arrow" :class="currentSort == 'totalAmountRequested'
                      ? currentSortDir == 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                    ">Amount Requested (RM)</span>
                </th>
                <th scope="col" class="pointer" @click="onSort('nettAmountToTransfer')">
                  <span class="sort-arrow" :class="currentSort == 'nettAmountToTransfer'
                      ? currentSortDir == 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                    ">Nett Amount Transfer (RM)</span>
                </th>
                <th scope="col" class="pointer" @click="onSort('isAutoWithdrawType')">
                  <span class="sort-arrow" :class="currentSort == 'isAutoWithdrawType'
                      ? currentSortDir == 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                    ">Type</span>
                </th>
                <th scope="col" class="pointer" @click="onSort('requestedAt')">
                  <span class="sort-arrow" :class="currentSort == 'requestedAt'
                      ? currentSortDir == 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                    ">Requested At</span>
                </th>
                <th scope="col" class="pointer" @click="onSort('statusString')">
                  <span class="sort-arrow" :class="currentSort == 'statusString'
                      ? currentSortDir == 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                    ">Status</span>
                </th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="
                    font-size: 16px;
                    letter-spacing: 0px;
                    color: #472667;
                    font-weight: 700;
                  ">
                  Total
                </td>
                <td>---</td>
                <td>---</td>
                <td>---</td>
                <td>
                  {{
                    parseFloat(totalamount + "")
                      .toFixed(2)
                      .toLocaleString()
                  }}
                </td>
                <td>
                  {{
                    parseFloat(totalNetAmount + "")
                      .toFixed(2)
                      .toLocaleString()
                  }}
                </td>
                <td>---</td>
                <td>---</td>
                <td>---</td>
                <td>---</td>
              </tr>
              <tr v-for="(withdrawal, index) in paginatedItems" :key="index + currentIndex">
                <td scope="row">{{ index + currentIndex }}</td>
                <td>
                  <router-link :to="{
                    name: 'admin-employee-details',
                    params: {
                      id: withdrawal.userId,
                    },
                  }">
                    {{ withdrawal.fullName }}
                  </router-link>
                </td>
                <td>{{ withdrawal.companyName }}</td>
                <td>{{ withdrawal.bankName }}</td>
                <td>
                  {{
                    parseFloat(withdrawal.totalAmountRequested)
                      .toFixed(2)
                      .toLocaleString()
                  }}
                </td>
                <td>
                  {{
                    parseFloat(withdrawal.nettAmountToTransfer)
                      .toFixed(2)
                      .toLocaleString()
                  }}
                </td>
                <td>
                  {{ withdrawal.isAutoWithdrawType == 1 ? "Auto" : "Manual" }}
                </td>
                <td>
                  {{
                    moment(withdrawal.requestedAt).format("DD MMM YYYY, h:mm A")
                  }}
                </td>
                <td :class="withdrawal.statusString == 'Rejected' ? 'red' : ''">
                  {{ withdrawal.statusString }}
                </td>
                <td>
                  <router-link :to="{
                    name: 'admin-withdrawal-details',
                    params: {
                      id: withdrawal.id,
                    },
                  }"><img class="edit-btn-img" :src="require('@/assets/images/edit-btn.svg')" /></router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </Pagination>
    </template>
  </div>
</template>

<script lang="ts">
import store from "@/store";
import axios from "axios";
import { defineComponent, ref, reactive } from "vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import Pagination from "@/components/Pagination.vue";
import moment from "moment";
import TitleBar from "@/components/TitleBar.vue";
import { useDateParam } from "@/composables/useDateParam";
import {
  formatDateToYYYYMMDD,
  formatDateToDDMMYYYY,
} from "@/helpers/date-formatter";
import { usePasswordVerify } from "@/composables/usePasswordVerify";

export default defineComponent({
  setup() {
    const { startDate, endDate, dateChange } = useDateParam();

    const { verifyPassword } = usePasswordVerify();

    const companyId = ref(null);

    const DownloadFilter = reactive({
      Date1: formatDateToYYYYMMDD(new Date()),
      Date2: formatDateToYYYYMMDD(new Date()),
      companyId: null,
      stringStatus: "0",
      IsAutoGenerate: null,
      Time: "B2",
    });

    return {
      startDate,
      endDate,
      companyId,
      moment: moment,
      DownloadFilter,
      dateChange,
      formatDateToDDMMYYYY,
      verifyPassword
    };
  },
  name: "WithdrawalList",
  data() {
    return {
      currentSort: "name",
      currentSortDir: "asc",
      withdrawalList: ref([]),
      originalWithdrawalList: ref([]),
      loading: true,
      count: 1,
      totalamount: 0,
      totalNetAmount: 0,

      timedefault: "B2",
      time: [
        { id: "B2", name: "Before 2pm (based on current date)" },
        { id: "A2", name: "After 2pm (based on current date)" },
        { id: "All", name: "All" },
      ],
      statusdefault: "0",
      status: [
        { id: "All", name: "All" },
        { id: "0", name: "Pending" },
        { id: "1", name: "Approved" },
      ],
      type: [
        { id: "All", name: "All" },
        { id: "0", name: "Manual" },
        { id: "1", name: "Auto" },
      ],
      typeDefault: "All",
      stringStatus: "All",
      Time: "B2",
      companies: null,
      search: "",
      downloadTypes: [
        { id: "download-default", name: "Default (Maybank Format)" },
        { id: "download-hlbb", name: "HLBB Format" },
        { id: "download-internal", name: "Internal Format" },
      ],
      downloadTypeDefault: "download-default",
      downloading: false,
    };
  },
  components: {
    Datepicker,
    Pagination,
    TitleBar,
  },
  methods: {
    onSort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;

      this.withdrawalList;
      this.withdrawalList.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
    async getList() {
      const dateString1 = formatDateToYYYYMMDD(this.startDate);
      const dateString2 = formatDateToYYYYMMDD(this.endDate)

      this.DownloadFilter.Date1 = dateString1;
      this.DownloadFilter.Date2 = dateString2;
      this.DownloadFilter.companyId = this.companyId;

      const token = store.state.token;
      if (this.typeDefault) {
        try {
          const IsAutoGenerate = parseInt(this.typeDefault);
          this.DownloadFilter.IsAutoGenerate = Number.isNaN(IsAutoGenerate)
            ? 2
            : IsAutoGenerate;
        } catch (error) {
          this.DownloadFilter.IsAutoGenerate = 2;
        }
      }

      const response = await axios.get(
        process.env.VUE_APP_API_URL + "Withdrawal/history",
        {
          params: {
            date1: dateString1,
            date2: dateString2,
            companyId: this.companyId,
            IsAutoGenerate: this.DownloadFilter.IsAutoGenerate,
          },
          headers: {
            Authorization: "bearer " + token,
          },
        }
      );
      this.loading = false;
      // 

      this.withdrawalList = response.data;
      this.originalWithdrawalList = response.data;
      this.totalamount = 0;
      this.totalNetAmount = 0;

      this.withdrawalList.forEach((element) => {
        if (element.statusString === "Rejected") return;

        this.totalamount += element.totalAmountRequested;
        this.totalNetAmount += element.nettAmountToTransfer;
      });
    },
    onSearch(event) {
      if (this.search === "") {
        this.withdrawalList = this.originalWithdrawalList;
      } else {
        this.withdrawalList = this.originalWithdrawalList.filter((p) => {
          return (
            p.fullName.toLowerCase().indexOf(this.search.toLowerCase()) != -1 ||
            (p.companyName ?? "")
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) != -1 ||
            (p.bankName ?? "")
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) != -1
          );
        });
      }
    },
    async companyList() {
      const token = store.state.token;
      const response = await axios.get(
        process.env.VUE_APP_API_URL + "Company/list",
        {
          headers: {
            Authorization: "bearer " + token,
          },
        }
      );
      this.loading = false;
      this.companies = response.data;
    },

    renderSelect({ defaultValue, options, id, label }) {
      return `
          <label for="${id}" class="form-label">${label}</label>
          <select id="${id}" name="Status" class="form-control" value="${defaultValue}">
               ${options.map(
        (s) =>
          `<option value="${s.id}" ${defaultValue === s.id ? "selected" : ""
          }>${s.name}</option>`
      )}
          </select>
      `;
    },

    async DownloadPopSA() {
      if (this.downloading) return;
      await this.$swal({
        html: `
            <form>
              <div class="row g-3 margin-lr-0 left-align">
                <div class="col-sm-12">
                  ${this.renderSelect({
          defaultValue: this.statusdefault,
          options: this.status,
          id: "status",
          label: "Status",
        })}
                </div>
                <div class="col-sm-12">
                  ${this.renderSelect({
          defaultValue: this.timedefault,
          options: this.time,
          id: "time",
          label: "Time",
        })}
                </div>
                <div class="col-sm-12">
                  ${this.renderSelect({
          defaultValue: this.typeDefault,
          options: this.type,
          id: "type",
          label: "Type",
        })}
                </div>
                <div class="col-sm-12">
                  ${this.renderSelect({
          defaultValue: this.downloadTypeDefault,
          options: this.downloadTypes,
          id: "downloadtype",
          label: "Download Type",
        })}
                </div>
              </div>
            </form>
          `,

        showCancelButton: false,
        confirmButtonText: "<i class='fa fa-download'></i> Download ",
        cancelButtonText: "Close",
        showCloseButton: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-secondary me-2",
        },
      }).then(async (result) => {
        if (!result.value || this.downloading) return;

        const status = this.$swal.getHtmlContainer().querySelector("#status");
        const time = this.$swal.getHtmlContainer().querySelector("#time");
        const type = this.$swal.getHtmlContainer().querySelector("#type");
        const downloadtype = this.$swal
          .getHtmlContainer()
          .querySelector("#downloadtype");
        if (status) {
          this.DownloadFilter.stringStatus = status.value;
        }
        if (time) {
          this.DownloadFilter.Time = time.value;
        }
        if (type) {
          try {
            this.DownloadFilter.IsAutoGenerate = parseInt(type.value);
          } catch (error) {
            this.DownloadFilter.IsAutoGenerate = null;
          }
        }
        if (!(await this.verifyPassword())) return

        if (downloadtype.value === "download-hlbb") {
          this.DownloadExcel("withdrawal/download-list/hlbb");
        } else if (downloadtype.value === "download-internal") {
          this.DownloadExcel("withdrawal/download-list/internal");
        } else {
          this.DownloadExcel();
        }
      });
    },
    async DownloadExcel(endpoint = "withdrawal/download-list") {
      this.downloading = true;
      try {
        const token = store.state.token;
        const response = await axios.post(
          process.env.VUE_APP_API_URL + endpoint,
          this.DownloadFilter,
          {
            responseType: "blob",
            headers: {
              Authorization: "bearer " + token,
            },
          }
        );
        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          "withdrawal_list_" +
          this.DownloadFilter.Date1 +
          "_" +
          this.DownloadFilter.Date2 +
          ".xlsx"
        );
        document.body.appendChild(fileLink);

        fileLink.click();
        document.body.removeChild(fileLink);
      } catch (error) {
        //
      }
      this.downloading = false;
    },
  },
  mounted() {
    this.getList();
    this.companyList();
  },
});
</script>
<style scoped>
.page-title-s {
  width: 100%;
  max-width: 240px;
}

.page-title-s input {
  width: 100%;
}
</style>

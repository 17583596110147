<template>
  <AdminWithdrawalAutoRequestReview v-if="reviewScreen" :reviewData="reviewData"
    @cancelAutoGenerate="cancelAutoGenerate" @confirmAutoGenerate="confirmationPopupOpen = true" />
  <div class="container" v-if="!reviewScreen">
    <TitleBar></TitleBar>

    <div class="text-center" v-if="formData.loading == true">
      <img :src="require('@/assets/images/loading.gif')" />
    </div>
    <div class="row mt-4 title-section" v-if="formData.loading == false">
      <div class="col-3">
        <Datepicker v-model="startDate" :enable-time-picker="false" :format="format" :max-date="endDate"
          placeholder="From" :auto-apply="true" :close-on-auto-apply="true" class="calender-picker-field"
          @closed="dateChange"><template #input-icon>
            <img :src="require('@/assets/images/Group-714.svg')" alt="" class="calander-logo" /> </template>
        </Datepicker>
      </div>
      <div class="col-3">
        <Datepicker v-model="endDate" :enable-time-picker="false" :format="format" :min-date="startDate"
          placeholder="To" :auto-apply="true" :close-on-auto-apply="true" class="calender-picker-field"
          @closed="dateChange"><template #input-icon>
            <img :src="require('@/assets/images/Group-714.svg')" alt="" class="calander-logo" /> </template>
        </Datepicker>
      </div>
      <div class="col-3">
        <select v-model="companyId" class="form-control" id="companyId" placeholder="">
          <option :value="0">All</option>
          <option v-for="c in companies" :key="c.id" v-bind:value="c.id">
            {{ c.companyName }}
          </option>
        </select>
      </div>

      <div class="col-3 d-flex gap-2">
        <button class="btn btn-primary btn-sm btn-list btn-list-3" @click="getList">
          <i class="fa fa-search"></i> Filter
        </button>
        <button class="btn btn-primary btn-sm btn-list btn-list-3" @click="downloadPopupOpen = true">
          Download
        </button>
        <button class="btn btn-primary btn-sm btn-list btn-list-3" @click="autogenerate">
          Auto Generate
        </button>
      </div>
    </div>
    <Pagination :totalItem="withdrawalList.length" :items="withdrawalList" :perPage="50">
      <template #data="{ paginatedItems, currentIndex }">
        <table class="table table-list" v-if="formData.loading == false">
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col" class="pointer" @click="onSort('companyName')">
                <span class="sort-arrow" :class="currentSort == 'companyName'
    ? currentSortDir == 'desc'
      ? 'sort-arrow-desc'
      : 'sort-arrow-asc'
    : ''
    ">Company Name</span>
              </th>
              <th scope="col" class="pointer" @click="onSort('totalRequest')">
                <span class="sort-arrow" :class="currentSort == 'totalRequest'
    ? currentSortDir == 'desc'
      ? 'sort-arrow-desc'
      : 'sort-arrow-asc'
    : ''
    ">Total Withdrawal</span>
              </th>
              <th scope="col" class="pointer" @click="onSort('totalAmount')">
                <span class="sort-arrow" :class="currentSort == 'totalAmount'
    ? currentSortDir == 'desc'
      ? 'sort-arrow-desc'
      : 'sort-arrow-asc'
    : ''
    ">Total Amount</span>
              </th>
              <th scope="col" class="pointer" @click="onSort('payDay')">
                <span class="sort-arrow" :class="currentSort == 'payDay'
    ? currentSortDir == 'desc'
      ? 'sort-arrow-desc'
      : 'sort-arrow-asc'
    : ''
    ">Payday</span>
              </th>
              <th scope="col" class="pointer" @click="onSort('createdAt')">
                <span class="sort-arrow" :class="currentSort == 'createdAt'
    ? currentSortDir == 'desc'
      ? 'sort-arrow-desc'
      : 'sort-arrow-asc'
    : ''
    ">Date</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(withdrawal, index) in paginatedItems" :key="index + currentIndex">
              <th scope="row">{{ index + currentIndex }}</th>
              <td>{{ withdrawal.companyName }}</td>
              <td>{{ withdrawal.totalRequest }}</td>
              <td>
                {{
    withdrawal.totalAmount
      ? parseFloat(withdrawal.totalAmount)
        .toFixed(2)
        .toLocaleString()
      : 0
  }}
              </td>
              <td>{{ moment(withdrawal.payDay).format("DD-MM-YYYY") }}</td>
              <td>{{ moment(withdrawal.createdAt).format("DD-MM-YYYY") }}</td>
            </tr>
          </tbody>
        </table>
        <p v-if="totalamount > 0">
          Total Amount :
          {{ parseFloat(totalamount).toFixed(2).toLocaleString() }}
        </p>
        <div v-if="popupOpen" class="popup">
          <div class="popup-content">
            <button class="close-button" @click="closePopup">×</button>
            <h2></h2>
            <form @submit.prevent="reviewAutoGenerateData">
              <div class="col-12">
                <label for="Payday" class="form-label">Payday</label>
                <Datepicker v-model="formData.Payday" :enable-time-picker="false" :format="format" :auto-apply="true"
                  :close-on-auto-apply="true" :max-date="maxDate" :min-date="minDate"></Datepicker>
                <span class="text-danger" v-for="error in v$.Payday.$errors" :key="error.$uid">{{ error.$message
                  }}</span>
              </div>
              <br />
              <div class="col-12">
                <label for="Company" class="form-label">Company</label>
                <select v-model="formData.CompanyIdG" class="form-control" id="CompanyIdG"
                  placeholder="Select a Company">
                  <option disabled value="null">Select a Company</option>
                  <template v-for="c in companies" :key="c.id">
                    <option :key="c.id" :value="c.id" v-if="c.statusString !== 'Inactive'">
                      {{ c.companyName }}
                    </option>
                  </template>
                </select>

                <span class="text-danger" v-for="error in v$.CompanyIdG.$errors" :key="error.$uid">{{ error.$message
                  }}</span>
              </div>
              <br />
              <div class="col-12">
                <label for="Company" class="form-label">Users</label>
                <select v-model="formData.userGroup" class="form-control" id="CompanyIdG"
                  placeholder="Select a user group">
                  <option value="activated">App account activated Users</option>
                  <option value="all">All Users</option>
                </select>

                <span class="text-danger" v-for="error in v$.userGroup.$errors" :key="error.$uid">{{ error.$message
                  }}</span>
              </div>
              <br />
              <div class="popup-actions">
                <button class="btn btn-primary me-2" type="submit">
                  Confirm
                </button>
              </div>
            </form>
          </div>
        </div>

        <div v-if="downloadPopupOpen" class="popup">
          <div class="popup-content">
            <button class="close-button" @click="downloadPopupOpen = false">
              ×
            </button>
            <h2></h2>
            <form @submit.prevent="downloadAutoGenerateData">
              <div class="col-12">
                <label for="Payday" class="form-label">Payday</label>
                <Datepicker v-model="formData.Payday" :enable-time-picker="false" :format="format" :auto-apply="true"
                  :close-on-auto-apply="true" :max-date="maxDate" :min-date="minDate"></Datepicker>
                <span class="text-danger" v-for="error in v$.Payday.$errors" :key="error.$uid">{{ error.$message
                  }}</span>
              </div>
              <br />
              <div class="col-12">
                <label for="Company" class="form-label">Company</label>
                <select v-model="formData.CompanyIdG" class="form-control" id="CompanyIdG"
                  placeholder="Select a Company">
                  <option disabled value="null">Select a Company</option>
                  <template v-for="c in companies" :key="c.id">
                    <option :key="c.id" :value="c.id" v-if="c.statusString !== 'Inactive'">
                      {{ c.companyName }}
                    </option>
                  </template>
                </select>

                <span class="text-danger" v-for="error in v$.CompanyIdG.$errors" :key="error.$uid">{{ error.$message
                  }}</span>
              </div>
              <br />
              <div class="col-12">
                <label for="Company" class="form-label">Users</label>
                <select v-model="formData.userGroup" class="form-control" id="CompanyIdG"
                  placeholder="Select a user group">
                  <option value="activated">App account activated Users</option>
                  <option value="all">All Users</option>
                </select>

                <span class="text-danger" v-for="error in v$.userGroup.$errors" :key="error.$uid">{{ error.$message
                  }}</span>
              </div>
              <br />
              <div class="col-12">
                <label for="Company" class="form-label">Format</label>
                <select v-model="formData.format" class="form-control" placeholder="Select a format">
                  <option value="default">Default</option>
                  <option value="mbb">MBB format</option>
                </select>

                <span class="text-danger" v-for="error in v$.format.$errors" :key="error.$uid">{{ error.$message
                  }}</span>
              </div>
              <br />
              <div class="popup-actions">
                <button class="btn btn-primary me-2" type="submit">
                  Confirm
                </button>
              </div>
            </form>
          </div>
        </div>
      </template>
    </Pagination>
  </div>
  <div class="popup" v-if="confirmationPopupOpen">
    <div class="popup-content">
      <br />
      <h3 class="text-center">Please confirm to continue</h3>
      <br />
      <br />
      <br />

      <div class="popup-actions">
        <button class="btn btn-primary me-2" @click="confirmationPopupOpen = false">
          Cancel
        </button>
        <button class="btn btn-primary me-2" @click="submitForm">
          Confirm
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import store from "@/store";
import axios from "axios";
import { defineComponent, ref, onMounted, reactive, inject, toRefs } from "vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import Pagination from "@/components/Pagination.vue";
import moment from "moment";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  email,
  sameAs,
  helpers,
  decimal,
  numeric,
  minLength,
  maxLength,
} from "@vuelidate/validators";
import { computed } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import TitleBar from "@/components/TitleBar.vue";
import AdminWithdrawalAutoRequestReview from "./AdminWithdrawalAutoRequestReview.vue";
import { useDateParam } from "@/composables/useDateParam";
import { usePasswordVerify } from "@/composables/usePasswordVerify";
import { formatDateToYYYYMMDD } from "@/helpers/date-formatter";
export default defineComponent({
  setup() {
    const { verifyPassword } = usePasswordVerify();
    const { startDate, endDate, dateChange } = useDateParam();
    const companyId = ref(0);
    const toast = useToast();
    const router = useRouter();
    const format = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    };

    const reviewScreen = ref(false);
    const reviewData = ref<any>({
      totalUsers: 0,
      totalWithdrawPayoff: 0,
      userList: [],
    });

    const DownloadFilter = reactive({
      Date1: formatDateToYYYYMMDD(new Date()),
      Date2: formatDateToYYYYMMDD(new Date()),
      companyId: "",
      stringStatus: "All",
      Time: "B2",
    });

    const popupOpen = ref(false);
    const downloadPopupOpen = ref(false);
    const confirmationPopupOpen = ref(false);

    const formData = reactive({
      Payday: new Date(),
      CompanyIdG: null,
      loading: true,
      userGroup: "activated",
      format: 'default'
    });
    const autogenerate = () => {
      popupOpen.value = true;
    };
    const downloadPopup = () => {
      downloadPopupOpen.value = true;
    };

    const closePopup = () => {
      popupOpen.value = false;
    };

    async function reviewAutoGenerateData() {
      const result = await v$.value.$validate();

      if (result) {
        const token = store.state.token;
        formData.loading = true;


        axios
          .post(
            process.env.VUE_APP_API_URL + "Withdrawal/auto/generate/review",
            // process.env.VUE_APP_API_URL + "Withdrawal/auto/generate/download",
            {
              companyId: formData.CompanyIdG,
              payday: formData.Payday,
              userGroup: formData.userGroup,
            },
            {
              headers: {
                Authorization: "bearer " + token,
              },
            }
          )
          .then((res) => {
            formData.loading = false;
            reviewData.value = res.data;
            reviewScreen.value = true;
          })
          .catch((err) => {
            // 
          });
        closePopup();
      }
    }

    async function downloadAutoGenerateData() {
      const result = await v$.value.$validate();
      let downloadUrl = process.env.VUE_APP_API_URL + "Withdrawal/auto/generate/download"
      if (formData.format === "mbb") {
        downloadUrl = process.env.VUE_APP_API_URL + "Withdrawal/auto/generate/download/mbb"
      }
      downloadPopupOpen.value = false;
      if (!(await this.verifyPassword())) return
      if (result) {
        const token = store.state.token;
        axios
          .post(
            downloadUrl,
            {
              companyId: formData.CompanyIdG,
              payday: formData.Payday,
              userGroup: formData.userGroup,
            },
            {
              responseType: "blob",
              headers: {
                Authorization: "bearer " + token,
              },
            }
          )
          .then((res) => {
            var fileURL = window.URL.createObjectURL(
              new Blob([res.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              })
            );
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", "auto-withdrawal_list.xlsx");
            document.body.appendChild(fileLink);
            fileLink.click();
            downloadPopupOpen.value = false;
          })
          .catch((err) => {
            // 
            downloadPopupOpen.value = false;
          });
      }
    }
    const submitForm = async () => {
      reviewScreen.value = false;
      const result = await v$.value.$validate();
      if (result) {
        const token = store.state.token;
        formData.loading = true;
        const response = axios
          .get(process.env.VUE_APP_API_URL + "Withdrawal/auto/generate", {
            params: {
              date1: null,
              date2: null,
              companyId: formData.CompanyIdG,
              Payday: formData.Payday,
              CompanyIdG: formData.CompanyIdG,
              userGroup: formData.userGroup,
            },
            headers: {
              Authorization: "bearer " + token,
            },
          })
          .then((res) => {
            formData.loading = false;
            toast.success("Ssuccessfully Generate", {
              timeout: 3000,
            });
            router.push({
              name: "withdrawal-list",
            });
          })
          .catch((err) => {
            // 
          });
        confirmationPopupOpen.value = false;
        closePopup();
      }
    };
    const rules = computed(() => {
      return {
        Payday: {
          required: helpers.withMessage("Please select payday", required),
        },
        CompanyIdG: {
          required: helpers.withMessage("Please select company", required),
        },
        userGroup: {
          required: helpers.withMessage("Please select user group", required),
        },
        format: {
          required: helpers.withMessage("Please select format", required),
        },
      };
    });
    const v$ = useVuelidate(rules, formData);

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const minDate = new Date(currentYear, currentMonth, 1);
    const maxDate = new Date(currentYear, currentMonth + 1, 0);

    function cancelAutoGenerate() {
      formData.Payday = new Date();
      formData.CompanyIdG = null;
      formData.userGroup = "activated";
      reviewScreen.value = false;
    }

    return {
      downloadAutoGenerateData,
      downloadPopupOpen,
      startDate,
      endDate,
      dateChange,
      companyId,
      format,
      moment: moment,
      DownloadFilter,
      rules,
      v$,
      formData,
      popupOpen,
      autogenerate,
      closePopup,
      submitForm,
      minDate,
      maxDate,
      reviewScreen,
      reviewData,
      cancelAutoGenerate,
      confirmationPopupOpen,
      reviewAutoGenerateData,
      verifyPassword
    };
  },
  name: "AdminWithdrawalAutoRequestList",
  data() {
    return {
      currentSort: "name",
      currentSortDir: "asc",
      withdrawalList: ref([]),

      count: 1,
      totalamount: 0 as any,

      timedefault: "B2",
      time: [
        { id: "B2", name: "Before 2pm (based on current date)" },
        { id: "A2", name: "After 2pm (based on current date)" },
        { id: "All", name: "All" },
      ],
      statusdefault: "All",
      status: [
        { id: "All", name: "All" },
        { id: "0", name: "Pending" },
        { id: "1", name: "Approved" },
      ],
      stringStatus: "All",
      Time: "B2",
      companies: null,
    };
  },
  components: {
    Datepicker,
    Pagination,
    TitleBar,
    AdminWithdrawalAutoRequestReview,
  },
  methods: {
    onSort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;

      this.withdrawalList = this.withdrawalList.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },

    async getList() {
      const dateString1 = formatDateToYYYYMMDD(this.startDate);
      const dateString2 = formatDateToYYYYMMDD(this.endDate);

      this.DownloadFilter.Date1 = dateString1;
      this.DownloadFilter.Date2 = dateString2;
      this.DownloadFilter.companyId = this.companyId;

      this.totalamount = 0;

      const token = store.state.token;
      const response = await axios.get(
        process.env.VUE_APP_API_URL + "Withdrawal/AutoWithdrawal/list",
        {
          params: {
            date1: dateString1,
            date2: dateString2,
            companyId: this.companyId,
          },
          headers: {
            Authorization: "bearer " + token,
          },
        }
      );
      this.formData.loading = false;
      this.withdrawalList = response.data;
      this.totalamount = 0;
      this.withdrawalList.forEach((element) => {
        this.totalamount = this.totalamount + element.totalAmountRequested;
      });
    },
    async companyList() {
      const token = store.state.token;
      const response = await axios.get(
        process.env.VUE_APP_API_URL + "Company/list/auto/withdrawal",
        {
          headers: {
            Authorization: "bearer " + token,
          },
        }
      );

      this.formData.loading = false;
      this.companies = response.data;
    },
    async DownloadPopSA() {
      await this.$swal({
        html: `
            <form>
             <div class="row g-3 margin-lr-0 left-align">
           <div class="col-sm-12">
 <label for="Status" class="form-label">Status</label>
 <select id="status"
             name="Status" class="form-control" value="${this.statusdefault}"
          >
               ${this.status.map(
          (s) => `<option value="${s.id}">${s.name}</option>`
        )}
          </select>
          </div>
                     <div class="col-sm-12">
 <label for="Time" class="form-label">Time</label>
 <select id="time"
             name="Time" class="form-control" value="${this.timedefault}"
          >
               ${this.time.map(
          (t) => `<option value="${t.id}">${t.name}</option>`
        )}
          </select>
          </div>
           </div>
          </form>
          `,

        showCancelButton: false,
        confirmButtonText: "<i class='fa fa-download'></i> Download ",
        cancelButtonText: "Close",
        showCloseButton: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-secondary me-2",
        },
      }).then(async (result) => {
        if (result.value) {
          const status = this.$swal.getHtmlContainer().querySelector("#status");
          const time = this.$swal.getHtmlContainer().querySelector("#time");
          if (status) {
            this.DownloadFilter.stringStatus = status.value;
          }
          if (time) {
            this.DownloadFilter.Time = time.value;
          }


          if (!await this.verifyPassword()) return
          this.DownloadExcel();
        }
      });
    },
    async DownloadExcel() {
      const token = store.state.token;
      const response = await axios
        .post(
          process.env.VUE_APP_API_URL + "withdrawal/download-list",
          this.DownloadFilter,
          {
            responseType: "blob",
            headers: {
              Authorization: "bearer " + token,
            },
          }
        )
        .then((res) => {
          var fileURL = window.URL.createObjectURL(
            new Blob([res.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            "withdrawal_list_" +
            this.DownloadFilter.Date1 +
            "_" +
            this.DownloadFilter.Date2 +
            ".csv"
          );
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((err) => {
          // 
        });
    },
  },
  mounted() {
    this.getList();
    this.companyList();
  },
});
</script>

<style>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  width: 32em;
  max-width: 100%;
  position: relative;
}

.close-button {
  position: absolute;
  top: -2px;
  right: 3px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 2;
  align-items: center;
  justify-content: center;
  width: 1.2em;
  height: 1.2em;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: -1.2em;
  padding: 0;
  overflow: hidden;
  transition: color 0.1s, box-shadow 0.1s;
  border: none;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0);
  color: #ccc;
  font-family: serif;
  font-family: monospace;
  font-size: 2.5em;
  cursor: pointer;
  justify-self: end;
}

.popup-actions {
  text-align: center;
}

.close-button:hover {
  color: red;
}
</style>

<template>
  <div class="row mt-5 title-section">
    <div class="col-6">
      <h3 class="pull-left page-title-h">
        {{ pageTitleCustom ? pageTitleCustom : displayPageTitle }}
        <a
          href="#"
          @click.prevent="goToEmployeeDetail(PageId)"
          v-if="
            role != 'admin' &&
            (pageTitleCustom == 'Employee Details' ||
              displayPageTitle == 'Employee Details')
          "
          type="submit"
          class="update-logo"
        >
          <img :src="require('@/assets/images/Group-1429.svg')" alt="" />
        </a>
        <router-link
          v-if="
            pageTitleCustom == 'Company Details' ||
            (displayPageTitle == 'Company Details' && PageId)
          "
          type="submit"
          class="update-logo"
          :to="{
            name: 'subcompany-update',
            params: {
              id: PageId,
            },
          }"
        >
          <img :src="require('@/assets/images/Group-1429.svg')" alt="" />
        </router-link>
      </h3>
      <span class="title-border-b" />
    </div>
    <div class="col-6">
      <div class="btn-list float-right sitemap-section">
        <span
          class="d-none d-sm-inline sitemap-section"
          id="site-map-1"
          v-if="displayPageCategory"
          >{{ displayPageCategory }}
          <template
            v-if="
              $route.meta.pageName != '' ||
              ($route.meta.pageSubCategory && $route.meta.pageSubCategory != '')
            "
            >>
          </template></span
        >
        <span
          class="d-none d-sm-inline sitemap-section"
          id="site-map-2"
          v-if="$route.meta.pageName != '' || $route.meta.pageSubCategory != ''"
          >{{
            $route.meta.pageSubCategory && $route.meta.pageSubCategory != ""
              ? displayPageSubCategory
              : $route.meta.pageName != ""
              ? $route.meta.pageName
              : $route.meta.pageName
          }}
          <template
            v-if="
              $route.meta.pageSubCategory && $route.meta.pageSubCategory != ''
            "
            >>
          </template>
        </span>
        <span
          class="d-none d-sm-inline sitemap-section"
          id="site-map-3"
          v-if="
            $route.meta.pageName != '' &&
            $route.meta.pageSubCategory &&
            $route.meta.pageSubCategory != '' &&
            $route.meta.pageName != '' &&
            ($route.meta.pageSubCategory1 || $route.meta.pageSubCategory1 != '')
          "
          >{{
            $route.meta.pageSubCategory1 && $route.meta.pageSubCategory1 != ""
              ? $route.meta.pageSubCategory1
              : $route.meta.pageName != ""
              ? $route.meta.pageName
              : $route.meta.pageName
          }}
          <template
            v-if="
              $route.meta.pageSubCategory1 && $route.meta.pageSubCategory1 != ''
            "
            >>
          </template>
        </span>
        <span
          class="d-none d-sm-inline sitemap-section"
          id="site-map-4"
          v-if="
            $route.meta.pageName != '' &&
            $route.meta.pageSubCategory1 &&
            $route.meta.pageSubCategory1 != ''
          "
          >{{
            $route.meta.pageName != ""
              ? $route.meta.pageName
              : $route.meta.pageName
          }}
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { usePasswordVerify } from "@/composables/usePasswordVerify";
import vue from "vue";
import { useRouter } from "vue-router";

export default {
  name: "TitleBar",
  // props override route meta
  props: {
    PageId: {
      default: "",
      required: false,
      type: String,
    },
    pageTitle: {
      default: "" ?? (this as any).$route.meta.pageTitle,
      required: false,
      type: String,
    },
    pageTitleCustom: {
      default: "",
      required: false,
      type: String,
    },
    pageCategory: {
      default: "" ?? (this as any).$route.meta.pageCategory,
      required: false,
      type: String,
    },
    pageSubCategory: {
      default: "" ?? (this as any).$route.meta.pageSubCategory,
      required: false,
      type: String,
    },
    pageSubCategory1: {
      default: "" ?? (this as any).$route.meta.pageSubCategory1,
      required: false,
      type: String,
    },
    pageName: {
      default: "" ?? (this as any).$route.meta.pageName,
      required: false,
      type: String,
    },
    subPageName: {
      default: "" ?? (this as any).$route.meta.subPageName,
      required: false,
      type: String,
    },
  },

  setup() {
    const router = useRouter();
    const { verifyPassword } = usePasswordVerify();

    async function goToEmployeeDetail(id) {
      if (!(await verifyPassword())) return;
      router.push({
        name: "employee-update",
        params: {
          id: id,
        },
      });
    }

    return { goToEmployeeDetail };
  },

  data() {
    return {};
  },
  computed: {
    role() {
      return this.$store.getters.role;
    },
    displayPageName: function () {
      return this.pageName != ""
        ? this.pageName
        : this.$route.meta.pageName != null
        ? this.$route.meta.pageName
        : "";
    },
    displayPageSubCategory: function () {
      return this.pageSubCategory != ""
        ? this.pageSubCategory
        : this.$route.meta.pageSubCategory != null
        ? this.$route.meta.pageSubCategory
        : "";
    },
    displayPageCategory: function () {
      return this.pageCategory != ""
        ? this.pageCategory
        : this.$route.meta.pageCategory != null
        ? this.$route.meta.pageCategory
        : // ? this.pageCategory
          // : this.$route.matched[1].meta.pageCategory != null
          // ? this.$route.matched[1].meta.pageCategory
          "";
    },
    displayPageTitle: function () {
      return this.pageTitle != ""
        ? this.pageTitle
        : this.$route.meta.pageTitle != null
        ? this.$route.meta.pageTitle
        : this.displayPageName;
    },
  },
};
</script>

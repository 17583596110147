<template>
  <div class="container mt-5">
    <TitleBar
      :pageTitleCustom="id && id != '' ? 'Update Company' : 'Add Company'"
    ></TitleBar>
    <div class="col-12 title-section">
      <span class="title-border-b" />
      <div class="text-danger" v-if="errorMsg != ''">
        {{ errorMsg }}
      </div>
    </div>
    <form @submit.prevent="submit">
      <div class="row g-3 pddbtm50">
        <div class="col-sm-5">
          <label for="CompanyName" class="form-label">Company Name</label>
          <input
            v-model="this.formData.CompanyName"
            type="text"
            class="form-control"
            id="CompanyName"
            placeholder=""
          />
          <span
            class="text-danger"
            v-for="error in v$.CompanyName.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>

        <div class="col-sm-5">
          <label for="ContactNumber" class="form-label">Contact Number</label>
          <input
            v-model="this.formData.ContactNumber"
            type="text"
            class="form-control"
            id="ContactNumber"
            placeholder=""
          />
          <span
            class="text-danger"
            v-for="error in v$.ContactNumber.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>
        <div class="col-sm-5">
          <label for="Email" class="form-label">Email</label>
          <input
            v-model="this.formData.Email"
            type="text"
            class="form-control"
            id="Email"
            placeholder=""
          />
          <span
            class="text-danger"
            v-for="error in v$.Email.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>
  
        <div class="col-sm-5">
          <label for="status" class="form-label">Status</label>
          <select
            v-model="formData.status"
            class="form-control"
            id="status"
            placeholder=""
          >
            <option value="1">Active</option>
            <option value="0">Inactive</option>
          </select>
          <span
            class="text-danger"
            v-for="error in v$.status.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>
      </div>
      <div class="row mt-2 g-3">
        <div class="col-12 title-section">
          <h3 class="pull-left">Employer</h3>
          <span class="title-border-b" />
        </div>
        <div class="col-sm-5">
          <label for="EfirstName" class="form-label">First name</label>
          <input
            v-model="this.formData.EFirstName"
            type="text"
            class="form-control"
            id="EfirstName"
            placeholder=""
          />
          <span
            class="text-danger"
            v-for="error in v$.EFirstName.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>

        <div class="col-sm-5">
          <label for="ElastName" class="form-label">Last name</label>
          <input
            v-model="formData.ELastName"
            type="text"
            class="form-control"
            id="ElastName"
            placeholder=""
          />
          <span
            class="text-danger"
            v-for="error in v$.ELastName.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>

        <div class="col-sm-5">
          <label for="EEmail" class="form-label"
            >Email <span class="text-muted"></span
          ></label>
          <input
            v-model="this.formData.EEmail"
            type="text"
            class="form-control"
            id="EEmail"
            placeholder=""
          />
          <span
            class="text-danger"
            v-for="error in v$.EEmail.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>

        <div class="col-sm-5" v-if="!id">
          <label for="EPassword" class="form-label">Password </label>
          <div class="control is-expanded">
            <input
              v-if="showPassword"
              v-model="this.formData.EPassword"
              type="text"
              class="form-control"
              id="EPassword"
              placeholder=""
            />
            <input
              v-else
              v-model="this.formData.EPassword"
              type="password"
              class="form-control"
              id="EPassword"
              placeholder=""
            />
            <span
              @click="toggleShow"
              class="fas password-icon"
              :class="{ 'fa-eye-slash': !showPassword, 'fa-eye': showPassword }"
            ></span>
          </div>
          <span
            class="text-danger"
            v-for="error in v$.EPassword.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>
      </div>
      <div class="pddtop30">
        <button class="btn btn-secondary mrgrbtn" type="button" @click="goBack">
          Cancel
        </button>
        <button class="btn btn-primary" type="submit">Submit</button>
      </div>
    </form>
  </div>
</template>
<style>
.password-icon {
  float: right;
  right: 3%;
  margin-top: -25px;
  position: relative;
  z-index: 2;
}
</style>
<script lang="ts">
import store from "@/store";
import axios from "axios";
import { defineComponent, ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  email,
  sameAs,
  helpers,
  decimal,
  numeric,
  minLength,
  maxLength,
} from "@vuelidate/validators";
import { reactive } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import TitleBar from "@/components/TitleBar.vue";
export default defineComponent({
  name: "AdminCompanyEditor",
  setup() {
    const toast = useToast();
    const router = useRouter();
    const token = store.state.token;
    const errorMsg = ref("");

    const sending = ref(false);

    const formData = reactive({
      pid: 0,
      Id: 0,
      CompanyName: "",
      ContactNumber: "",
      Email: "",
      CompanyLogo: "",
      status: "",
      EFirstName: "",
      ELastName: "",
      EEmail: "",
      EPassword: "",
      EContactNumber: "",
    });

    const submit = async () => {
      if (sending.value) return;

      sending.value = true;
      const result = await v$.value.$validate();

      if (!result) {
        sending.value = false;
        return;
      }
      if (formData.pid && formData.pid != 0) {
        await axios
          .put(process.env.VUE_APP_API_URL + "company/Cemployer", formData, {
            headers: {
              Authorization: "bearer " + token,
            },
          })
          .then((res) => {
            errorMsg.value = "";

            toast.success("Employee successfully updated", {
              timeout: 3000,
            });
            sending.value = false;
            router.push({
              name: "admin-company-details",
              params: { id: formData.Id },
            });
          })
          .catch((err) => {
            sending.value = false;
            errorMsg.value = err.response.data;
          });
      } else {
        await axios
          .post(process.env.VUE_APP_API_URL + "company/Cemployer", formData, {
            headers: {
              Authorization: "bearer " + token,
            },
          })
          .then((res) => {
            errorMsg.value = "";

            toast.success("Employee successfully added", {
              timeout: 3000,
            });
            sending.value = false;
            router.push("/admin/company/list");
          })
          .catch((err) => {
            errorMsg.value = err.response.data;
            sending.value = false;
          });
      }
    };

    const rules = computed(() => {
      return {
        CompanyName: {
          required: helpers.withMessage("Please enter company name", required),
        },
        ContactNumber: {
          required: helpers.withMessage(
            "Please enter contact number",
            required
          ),
          numeric,
        },
        Email: {
          email,
          required: helpers.withMessage("Please enter email", required),
        },
        EFirstName: {
          required: helpers.withMessage("Please enter first name", required),
        },
        ELastName: {
          required: helpers.withMessage("Please enter last name", required),
        },
        EEmail: {
          email,
          required: helpers.withMessage("Please enter email", required),
        },
        EContactNumber: {
        },
        EPassword: {
          required: helpers.withMessage("Please enter Password", required),
          minLength: minLength(6),
          //passwordformat: helpers.withMessage("The password requires an uppercase, lowercase and number", helpers.regex(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/)),
        },
        status: {
          required: helpers.withMessage("Please select status", required),
        },
      };
    });
    const v$ = useVuelidate(rules, formData);

    return { v$, formData, token, router, toast, errorMsg, submit };
  },
  data() {
    return {
      id: "",
      loading: true,
      showPassword: false,
      password: null,
    };
  },
  components: {
    TitleBar,
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    async companyDetails() {
      const token = store.state.token;
      const response = await axios
        .get(process.env.VUE_APP_API_URL + "company/Cemployer/" + this.id, {
          headers: {
            Authorization: "bearer " + token,
          },
        })
        .then((res) => {
          this.formData.Id = res.data.id;
          this.formData.Email = res.data.email;
          this.formData.CompanyName = res.data.companyName;
          this.formData.ContactNumber = res.data.contactNumber;

          this.formData.status = res.data.status;

          this.formData.pid = this.id;

          this.formData.EFirstName = res.data.eFirstName;
          this.formData.ELastName = res.data.eLastName;
          this.formData.EEmail = res.data.eEmail;
          if (this.id) {
            this.formData.EPassword = "----------";
          }
          // this.formData.EPassword = res.data.ePassword;
          this.formData.EContactNumber = res.data.eContactNumber;
        })
        .catch((err) => {
          //
        });
      this.loading = false;
    },
    goBack() {
      this.$router.replace({
        name: "admin-company-name",
      });
    },
  },
  validations() {
    return {};
  },
  mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.companyDetails();
    }
  },
});
</script>

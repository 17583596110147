<template>
  <div class="container">
    <TitleBar :PageId="details.id + ''"></TitleBar>
    <div class="text-center" v-if="loading == true">
      <img :src="require('@/assets/images/loading.gif')" />
    </div>
    <div class="text-danger" v-if="errorMsg != ''">
      {{ errorMsg }}
    </div>
    <div v-if="loading == false">
      <tab
        :tabs="tabs"
        @tabChanged="onTabChange()"
        :maxTabs="details.allowCurlecIntegration === 1 ? 3 : 2"
      >
        <template v-slot:tab1>
          <div class="row pddbtm30">
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label
                for="staticEmail"
                class="col-sm-12 col-form-label detail-page-label"
                >First Name</label
              >
              <div class="col-sm-12">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext detail-page-input"
                  v-model="details.firstName"
                />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label
                for="staticEmail"
                class="col-sm-12 col-form-label detail-page-label"
                >Last Name</label
              >
              <div class="col-sm-12">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext detail-page-input"
                  v-model="details.lastName"
                />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label
                for="staticEmail"
                class="col-sm-12 col-form-label detail-page-label"
                >IC Number</label
              >
              <div class="col-sm-12">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext detail-page-input"
                  v-model="details.icNumber"
                />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label
                for="staticEmail"
                class="col-sm-12 col-form-label detail-page-label"
                >Employee Number</label
              >
              <div class="col-sm-12">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext detail-page-input"
                  v-model="details.employeeNumber"
                />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label
                for="staticEmail"
                class="col-sm-12 col-form-label detail-page-label"
                >Email</label
              >
              <div class="col-sm-12">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext detail-page-input"
                  v-model="details.email"
                />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label
                for="staticEmail"
                class="col-sm-12 col-form-label detail-page-label"
                >Personal Email</label
              >
              <div class="col-sm-12">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext detail-page-input"
                  v-model="details.personalEmail"
                />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label
                for="dob"
                class="col-sm-12 col-form-label detail-page-label"
                >Date of birth</label
              >
              <div class="col-sm-12">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext detail-page-input"
                  v-model="details.dataOfBirth"
                />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label
                for="address"
                class="col-sm-12 col-form-label detail-page-label"
                >Address</label
              >
              <div class="col-sm-12">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext detail-page-input"
                  v-model="details.address"
                />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label
                for="staticEmail"
                class="col-sm-12 col-form-label detail-page-label"
                >Contact Number</label
              >
              <div class="col-sm-12">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext detail-page-input"
                  v-model="details.contactNumber"
                />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label
                for="staticEmail"
                class="col-sm-12 col-form-label detail-page-label"
                >Department</label
              >
              <div class="col-sm-12">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext detail-page-input"
                  v-model="details.departmentName"
                />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label
                for="staticEmail"
                class="col-sm-12 col-form-label detail-page-label"
                >Position</label
              >
              <div class="col-sm-12">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext detail-page-input"
                  v-model="details.position"
                />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label
                for="staticEmail"
                class="col-sm-12 col-form-label detail-page-label"
                >Bank</label
              >
              <div class="col-sm-12">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext detail-page-input"
                  v-model="details.bankName"
                />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label
                for="staticEmail"
                class="col-sm-12 col-form-label detail-page-label"
                >Bank Account Number</label
              >
              <div class="col-sm-12">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext detail-page-input"
                  v-model="details.bankAccountNumber"
                />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label
                for="staticEmail"
                class="col-sm-12 col-form-label detail-page-label"
                >Basic salary (including fixed allowance)</label
              >
              <div class="col-sm-12">
                <SalarySafeguard :userId="details.id"></SalarySafeguard>
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label
                for="SubCompanyName"
                class="col-sm-12 col-form-label detail-page-label"
                >Company</label
              >
              <div class="col-sm-12">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext detail-page-input"
                  v-model="details.subCompanyName"
                />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label
                for="staticEmail"
                class="col-sm-12 col-form-label detail-page-label"
                >Joined Date</label
              >
              <div class="col-sm-12">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext detail-page-input"
                  v-model="details.joinedDate"
                />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label
                for="staticEmail"
                class="col-sm-12 col-form-label detail-page-label"
                >Withdrawal Start Date</label
              >
              <div class="col-sm-12">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext detail-page-input"
                  v-model="details.withdrawalStartDate"
                />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label
                for="GracePeriod"
                class="col-sm-12 col-form-label detail-page-label"
                >Notice Period</label
              >
              <div class="col-sm-12">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext detail-page-input"
                  v-model="details.gracePeriod"
                />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label
                for="staticEmail"
                class="col-sm-12 col-form-label detail-page-label"
                >App Activation status</label
              >
              <div class="col-sm-12">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext detail-page-input"
                  v-model="details.status"
                  :class="details.status == 'Inactive' ? 'red' : ''"
                />
              </div>
            </div>
            <div class="col-sm-5 mb-2 mrgr20 row">
              <label
                for="mainstatus"
                class="col-sm-12 col-form-label detail-page-label"
                >Account Status</label
              >
              <div class="col-sm-12">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext detail-page-input"
                  v-model="details.mainStatusString"
                  :class="details.mainStatusString == 'Inactive' ? 'red' : ''"
                />
              </div>
            </div>
          </div>
        </template>
        <template v-slot:tab2>
          <div class="row mt-4 title-section" v-if="loading == false">
            <div class="col-3">
              <Datepicker
                v-model="date1"
                :enable-time-picker="false"
                :format="format"
                :max-date="date2"
                placeholder="From"
                :auto-apply="true"
                :close-on-auto-apply="true"
                class="calender-picker-field"
                ><template #input-icon>
                  <img
                    :src="require('@/assets/images/Group-714.svg')"
                    alt=""
                    class="calander-logo"
                  /> </template
              ></Datepicker>
            </div>
            <div class="col-3">
              <Datepicker
                v-model="date2"
                :enable-time-picker="false"
                :format="format"
                :min-date="date1"
                placeholder="To"
                :auto-apply="true"
                :close-on-auto-apply="true"
                class="calender-picker-field"
                ><template #input-icon>
                  <img
                    :src="require('@/assets/images/Group-714.svg')"
                    alt=""
                    class="calander-logo"
                  /> </template
              ></Datepicker>
            </div>

            <div class="col-3">
              <button
                class="btn btn-primary btn-sm me-2 btn-list btn-list-3"
                @click="getList"
              >
                <i class="fa fa-search"></i> Filter
              </button>
            </div>
          </div>

          <p v-if="totalamount > 0 && totalNetAmount > 0">
            Total Fees : RM
            {{
              parseFloat(totalamount - totalNetAmount + "")
                .toFixed(2)
                .toLocaleString()
            }}
          </p>
          <Pagination
            :totalItem="withdrawalList.length"
            :items="withdrawalList"
            :perPage="50"
          >
            <template #data="{ paginatedItems, currentIndex }">
              <table class="table table-list" v-if="loading == false">
                <thead>
                  <tr>
                    <th scope="col">No</th>

                    <th scope="col" class="pointer" @click="onSort('bankName')">
                      <span
                        class="sort-arrow"
                        :class="
                          currentSort == 'bankName'
                            ? currentSortDir == 'desc'
                              ? 'sort-arrow-desc'
                              : 'sort-arrow-asc'
                            : ''
                        "
                        >Bank</span
                      >
                    </th>

                    <th
                      scope="col"
                      class="pointer"
                      @click="onSort('totalAmountRequested')"
                    >
                      <span
                        class="sort-arrow"
                        :class="
                          currentSort == 'totalAmountRequested'
                            ? currentSortDir == 'desc'
                              ? 'sort-arrow-desc'
                              : 'sort-arrow-asc'
                            : ''
                        "
                        >Amount Requested (RM)</span
                      >
                    </th>
                    <th
                      scope="col"
                      class="pointer"
                      @click="onSort('nettAmountToTransfer')"
                    >
                      <span
                        class="sort-arrow"
                        :class="
                          currentSort == 'nettAmountToTransfer'
                            ? currentSortDir == 'desc'
                              ? 'sort-arrow-desc'
                              : 'sort-arrow-asc'
                            : ''
                        "
                        >Nett Amount Transfer (RM)</span
                      >
                    </th>
                    <th
                      scope="col"
                      class="pointer"
                      @click="onSort('isAutoWithdrawType')"
                    >
                      <span
                        class="sort-arrow"
                        :class="
                          currentSort == 'isAutoWithdrawType'
                            ? currentSortDir == 'desc'
                              ? 'sort-arrow-desc'
                              : 'sort-arrow-asc'
                            : ''
                        "
                        >Type</span
                      >
                    </th>
                    <th
                      scope="col"
                      class="pointer"
                      @click="onSort('requestedAt')"
                    >
                      <span
                        class="sort-arrow"
                        :class="
                          currentSort == 'requestedAt'
                            ? currentSortDir == 'desc'
                              ? 'sort-arrow-desc'
                              : 'sort-arrow-asc'
                            : ''
                        "
                        >Requested At</span
                      >
                    </th>
                    <th
                      scope="col"
                      class="pointer"
                      @click="onSort('statusString')"
                    >
                      <span
                        class="sort-arrow"
                        :class="
                          currentSort == 'statusString'
                            ? currentSortDir == 'desc'
                              ? 'sort-arrow-desc'
                              : 'sort-arrow-asc'
                            : ''
                        "
                        >Status</span
                      >
                    </th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style="
                        font-size: 16px;
                        letter-spacing: 0px;
                        color: #472667;
                        font-weight: 700;
                      "
                    >
                      Total
                    </td>
                    <td>---</td>
                    <td>
                      {{
                        parseFloat(totalamount + "")
                          .toFixed(2)
                          .toLocaleString()
                      }}
                    </td>
                    <td>
                      {{
                        parseFloat(totalNetAmount + "")
                          .toFixed(2)
                          .toLocaleString()
                      }}
                    </td>
                    <td>---</td>
                    <td>---</td>
                    <td>---</td>
                    <td>---</td>
                  </tr>
                  <tr
                    v-for="(withdrawal, index) in paginatedItems"
                    :key="index + currentIndex"
                  >
                    <td scope="row">{{ index + currentIndex }}</td>
                    <td>{{ withdrawal.bankName }}</td>
                    <td>
                      {{
                        parseFloat(withdrawal.totalAmountRequested)
                          .toFixed(2)
                          .toLocaleString()
                      }}
                    </td>
                    <td>
                      {{
                        parseFloat(withdrawal.nettAmountToTransfer)
                          .toFixed(2)
                          .toLocaleString()
                      }}
                    </td>
                    <td>
                      {{
                        withdrawal.isAutoWithdrawType == 1 ? "Auto" : "Manual"
                      }}
                    </td>
                    <td>
                      {{ moment(withdrawal.requestedAt).format("DD-MM-YYYY") }}
                    </td>
                    <td
                      :class="
                        withdrawal.statusString == 'Rejected' ? 'red' : ''
                      "
                    >
                      {{ withdrawal.statusString }}
                    </td>
                    <td>
                      <router-link
                        :to="{
                          name: 'admin-withdrawal-details',
                          params: {
                            id: withdrawal.id,
                          },
                        }"
                        ><img
                          class="edit-btn-img"
                          :src="require('@/assets/images/edit-btn.svg')"
                      /></router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </Pagination>
        </template>
        <template v-slot:tab3 v-if="details.allowCurlecIntegration === 1">
          <div>
            <div v-for="item in curlecProfiles" :key="item.id">
              <p>
                Curlec Reference: <strong>{{ item.payeeRef }}</strong>
              </p>
              <p>Recipient Reference: {{ item.recipientRef }}</p>
              <p>Name: {{ item.firstName }} {{ item.lastName }}</p>
              <p>
                AccountId (for Curlec Reference to Bank details):
                {{ item.accoundId }}
              </p>
              <p>BankAccountNumber: {{ item.bankAccountNumber }}</p>
              <p>SwiftCode: {{ item.swiftCode }}</p>
              <p>Active Profile: {{ item.isActive === 1 ? "Yes" : "No" }}</p>

              <p>
                Profile Created:
                {{ item.status === 1 ? "Yes" : "No" }}
              </p>
              <p v-if="item.status === 1">
                Profile Created At:
                {{ item.profileCreatedAt }}
              </p>
              <p v-if="item.status === 1">
                Profile Updated At:
                {{ item.profileUpdatedAt }}
              </p>
              <p v-if="item.remark">
                Remark:
                {{ item.remark }}
              </p>
            </div>

            <button
              class="btn btn-primary btn-sm me-2 btn-list btn-list-3"
              @click="generateCurlecProfile()"
            >
              {{ generating ? "Generating..." : "Generate Profile" }}
            </button>
          </div>
        </template>
      </tab>
    </div>
    <div
      class="btn btn-primary mrgrbtn"
      type="submit"
      style="width: 150px"
      v-if="role == 'admin' && details.failedLoginAttempts >= 3"
      @click="reactivate"
    >
      Reactivate
    </div>

    <router-link
      class="btn btn-secondary"
      to="/employee/list"
      v-if="role != 'admin'"
      >Back</router-link
    >
  </div>
</template>
<script lang="ts">
import store from "@/store";
import axios from "axios";
import { computed, defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
import TitleBar from "@/components/TitleBar.vue";
import SalarySafeguard from "@/components/SalarySafeguard.vue";
import Tab from "@/components/Tab.vue";
import Pagination from "@/components/Pagination.vue";
import { formatDateToYYYYMMDD } from "@/helpers/date-formatter";
export default defineComponent({
  name: "EmployeeDetails",
  setup() {
    const toast = useToast();
    const router = useRouter();
    const route = useRoute;
    const role = computed(() => {
      return store.state.role;
    });

    const temp = new Date();
    temp.setDate(1);
    const date1 = ref(temp);
    const date2 = ref(new Date());

    const format = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    };
    return { role, toast, router, date1, date2, format };
  },
  components: {
    TitleBar,
    SalarySafeguard,
    Tab,
    Pagination,
    Datepicker,
  },
  data() {
    return {
      details: {} as any,
      loading: true,
      errorMsg: ref(""),
      moment: moment,
      tabs: [
        { label: "Details", slotName: "tab1" },
        { label: "Withdrawals", slotName: "tab2" },
      ],
      withdrawalList: ref([]),
      currentSort: "name",
      currentSortDir: "asc",
      totalamount: 0,
      totalNetAmount: 0,
      curlecProfiles: [],
      generating: false,
    };
  },
  methods: {
    onSort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;

      this.withdrawalList = this.withdrawalList.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
    async getList() {
      const id = this.$route.params.id;
      const dateString1 = formatDateToYYYYMMDD(this.date1);
      const dateString2 = formatDateToYYYYMMDD(this.date2);

      const token = store.state.token;
      const response = await axios.get(
        process.env.VUE_APP_API_URL + "Withdrawal/employee/history",
        {
          params: {
            date1: dateString1,
            date2: dateString2,
            employeeId: id,
          },
          headers: {
            Authorization: "bearer " + token,
          },
        }
      );
      this.withdrawalList = response.data;
      this.totalamount = 0;
      this.totalNetAmount = 0;
      this.withdrawalList.forEach((element) => {
        if (element.statusString === "Rejected") return;
        this.totalamount += element.totalAmountRequested;
        this.totalNetAmount += element.nettAmountToTransfer;
      });
    },
    onTabChange() {
      //
    },
    async reactivate() {
      const token = store.state.token;
      const id = this.$route.params.id;
      await axios
        .put(
          process.env.VUE_APP_API_URL + "employee/" + id + "/reactivateaccount",
          "",
          {
            headers: {
              Authorization: "bearer " + token,
            },
          }
        )
        .then((res) => {
          this.toast.success("Successfully reactivate", {
            timeout: 3000,
          });
          this.errorMsg = "";
          this.router.push({
            name: "admin-company-details",
            params: { id: this.companyId },
          });
        })
        .catch((err) => {
          this.errorMsg = err.response.data;
        });
    },
    async employeeDetails() {
      const id = this.$route.params.id;

      const token = store.state.token;
      let response: any = await axios.get(
        process.env.VUE_APP_API_URL + "employee/" + id + "/details",
        {
          headers: {
            Authorization: "bearer " + token,
          },
        }
      );

      response = response.data;

      if (response.allowCurlecIntegration === 1) {
        this.tabs = [
          ...this.tabs,
          { label: "Curlec Profile", slotName: "tab3" },
        ];
      }

      if (response.failedLoginAttemptsDate) {
        response.failedLoginAttemptsDate = moment(
          response.failedLoginAttemptsDate
        ).format("DD-MM-YYYY");
      }
      if (response.dataOfBirth) {
        response.dataOfBirth = moment(response.dataOfBirth).format(
          "DD-MM-YYYY"
        );
      }
      if (response.joinedDate) {
        response.joinedDate = moment(response.joinedDate).format("DD-MM-YYYY");
      }
      if (response.withdrawalStartDate) {
        response.withdrawalStartDate = moment(
          response.withdrawalStartDate
        ).format("DD-MM-YYYY");
      }
      if (response.gracePeriod) {
        response.gracePeriod = moment(response.gracePeriod).format(
          "DD-MM-YYYY"
        );
      }

      if (response.address1) {
        response.address ==
          response.address1 +
            ", " +
            response.address2 +
            ", " +
            response.city +
            ", " +
            response.postCode;
      }
      this.details = response;
    },

    async getCurlecProfile() {
      try {
        const id = this.$route.params.id;

        const token = store.state.token;
        let response: any = await axios.get(
          process.env.VUE_APP_API_URL + "Employee/curlec-profile/detail/" + id,
          {
            headers: {
              Authorization: "bearer " + token,
            },
          }
        );

        response = response.data.map((i) => {
          const temp = { ...i };
          if (temp.profileCreatedAt) {
            temp.profileCreatedAt = moment(temp.profileCreatedAt).format(
              "DD MMMM YYYY, h:mm A"
            );
          }
          if (temp.profileUpdatedAt) {
            temp.profileUpdatedAt = moment(temp.profileUpdatedAt).format(
              "DD MMMM YYYY, h:mm A"
            );
          }

          return temp;
        });

        this.curlecProfiles = response;
      } catch (error) {
        //
      }
    },

    async generateCurlecProfile() {
      try {
        this.generating = true;
        const id = this.$route.params.id;
        const token = store.state.token;
        const response = await axios.post(
          process.env.VUE_APP_API_URL +
            "Employee/generate/curlec-profile/" +
            id,
          {},
          {
            headers: {
              Authorization: "bearer " + token,
            },
          }
        );
        this.getCurlecProfile();
      } catch (error) {
        //
      }
      this.generating = false;
    },
  },
  mounted() {
    (async () => {
      try {
        this.loading = true;
        await this.employeeDetails();
        await this.getList();
        await this.getCurlecProfile();
      } catch (error) {
        //
        console.log(error);
      } finally {
        this.loading = false;
      }
    })();
  },
});
</script>

import { ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import moment from "moment";
import { formatDateToDDMMYYYY } from "@/helpers/date-formatter";
export function useDateParam() {
  const router = useRouter();
  const route = useRoute();

  const startDate = ref(null);
  const endDate = ref(null);
  setStartAndEndDate();

  watch(route, (value) => {
    setStartAndEndDate();
  });

  function setStartAndEndDate() {
    if (!(route.query.start && route.query.end)) {
      const temp = new Date();
      temp.setDate(1);
      router.replace({
        name: route.name,
        query: {
          start: formatDateToDDMMYYYY(temp),
          end: formatDateToDDMMYYYY(new Date()),
        },
      });
      startDate.value = moment(
        formatDateToDDMMYYYY(temp),
        "DD-MM-YYYY"
      ).toDate();
      endDate.value = moment(
        formatDateToDDMMYYYY(new Date()),
        "DD-MM-YYYY"
      ).toDate();
    } else {
      startDate.value = moment(route.query.start, "DD-MM-YYYY").toDate();
      endDate.value = moment(route.query.end, "DD-MM-YYYY").toDate();
    }
  }

  function dateChange() {
    router.push({
      name: route.name,
      query: {
        start: formatDateToDDMMYYYY(startDate.value),
        end: formatDateToDDMMYYYY(endDate.value),
      },
    });
  }

  return { dateChange, startDate, endDate };
}

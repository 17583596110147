<template>
  <div class="container">
    <TitleBar></TitleBar>
    <div class="text-center" v-if="loading == true">
      <img :src="require('@/assets/images/loading.gif')" />
    </div>

    <div class="text-danger" v-if="errorMsg != ''">
      {{ errorMsg }}
    </div>
    <div v-if="loading == false">
      <div class="row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">Company Name</label>
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ InvoiceDetails.companyName }}
          </div>
        </div>
      </div>
      <div class="row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">Invoice Number</label>
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ InvoiceDetails.invoiceNumber }}
          </div>
        </div>
      </div>
      <div class="row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">Status</label>
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ InvoiceDetails.statusString }}
          </div>
        </div>
      </div>
      <div class="row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">Paid at</label>
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{
              InvoiceDetails.paidAt
              ? moment(InvoiceDetails.paidAt).format("DD-MM-YYYY HH:mm:ss")
              : "-"
            }}
          </div>
        </div>
      </div>
      <div class="mb-5 row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">Transaction ID</label>
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{
              InvoiceDetails.transactionId ? InvoiceDetails.transactionId : "-"
            }}
          </div>
        </div>
      </div>
      <div class="row">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">Total</label>
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            RM{{ parseFloat(InvoiceDetails.total).toFixed(2).toLocaleString() }}
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <label for="staticEmail" class="col-sm-3 col-form-label detail-page-label">Total Withdrawal</label>
        <div class="col-sm-9">
          <div class="form-control-plaintext detail-page-input">
            {{ InvoiceDetails.totalWithdrawal }}
          </div>
        </div>
      </div>
      <!-- <hr class="my-4" /> -->
      <button class="btn btn-primary mrgrbtn" @click="withdrawalInvoiceUpdate" v-if="InvoiceDetails.status != 1">
        Update Status
      </button>
      <router-link class="btn btn-secondary" to="/admin/withdrawal/invoice/list" v-if="role == 'admin'">Back</router-link>
      <router-link class="btn btn-secondary" to="/withdrawal/invoice/list" v-else>Back</router-link>
    </div>
  </div>
</template>
<style>
.transaction-id-error {
  font-size: 14px;
}
</style>
<script lang="ts">
import store from "@/store";
import axios from "axios";
import { defineComponent, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import moment from "moment";
import { useToast } from "vue-toastification";
import TitleBar from "@/components/TitleBar.vue";
export default defineComponent({
  name: "WithdrawalInvoiceDetails",
  components: { TitleBar },
  setup() {
    const store = useStore();
    const token = store.state.token;
    const toast = useToast();
    const router = useRouter();
    const role = computed(() => {
      return store.state.role;
    });
    return { role, token, router, toast };
  },
  data() {
    return {
      Id: ref(0),
      count: 1,
      InvoiceDetails: ref<any>({}),
      loading: true,
      errorMsg: ref(""),
      terrorMsg: ref(""),
      transactionid: "",
      moment: moment,
    };
  },
  methods: {
    async withdrawalInvoiceUpdate() {
      const { value: formValues } = await this.$swal({
        html: `
            <form>
             <div class="row g-3 margin-lr-0 left-align">
           <div class="col-sm-12">
 <label for="Transaction ID" class="form-label">Transaction ID</label>
      <input
            type="text"
            class="form-control"
            id="transactionid"
            placeholder=""
          />
          <span
            class="text-danger transaction-id-error"
             id="transactioniderror"
            >${this.terrorMsg}</span
          >
          </div>
           </div>
          </form>
          `,

        showCancelButton: false,
        confirmButtonText: "Submit",
        cancelButtonText: "Close",
        showCloseButton: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-secondary me-2",
        },
        preConfirm: () => {
          const transactionid = this.$swal
            .getHtmlContainer()
            .querySelector("#transactionid");
          if (transactionid) {
            if (!transactionid.value) {
              this.terrorMsg = "Please enter Transaction ID";
              this.withdrawalInvoiceUpdate();
            } else {
              this.terrorMsg = "";
              this.transactionid = transactionid.value;
              this.withdrawalInvoiceUpdateSubmit();
            }
          }
        },
      });
    },
    async withdrawalInvoiceUpdateSubmit() {
      const token = store.state.token;
      const id = this.$route.params.id;
      this.InvoiceDetails.newTransactionId = this.transactionid;
      const response = await axios
        .post(
          process.env.VUE_APP_API_URL + "company/invoice/detail/update",
          this.InvoiceDetails,
          {
            responseType: "blob",
            headers: {
              Authorization: "bearer " + token,
            },
          }
        )
        .then((res) => {
          this.terrorMsg = "";
          this.invoiceDetails();
          this.toast.success("Invoice successfully updated", {
            timeout: 3000,
          });

          // this.router.push({
          //   name: "invoice-details",
          //   params: { id: id },
          // });
        })
        .catch((err) => {
          // 
          this.terrorMsg = err.message;
          this.withdrawalInvoiceUpdate();
        });
    },
    async invoiceDetails() {
      const id = this.$route.params.id;

      const token = store.state.token;
      const response = await axios
        .get(
          process.env.VUE_APP_API_URL + "company/invoice/" + id + "/detail",
          {
            headers: {
              Authorization: "bearer " + token,
            },
          }
        )
        .then((res) => {
          this.InvoiceDetails = res.data;
        })
        .catch((err) => {
           // 
        });
      this.loading = false;
    },
  },
  mounted() {
    this.invoiceDetails();
  },
});
</script>

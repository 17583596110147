<template>
  <div class="container">
    <TitleBar></TitleBar>
    <div class="text-center" v-if="loading == true">
      <img :src="require('@/assets/images/loading.gif')" />
    </div>
    <Pagination :totalItem="enquirys.length" :items="enquirys" :perPage="50">
      <template #data="{ paginatedItems, currentIndex }">
        <table class="table table-list" v-if="loading == false">
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col" class="pointer" @click="onSort('fullName')">
                <span
                  class="sort-arrow"
                  :class="
                    currentSort == 'fullName'
                      ? currentSortDir == 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                  "
                  >Full Name</span
                >
              </th> 
              <!-- <th
                scope="col"
                v-if="role == 'admin'"
                class="pointer sort-arrow"
                :class="
                  currentSort == 'mobileNumber'
                    ? currentSortDir == 'desc'
                      ? 'sort-arrow-desc'
                      : 'sort-arrow-asc'
                    : ''
                "
                @click="onSort('mobileNumber')"
              >
                Mobile Number
              </th> -->
              <th
                scope="col"
                class="pointer sort-arrow"
                :class="
                  currentSort == 'email'
                    ? currentSortDir == 'desc'
                      ? 'sort-arrow-desc'
                      : 'sort-arrow-asc'
                    : ''
                "
                @click="onSort('email')"
              >
                Email
              </th>
              <th scope="col" class="pointer" @click="onSort('companyName')">
                <span
                  class="sort-arrow"
                  :class="
                    currentSort == 'companyName'
                      ? currentSortDir == 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                  "
                  >Company Name</span
                >
              </th>
              <th scope="col" class="pointer" @click="onSort('message')">
                <span
                  class="sort-arrow"
                  :class="
                    currentSort == 'message'
                      ? currentSortDir == 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                  "
                  >Message</span
                >
              </th>
              <th scope="col" class="pointer" @click="onSort('createdAt')">
                <span
                  class="sort-arrow"
                  :class="
                    currentSort == 'createdAt'
                      ? currentSortDir == 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                  "
                  >Date</span
                >
              </th>
              <th scope="col" class="pointer" @click="onSort('enquiryType')">
                <span
                  class="sort-arrow"
                  :class="
                    currentSort == 'enquiryType'
                      ? currentSortDir == 'desc'
                        ? 'sort-arrow-desc'
                        : 'sort-arrow-asc'
                      : ''
                  "
                  >Type</span
                >
              </th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(enquiry, index) in paginatedItems" :key="enquiry.id">
              <td scope="row">{{ index + currentIndex }}</td>
              <td>{{ enquiry.fullName }}</td>
              <!-- <td>{{ enquiry.mobileNumber }}</td>  -->
              <td>{{ enquiry.email }}</td>
              <td>{{ enquiry.companyName }}</td>
              <td>{{ enquiry.message }}</td>
              <td>
                {{ moment(enquiry.createdAt).format("DD-MM-YYYY HH:mm:ss") }}
              </td>
              <td>{{ enquiry.enquiryType }}</td>
              <td>
                <router-link
                  :to="{
                    name: 'admin-enquiry-details',
                    params: {
                      id: enquiry.id,
                    },
                  }"
                  ><img
                    class="edit-btn-img"
                    :src="require('@/assets/images/edit-btn.svg')"
                /></router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </Pagination>
  </div>
</template>
<script lang="ts">
import store from "@/store";
import axios from "axios";
import Pagination from "@/components/Pagination.vue";
import TitleBar from "@/components/TitleBar.vue";
import {
  computed,
  getTransitionRawChildren,
  onMounted,
  ref,
  watch,
  defineComponent,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import moment from "moment";
export default defineComponent({
  name: "EnquiryList",
  setup() {
    const store = useStore();

    const role = computed(() => {
      return store.state.role;
    });

    return { role };
  },
  components: {
    Pagination,
    TitleBar,
  },
  data() {
    return {
      enquirys: ref([]),
      count: 1,
      loading: true,
      moment: moment,
      currentSort: "name",
      currentSortDir: "asc",
    };
  },
  methods: {
    onSort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;

      this.enquirys = this.enquirys.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
    async enquiryList() {
      const token = store.state.token;
      const response = await axios.get(
        process.env.VUE_APP_API_URL + "Enquiry/list",
        {
          headers: {
            Authorization: "bearer " + token,
          },
        }
      );
      this.loading = false;
      this.enquirys = response.data;
    },
  },
  mounted() {
    this.enquiryList();
  },
});
</script>

<template>
  <TitleBar pageTitleCustom="WITHDRAWAL AUTO GENERATE REVIEW"></TitleBar>
  <div
    class="row"
    v-for="item in reviewData.groupedUsers"
    :key="item.withdrawalPayoff"
  >
    <label for="staticEmail" class="col-sm-5 col-form-label detail-page-label"
      >Total user with Payout RM
      {{ parseFloat(item.withdrawalPayoff).toFixed(2).toLocaleString() }}
      </label
    >
    <div class="col-sm-7">
      <div class="form-control-plaintext detail-page-input">
        {{ item.count }}
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <label for="staticEmail" class="col-sm-5 col-form-label detail-page-label"
      >Total withdrawal payouts RM</label
    >
    <div class="col-sm-7">
      <div class="form-control-plaintext detail-page-input">
        {{ parseFloat(reviewData.totalWithdrawPayoff).toFixed(2).toLocaleString() }}
      </div>
    </div>
  </div>

  <div class="row mt-4 title-section">
    <div class="col-4 d-flex gap-2">
      <button
        class="btn btn-primary btn-sm btn-list btn-list-3"
        @click="$emit('cancelAutoGenerate')"
      >
        Back
      </button>
      <button
        class="btn btn-primary btn-sm btn-list btn-list-3"
        @click="$emit('confirmAutoGenerate')"
      >
        Auto Generate
      </button>
    </div>
    <div class="col-3"></div>
  </div>

  <Pagination
    :totalItem="filteredList.length"
    :items="filteredList"
    :perPage="50"
  >
    <template #data="{ paginatedItems, currentIndex }">
      <table class="table table-list">
        <thead>
          <tr>
            <th scope="col">No</th>
            <th scope="col" class="pointer" @click="onSort('fullName')">
              <span
                class="sort-arrow"
                :class="
                  currentSort == 'fullName'
                    ? currentSortDir == 'desc'
                      ? 'sort-arrow-desc'
                      : 'sort-arrow-asc'
                    : ''
                "
                >Name</span
              >
            </th>

            <th scope="col" class="pointer" @click="onSort('contactNumber')">
              <span
                class="sort-arrow"
                :class="
                  currentSort == 'contactNumber'
                    ? currentSortDir == 'desc'
                      ? 'sort-arrow-desc'
                      : 'sort-arrow-asc'
                    : ''
                "
                >Contact Number</span
              >
            </th>

            <th scope="col" class="pointer" @click="onSort('status')">
              <span
                class="sort-arrow"
                :class="
                  currentSort == 'status'
                    ? currentSortDir == 'desc'
                      ? 'sort-arrow-desc'
                      : 'sort-arrow-asc'
                    : ''
                "
                >App Activation status</span
              >
            </th>
            <th scope="col" class="pointer" @click="onSort('mainStatus')">
              <span
                class="sort-arrow"
                :class="
                  currentSort == 'mainStatus'
                    ? currentSortDir == 'desc'
                      ? 'sort-arrow-desc'
                      : 'sort-arrow-asc'
                    : ''
                "
                >Account Status</span
              >
            </th>

            <th scope="col" class="pointer" @click="onSort('grossSalary')">
              <span
                class="sort-arrow"
                :class="
                  currentSort == 'grossSalary'
                    ? currentSortDir == 'desc'
                      ? 'sort-arrow-desc'
                      : 'sort-arrow-asc'
                    : ''
                "
                >Salary</span
              >
            </th>
            <th scope="col" class="pointer" @click="onSort('withdrawalPayoff')">
              <span
                class="sort-arrow"
                :class="
                  currentSort == 'withdrawalPayoff'
                    ? currentSortDir == 'desc'
                      ? 'sort-arrow-desc'
                      : 'sort-arrow-asc'
                    : ''
                "
                >Payout</span
              >
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(employee, index) in paginatedItems"
            :key="employee.userId"
          >
            <td scope="row">{{ index + currentIndex }}</td>
            <td>{{ employee.fullName }}</td>
            <td>{{ employee.contactNumber }}</td>
            <td :class="employee.status == 0 ? 'red' : ''">
              {{ employee.status == 0 ? "Inactive" : "Active" }}
            </td>
            <td :class="employee.mainStatus == 0 ? 'red' : ''">
              {{ employee.mainStatus == 0 ? "Inactive" : "Active" }}
            </td>
            <td>
              {{ convertToTwoDecimalPlaces(employee.grossSalary) }}
            </td>
            <td>
              {{ convertToTwoDecimalPlaces(employee.withdrawalPayoff) }}
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </Pagination>
</template>
<script lang="ts" setup>
import { ref, defineProps, toRaw, computed } from "vue";
import Pagination from "@/components/Pagination.vue";
import TitleBar from "@/components/TitleBar.vue";

const props = defineProps<{
  reviewData: any;
}>();
const currentSort = ref("fullName");
const currentSortDir = ref("asc");
const search = ref("");

const filteredList = computed(() => {
  return [...toRaw(props.reviewData).userList].sort((a, b) => {
    let modifier = 1;
    if (currentSortDir.value === "desc") modifier = -1;
    if (a[currentSort.value] < b[currentSort.value]) return -1 * modifier;
    if (a[currentSort.value] > b[currentSort.value]) return 1 * modifier;
    return 0;
  });
});

function convertToTwoDecimalPlaces(number) {
  var numberWithTwoDecimalPlaces = number.toFixed(2);
  return numberWithTwoDecimalPlaces;
}

function onSort(s) {
  if (s === currentSort.value) {
    currentSortDir.value = currentSortDir.value === "asc" ? "desc" : "asc";
  }
  currentSort.value = s;
}
</script>
<style scoped>
.detail-page-label {
  font-size: 16px;
}
</style>

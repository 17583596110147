import axios from "axios";
import { inject } from "vue";
import { useStore } from "vuex";

export function usePasswordVerify() {
  const swal = inject("$swal") as any;
  const store = useStore();
  const token = store.state.token;

  function verifyPassword() {
    return new Promise((resolve) => {
      swal({
        html: `
                <div  style="margin-inline:auto; max-width:80%;">
                    <label class="form-label text-start d-block" for="passwordConfirm">Password</label>
                    <div class="form-control d-flex">
                      <input style="width:100%; border:none; flex:1;" id="passwordConfirm"  type="password"/>
                      <span class="eye-icon eye-close p-1"></span>
                    </div>
                    <p id="error" class="text-danger"></p>
                </div>
                  `,

        showCancelButton: false,
        confirmButtonText: "Submit",
        cancelButtonText: "Close",
        showCloseButton: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-secondary me-2",
        },
        preConfirm: async () => {
          const el = swal.getHtmlContainer().querySelector("#passwordConfirm");
          const errorEl = swal.getHtmlContainer().querySelector("#error");
          errorEl.innerText = "";
          let success = false;
          if (el && el.value) {
            try {
              const response = await axios.post(
                process.env.VUE_APP_API_URL + "auth/reconfirm-password",
                {
                  password: el.value,
                },
                {
                  headers: {
                    Authorization: "bearer " + token,
                  },
                }
              );
              success = true;
            } catch (_) {
              errorEl.innerText = "Invalid Password";
            }
          } else {
            errorEl.innerText = "Field empty";
          }
          return success;
        },
      }).then((result) => {
        return resolve(result.isConfirmed);
      });

      const eyeIcon = swal.getHtmlContainer().querySelector(".eye-icon");
      const passwordInput = swal
        .getHtmlContainer()
        .querySelector("#passwordConfirm");

      eyeIcon.onclick = () => {
        if (passwordInput.type === "password") {
          passwordInput.type = "text";
          eyeIcon.classList.remove("eye-close");
        } else {
          passwordInput.type = "password";
          eyeIcon.classList.add("eye-close");
        }
      };
    });
  }

  return { verifyPassword };
}

<template>
  <div>
    <TitleBar />
    <div class="article-details-container">
      <div class="field">
        <h3>Title</h3>
        <input type="text" v-model="title" required v-bind="titleAttrs" />
        <div class="error">{{ errors.title }}</div>
      </div>
      <div class="field">
        <h3>Author</h3>
        <input type="text" v-model="author" v-bind="authorAttrs" />
        <div class="error">{{ errors.author }}</div>
      </div>
      <!-- <div class="field">
        <h3>Category</h3>
        <select v-model="categoryId" v-bind="categoryAttrs">
          <option v-for="category in article.categoryList" :key="category.id" :value="category.id">
            {{ category.categoryName }}
          </option>
        </select>
        <div class="error">{{ errors.categoryName }}</div>
      </div> -->

      <div class="field">
        <h3>Status</h3>
        <select class="form-control" v-model="status" v-bind="statusAttrs">
          <option value="1">Active</option>
          <option value="0">Inactive</option>
        </select>
        <div class="error">{{ errors.status }}</div>
      </div>
      <div class="field">
        <h3>Description</h3>
        <textarea v-model="description" v-bind="descriptionAttrs" name="description" cols="30" rows="10"></textarea>
        <div class="error">{{ errors.description }}</div>
      </div>
      <div class="field">
        <h3>Image</h3>
        <label for="file-upload" class="custom-file-upload btn btn-primary btn-sm btn-list btn-list-3">
          Choose File
        </label>
        <input id="file-upload" type="file" accept="image/*" multiple @change="handleImageChange" />
        <draggable v-model="articleImages" @update:modelValue="handleImageOrder" group="imageOrder"
          :item-key="(image) => image.id" class="d-flex gap-2 pt-3">
          <template v-slot:item="{ element, index }">
            <div class="d-flex" style="flex: 1;">
              <img :src="element.imagePath" alt="Article Image" style="max-height: 175px;" />
              <i class="fa-solid fa-delete-left" @click="deleteImage(index)"></i>
            </div>
          </template>
        </draggable>
      </div>
    </div>
    <div>
      <editor v-model="content" v-bind="contentAttrs" api-key="rn4saascaslcplyh405st6s4yej37m4k5fa7sx114t26u4xq" :init="{
          height: 500,
          menubar: false,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'help',
            'wordcount',
          ],
          toolbar:
            'undo redo | casechange blocks | bold italic backcolor | \
                                                                                                                    alignleft aligncenter alignright alignjustify | \
                                                                                                                    bullist numlst checklist outdent indent | removeformat | a11ycheck code table help',
        }" />
      <div class="error">{{ errors.content }}</div>
    </div>
    <button class="updateButton btn btn-primary btn-sm btn-list btn-list-3" @click="saveArticle">
      {{ article.id ? "Update" : "Create" }}
    </button>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import Editor from "@tinymce/tinymce-vue";
import axios from "axios";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import { useForm } from "vee-validate";
import * as yup from "yup";
import draggable from "vuedraggable";
import TitleBar from "@/components/TitleBar.vue";

const store = useStore();
const token = store.state.token;
const articleImages = ref([]);

const article = ref({
  id: null,
  title: "",
  author: "",
  category: { id: null, categoryName: "" },
  categoryList: [],
  description: "",
  content: "",
  articleImages: [],
  status: null,
  deletedImages: [],
});

const route = useRoute();
const router = useRouter();

const { errors, handleSubmit, defineField, isSubmitting, validate } = useForm({
  validationSchema: yup.object({
    title: yup.string().required("Please enter the title."),
    author: yup.string().required("Please enter the author."),
    // categoryId: yup.string().required("Please select a category."),
    status: yup.string().required("Please select a status."),
    description: yup.string().required("Please enter the description."),
    content: yup.string().required("Please enter the content."),
  }),
});

const [title, titleAttrs] = defineField("title");
const [author, authorAttrs] = defineField("author");
// const [categoryId, categoryAttrs] = defineField("categoryId");
const categoryId = ref(1);
const [status, statusAttrs] = defineField("status");
const [description, descriptionAttrs] = defineField("description");
const [content, contentAttrs] = defineField("content");

onMounted(async () => {
  const articleId = route.params.id;
  if (articleId) {
    await fetchArticleDetails(articleId);
  } else {
    article.value = createEmptyArticle();
  }

  const response = await axios.get(
    process.env.VUE_APP_API_URL + "ArticleCategory",
    {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "multipart/form-data",
      },
    }
  );
  article.value.categoryList = response.data;
});

const fetchArticleDetails = async (id) => {
  try {
    const response = await axios.get(
      process.env.VUE_APP_API_URL + `Article/${id}`
    );
    const details = response.data;
    details.articleImages = details.articleImages.map((i) => ({
      ...i,
      imagePath:
        process.env.VUE_APP_BASE_URL +
        `UploadedFiles/ArticleImages/${i.imagePath}`,
      file: null,
    }));

    article.value = details;
    title.value = details.title;
    author.value = details.author;
    categoryId.value = details.category.id;
    status.value = details.status;
    description.value = details.description;
    content.value = details.content;
    articleImages.value = details.articleImages;
  } catch (error) {
    console.error(`Error fetching article details for id ${id}:`, error);
  }
};

const createEmptyArticle = () => ({
  id: null,
  title: "",
  author: "",
  category: { id: null, categoryName: "" },
  categoryList: [],
  description: "",
  content: "",
  status: "",
  articleImages: [],
  deletedImages: [],
});
// Method to delete an image
const deleteImage = (index) => {

  const newList = articleImages.value.filter(
    (image, index2) => index !== index2
  );

  articleImages.value = newList.map((i, index) => ({
    ...i,
    imageOrder: index + 1,
  }));

};
// Function to create/update the article
const saveArticle = handleSubmit(async () => {
  try {

    if (articleImages.value.length < 1) {
      Swal.fire({
        icon: "error",
        title: "You didn't add an image!",
      })
      return
    }

    const formData = new FormData();
    formData.append("title", title.value);
    formData.append("author", author.value);
    formData.append("categoryId", categoryId.value.toString());
    formData.append("content", content.value);
    formData.append("description", description.value);
    formData.append("status", status.value);

    for (let i = 0; i < articleImages.value.length; i++) {
      const element = articleImages.value[i];
      if (element.id) {
        formData.append(`images[${i}][id]`, element.id);
      }
      formData.append(`images[${i}].imagePath`, element.imagePath);
      formData.append(`images[${i}].file`, element.file);
      formData.append(`images[${i}].imageOrder`, element.imageOrder);
    }

    if (article.value.id) {
      await axios.put(
        process.env.VUE_APP_API_URL + `article/update/${article.value.id}`,
        formData,
        {
          headers: {
            Authorization: "bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      Swal.fire({
        icon: "success",
        title: "Article updated successfully",
      })
        .then(() => {
          router.push("/articles");
        });
    } else {
      await axios.post(
        process.env.VUE_APP_API_URL + `Article/create`,
        formData,
        {
          headers: {
            Authorization: "bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      Swal.fire({
        icon: "success",
        title: "Article created successfully",
      }).then(() => {
        router.push("/articles");
      });
    }
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Something went wrong",
    })
    // Handle error as needed
  }
});
// Handle image change
const handleImageChange = (event) => {
  const files = event.target.files;
  if (!files) return
  files.forEach((file, index) => {
    const imageUrl = URL.createObjectURL(file);
    articleImages.value.push({
      id: null,
      imagePath: imageUrl,
      file,
      imageOrder: index + 1,
    });
  });

};

const handleImageOrder = async (images) => {
  articleImages.value = images.map((i, index) => ({
    ...i,
    imageOrder: index + 1,
  }));

};
</script>

<style scoped>
h3 {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 2px;
  color: #4c2c6b;
  opacity: 1;

}

.error {
  color: red;
}

.article-details-container {
  max-width: 600px;
}

.field {
  margin-bottom: 20px;
}

h3 {
  margin-bottom: 5px;
  color: #333;
}

input,
select,
textarea {
  border: 1px;
  padding-left: 10px;
  padding-right: 60px;
  font-size: 16px;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029;
  border-radius: 17px;
  letter-spacing: 0px;
  color: #472667;
}

textarea {
  height: 100px;
}


.image-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 10px;
}

.custom-file-upload {
  display: inline-block;
  cursor: pointer;
  background-color: #472667;
  color: #fff;
  transition: background-color 0.3s ease;
}

#file-upload {
  display: none;
}

.imageSection {
  display: flex;
}

.fa-delete-left {
  margin-left: 5px;
  cursor: pointer;
}

.updateButton {
  margin-top: 20px;
  float: right;
}
</style>

<template>
  <div class="container mt-5">
    <TitleBar></TitleBar>
    <div class="text-danger" v-if="errorMsg != ''">
      {{ errorMsg }}
    </div>

    <form enctype="multipart/form-data">
      <div class="row g-3">
        <div class="col-sm-12">
          <div class="input-file-div">
            <input type="file" id="file" name="file" ref="file" class="form-control file-input"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" v-on:change="handleFile()" />
            <label class="custom-file-input">
              <div @click="openFileInput" class="custom-file-div">
                <div class="custom-file-inner">
                  <span class="icon"><img :src="require('@/assets/images/Group-1429.svg')" alt="File Icon" /></span>
                  <span class="label">Choose file</span>
                </div>
              </div>
            </label>
            <label class="custom-file-input-no-file-chosen" @click="openFileInput" v-if="!file">
              <div class="custom-file-input-no-file-chosen-opacity">
                No file selected
              </div>
            </label>
          </div>
          <span class="text-danger" v-if="errormessage">
            {{ errormessage }}
          </span>

          <span v-if="successnumber != 0 || failnumber != 0">
            <br />
            Number of success
            <router-link v-if="successnumber != null" target="_blank" :to="{
              name: 'admin-insurance-bulkimport-list',
            }">(view more details)</router-link>: {{ successnumber }}
            <br />
            Number of failed
            <router-link v-if="failnumber != null" target="_blank" :to="{
              name: 'admin-insurance-bulkimport-list',
            }">(view more details)</router-link>
            : {{ failnumber }}
          </span>
        </div>
      </div>
      <div class="text-center" v-if="loading == true">
        <img :src="require('@/assets/images/loading.gif')" />
      </div>
      <div class="pddtop30">
        <router-link class="btn btn-secondary mrgrbtn" to="/insurance-enrolment/bulkimport/list">Back</router-link>
        <button class="btn btn-primary" type="button" v-on:click="submit()">
          Submit
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import store from "@/store";
import TitleBar from "@/components/TitleBar.vue";
import axios from "axios";
export default defineComponent({
  name: "AdminWithdrawalBulkImport",
  components: { TitleBar },
  data() {
    return {
      file: "",
      errormessage: "",
      loading: false,
      successnumber: 0,
      failnumber: 0,
      bulkiid: "",
      errorMsg: "",
      excelType: "default",
    };
  },
  methods: {
    openFileInput() {
      let fileInputElement = this.$refs.file;
      fileInputElement.click();
    },

    async submit() {
      if (this.loading) return;
      this.loading = true;

      this.errormessage = "";
      const file = this.$refs.file.files[0];
      if (!file) {
        this.errormessage = "No file chosen";
        this.loading = false;
        return;
      }

      if (file.size > 1024 * 1024) {
        this.errormessage = "File too big (> 1MB)";
        this.loading = false;
        return;
      }
      if (
        !(
          (
            file.type ==
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            file.type == "application/vnd.ms-excel" ||
            file.type == "application/vnd.ms-excel.sheet.macroenabled.12"
          )
          // || (file.type == "text/csv")
        )
      ) {
        this.errormessage = "Invalid File";
        this.loading = false;
        return;
      }
      try {
        let formData = new FormData();

        formData.append("file", this.file);

        const token = store.state.token;
        let endpoint = "Insurance/bulkimport";
        var response = (
          await axios.post(process.env.VUE_APP_API_URL + endpoint, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "bearer " + token,
            },
          })
        ).data;
        this.loading = false;
        this.successnumber = response.result.successnumber;
        this.failnumber = response.result.failnumber;
        this.bulkiid = response.result.bulkid;
        this.errormessage = response.result.msg;

      } catch (error) {
        this.loading = false;
      }
    },

    handleFile() {
      this.file = this.$refs.file.files[0];
      this.errormessage = "";
      this.successnumber = 0;
      this.failnumber = 0;
    },
  },
});
</script>

<template>
  <div class="container mt-5">
    <TitleBar></TitleBar>
    <form @submit.prevent="submit">
      <div class="row g-3 pddbtm50">
        <div class="col-sm-5">
          <label for="firstName" class="form-label">First name*</label>
          <input
            v-model="formData.FirstName"
            type="text"
            class="form-control"
            id="firstName"
            placeholder=""
          />
          <span
            class="text-danger"
            v-for="error in v$.FirstName.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>

        <div class="col-sm-5">
          <label for="lastName" class="form-label">Last name</label>
          <input
            v-model="formData.LastName"
            type="text"
            class="form-control"
            id="lastName"
            placeholder=""
          />
          <span
            class="text-danger"
            v-for="error in v$.LastName.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>
        <div class="col-sm-5">
          <label for="lastName" class="form-label">IC Number*</label>
          <input
            v-model="formData.IcNumber"
            type="text"
            class="form-control"
            id="IcNumber"
            placeholder=""
          />
          <span
            class="text-danger"
            v-for="error in v$.IcNumber.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>
        <div class="col-sm-5">
          <label for="lastName" class="form-label">Employee Number*</label>
          <input
            v-model="formData.EmployeeNumber"
            type="text"
            class="form-control"
            id="EmployeeNumber"
            placeholder=""
          />
          <span
            class="text-danger"
            v-for="error in v$.EmployeeNumber.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>
        <div class="col-5">
          <label for="email" class="form-label"
            >Email*<span class="text-muted"></span
          ></label>
          <input
            v-model="formData.Email"
            type="text"
            class="form-control"
            id="email"
            placeholder=""
          />
          <span
            class="text-danger"
            v-for="error in v$.Email.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>

        <div class="col-5">
          <label for="DataOfBirth" class="form-label">Date of birth* </label>
          <Datepicker
            v-model="formData.DataOfBirth"
            :enable-time-picker="false"
            :format="format"
            :auto-apply="true"
            :close-on-auto-apply="true"
            class="calender-picker-field"
          >
            <template #input-icon>
              <img
                :src="require('@/assets/images/Group-714.svg')"
                alt=""
                class="calander-logo"
              />
            </template>
          </Datepicker>
          <span
            class="text-danger"
            v-for="error in v$.DataOfBirth.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>
        <div class="col-5">
          <label for="Address1" class="form-label">Address 1 </label>
          <input
            v-model="formData.Address1"
            type="text"
            class="form-control"
            id="Address1"
            placeholder=""
          />
          <span
            class="text-danger"
            v-for="error in v$.Address1.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>
        <div class="col-5">
          <label for="Address2" class="form-label">Address 2 </label>
          <input
            v-model="formData.Address2"
            type="text"
            class="form-control"
            id="Address2"
            placeholder=""
          />
          <span
            class="text-danger"
            v-for="error in v$.Address2.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>
        <div class="col-5">
          <label for="City" class="form-label">City </label>
          <input
            v-model="formData.City"
            type="text"
            class="form-control"
            id="City"
            placeholder=""
          />
          <span
            class="text-danger"
            v-for="error in v$.City.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>
        <div class="col-5">
          <label for="PostCode" class="form-label">Postcode </label>
          <input
            v-model="formData.PostCode"
            type="text"
            class="form-control"
            id="PostCode"
            placeholder=""
          />
          <span
            class="text-danger"
            v-for="error in v$.PostCode.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>
        <div class="col-5">
          <label for="contactNumber" class="form-label">Contact Number* </label>
          <input
            v-model="formData.ContactNumber"
            type="text"
            class="form-control"
            id="ContactNumber"
            placeholder=""
          />
          <span
            class="text-danger"
            v-for="error in v$.ContactNumber.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>
        <div class="col-5">
          <label for="DepartmentName" class="form-label">Department* </label>
          <input
            v-model="formData.DepartmentName"
            type="text"
            class="form-control"
            id="DepartmentName"
            placeholder=""
          />
          <span
            class="text-danger"
            v-for="error in v$.DepartmentName.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>
        <div class="col-5">
          <label for="Position" class="form-label">Position* </label>
          <input
            v-model="formData.Position"
            type="text"
            class="form-control"
            id="Position"
            placeholder=""
          />
          <span
            class="text-danger"
            v-for="error in v$.Position.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>
        <div class="col-sm-5">
          <label for="Bank" class="form-label">Bank*</label>
          <select
            v-model="formData.BankId"
            type="text"
            class="form-control"
            id="BankId"
            placeholder=""
          >
            <option value="">Please select</option>
            <option v-for="bank in banks" :key="bank.id" v-bind:value="bank.id">
              {{ bank.bankName }}
            </option>
          </select>
          <span
            class="text-danger"
            v-for="error in v$.BankId.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>
        <div class="col-sm-5">
          <label for="lastName" class="form-label">Bank Account Number*</label>
          <input
            v-model="formData.BankAccountNumber"
            type="text"
            class="form-control"
            id="BankAccountNumber"
            placeholder=""
          />
          <span
            class="text-danger"
            v-for="error in v$.BankAccountNumber.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>
        <div class="col-sm-5">
          <label for="lastName" class="form-label"
            >Basic salary (including fixed allowance)*</label
          >
          <input
            v-model="formData.GrossSalary"
            type="number"
            class="form-control"
            id="GrossSalary"
            placeholder=""
          />
          <span
            class="text-danger"
            v-for="error in v$.GrossSalary.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>

        <div class="col-sm-5">
          <label for="SubCompany" class="form-label">Company</label>
          <select
            v-model="formData.SubCompany"
            type="text"
            class="form-control"
            id="SubCompany"
            placeholder=""
          >
            <option value="">Please select</option>

            <template v-for="subcompany in subcompanies">
              <option
                v-if="subcompany.statusString !== 'Inactive'"
                :key="subcompany.id"
                :value="subcompany.id"
              >
                {{ subcompany.companyName }}
              </option>
            </template>
          </select>
          <span
            class="text-danger"
            v-for="error in v$.SubCompany.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>
        <div class="col-sm-5">
          <label for="JoinedDate" class="form-label">Joined Date </label>
          <Datepicker
            v-model="formData.JoinedDate"
            :enable-time-picker="false"
            :format="format"
            :auto-apply="true"
            :close-on-auto-apply="true"
            class="calender-picker-field"
          >
            <template #input-icon>
              <img
                :src="require('@/assets/images/Group-714.svg')"
                alt=""
                class="calander-logo"
              />
            </template>
          </Datepicker>
          <span
            class="text-danger"
            v-for="error in v$.JoinedDate.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>
        <div class="col-sm-5">
          <label for="MainStatus" class="form-label">Account Status*</label>
          <select
            v-model="formData.MainStatus"
            type="text"
            class="form-control"
            id="MainStatus"
            placeholder=""
          >
            <option value="1">Active</option>
            <option value="0">Deactivate</option>
          </select>
          <span
            class="text-danger"
            v-for="error in v$.MainStatus.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>

        <div class="col-sm-5">
          <label for="WithdrawalStartDate" class="form-label"
            >Withdrawal Start Date
          </label>
          <Datepicker
            v-model="formData.WithdrawalStartDate"
            :enable-time-picker="false"
            :format="format"
            :auto-apply="true"
            :close-on-auto-apply="true"
            class="calender-picker-field"
          >
            <template #input-icon>
              <img
                :src="require('@/assets/images/Group-714.svg')"
                alt=""
                class="calander-logo"
              />
            </template>
          </Datepicker>
          <span
            class="text-danger"
            v-for="error in v$.WithdrawalStartDate.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>

        <div class="col-sm-5" v-if="formData.MainStatus == 0">
          <label for="GracePeriod" class="form-label">Notice Period </label>
          <Datepicker
            v-model="formData.GracePeriod"
            :enable-time-picker="false"
            :format="format"
            :auto-apply="true"
            :close-on-auto-apply="true"
            class="calender-picker-field"
          >
            <template #input-icon>
              <img
                :src="require('@/assets/images/Group-714.svg')"
                alt=""
                class="calander-logo"
              />
            </template>
          </Datepicker>
          <span
            class="text-danger"
            v-for="error in v$.GracePeriod.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div>
        <!-- <div class="col-sm-5">
          <label for="GracePeriod" class="form-label">Notice Period </label>
          <Datepicker
            v-model="formData.GracePeriod"
            :enable-time-picker="false"
            :format="format"
          ></Datepicker>
          <span
            class="text-danger"
            v-for="error in v$.GracePeriod.$errors"
            :key="error.$uid"
            >{{ error.$message }}</span
          >
        </div> -->
      </div>

      <!-- <hr class="my-4" /> -->
      <div
        class="text-danger"
        v-if="errorMsg != ''"
        style="margin-bottom: 20px"
      >
        {{ errorMsg }}
      </div>
      <router-link class="btn btn-secondary mrgrbtn" to="/employee/list"
        >Cancel</router-link
      >
      <button class="btn btn-primary" type="submit">Submit</button>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  email,
  helpers,
  decimal,
  numeric,
  requiredIf,
} from "@vuelidate/validators";
import { reactive } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import store from "@/store";
import Datepicker from "@vuepic/vue-datepicker";
import axios from "axios";
import TitleBar from "@/components/TitleBar.vue";
export default defineComponent({
  name: "EmployeeAdd",
  setup() {
    const toast = useToast();
    const DataOfBirth = ref(new Date());
    const errorMsg = ref("");
    const router = useRouter();
    const token = store.state.token;
    const format = (DataOfBirth) => {
      const day = DataOfBirth.getDate();
      const month = DataOfBirth.getMonth() + 1;
      const year = DataOfBirth.getFullYear();

      return `${day}-${month}-${year}`;
    };
    const submit = async () => {
      const result = await v$.value.$validate();

      if (result) {
        await axios
          .post(process.env.VUE_APP_API_URL + "employee/add", formData, {
            headers: {
              Authorization: "bearer " + token,
            },
          })
          .then((res) => {
            errorMsg.value = "";

            toast.success("Employee successfully added", {
              timeout: 3000,
            });

            router.push("/employee/list");
          })
          .catch((err) => {
            errorMsg.value = err.response.data;
          });
      }
    };

    const formData = reactive({
      FirstName: "",
      LastName: "",
      IcNumber: "",
      Email: "",
      ContactNumber: "",
      DepartmentName: "",
      Position: "",
      BankId: "",
      BankAccountNumber: "",
      GrossSalary: 0,
      EmployeeNumber: "",
      DataOfBirth: "",
      Address1: "",
      Address2: "",
      City: "",
      PostCode: "",
      JoinedDate: null,
      WithdrawalStartDate: null,
      SubCompany: null,
      MainStatus: 1,
      GracePeriod: null,
    });

    const rules = computed(() => {
      return {
        FirstName: {
          required: helpers.withMessage("Please enter first name", required),
        },
        LastName: {},
        IcNumber: {
          required: helpers.withMessage("Please enter IC number", required),
        },
        EmployeeNumber: {
          required: helpers.withMessage(
            "Please enter employee number",
            required
          ),
        },
        Email: {
          email,
          required: helpers.withMessage(
            "Please enter email if contact number is not available",
            requiredIf(!formData.ContactNumber || formData.ContactNumber === "")
          ),
        },
        BankId: {
          required: helpers.withMessage("Please select bank", required),
        },
        BankAccountNumber: {
          required: helpers.withMessage(
            "Please enter bank account number",
            required
          ),
        },
        ContactNumber: {
          required: helpers.withMessage(
            "Please enter contact number if email is not available",
            requiredIf(!formData.Email && formData.Email === "")
          ),
          phoneformat: helpers.withMessage(
            "Invalid Contact Number",
            helpers.regex(/^[6][0][1][0-9]+$/)
          ),
          numeric,
        },
        DepartmentName: {
          required: helpers.withMessage(
            "Please enter department name",
            required
          ),
        },
        Position: {
          required: helpers.withMessage("Please enter position", required),
        },
        GrossSalary: {
          required: helpers.withMessage("Please enter salary", required),
          decimal,
        },
        DataOfBirth: {
          required: helpers.withMessage("Please enter date of birth", required),
        },
        Address1: {},
        Address2: {},
        City: {},
        PostCode: {
          numeric,
        },
        JoinedDate: {},
        WithdrawalStartDate: {},
        SubCompany: {},
        MainStatus: {},
        GracePeriod: {},
      };
    });
    const v$ = useVuelidate(rules, formData);

    return { v$, submit, formData, errorMsg, DataOfBirth, format };
  },
  data() {
    return {
      banks: ref([]),
      loading: true,
      subcompanies: ref([]),
    };
  },
  components: {
    Datepicker,
    TitleBar,
  },
  methods: {
    async bankList() {
      const response = await axios.get(process.env.VUE_APP_API_URL + "Bank");
      this.banks = response.data;
    },
    async subcompanyList() {
      const token = store.state.token;
      const response = await axios.get(
        process.env.VUE_APP_API_URL + "SubCompany/list",
        {
          headers: {
            Authorization: "bearer " + token,
          },
        }
      );
      this.loading = false;
      this.subcompanies = response.data;
    },
  },
  validations() {
    return {};
  },
  mounted() {
    this.bankList();
    this.subcompanyList();
  },
});
</script>

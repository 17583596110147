<template>
  <div class="tab">
    <div class="tab-header">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        :class="{ active: activeTab === index }"
        @click="changeTab(index)"
      >
        {{ tab.label }}
      </div>
    </div>
    <div class="tab-content">
      <slot :name="tabs[activeTab]?.slotName"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tab",
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    maxTabs: {
      type: Number,
      default: 4,
    },
  },
  emits: ["tabChanged"],
  data() {
    return {
      activeTab: 0,
    };
  },
  methods: {
    changeTab(index) {
      this.$emit("tabChanged", index);
      this.activeTab = index;
      this.$router.push({ query: { index: index } });
    },
  },
  mounted() {
    try {
      const queryParamIndex = parseInt(this.$route.query?.index);
      this.activeTab = !Number.isNaN(queryParamIndex)
        ? Math.max(Math.min(queryParamIndex, this.maxTabs - 1), 0)
        : 0;
    } catch (error) {
      //
    }
  },
};
</script>

<style>
.tab-header {
  display: flex;
}

.tab-header > div {
  flex: 1;
  cursor: pointer;
  padding: 10px 20px;
  background-color: #eee;
  border: 1px solid #ccc;
  border-bottom: none;
  user-select: none;
}

.tab-header > div.active {
  background-color: #fff;
}

.tab-content {
  border: 1px solid #ccc;
  padding: 20px;
}

@media (min-width: 576px) {
  .tab-header > div {
    flex-direction: row;
    flex: unset;
  }
}
</style>
